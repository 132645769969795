import React from "react";
import { IonImg, IonLabel, IonProgressBar } from "@ionic/react";
import Calendar from "react-calendar";
import Slider from "react-slick";
import StoreService from "../../../../services/store.service";
import storeService from "../../../../services/store.service";
import { formatWeekDays } from "../../../../helper/Common";
const DesktopScreen: React.FC<any> = (props) => {
  const {
    onSuccess,
    steps,
    userData,
    isLoading,
    onBack,
    isStaffNotAvailable,
    onNoStaffBack,
    selectedDepartment,
    setSteps,
    staffData,
    selectedStaff,
    setSelectedStaff,
    setSelectedTime,
    setSelectedDate,
    onDateChange,
    onArrowChange,
    onMonthYearChange,
    dateMarks,
    moment,
    selectedDate,
    timeSlot,
    isBookingLoader,
    setOnCancel,
    setSelectedTimeDetails,
    setIsContactShow,
    settings,
    selectedTime,
    contactFrom,
    handlesubmitButton,
  } = props;

  return (
    <>
      {!onSuccess && (
        <>
          {steps !== 4 ? (
            <>
              <div className="toptilediv">
                <IonLabel className="subtext">Olá, {userData?.name}</IonLabel>
                {steps !== 4 && steps !== 2 ? (
                  <IonLabel className="maintext">
                    Conheça nossas Especialidades
                  </IonLabel>
                ) : (
                  steps === 2 && (
                    <IonLabel className="maintext">
                      Agendamento de consulta
                    </IonLabel>
                  )
                )}
              </div>
            </>
          ) : (
            <div>
              <div className="toptilediv">
                <div>
                  <IonLabel className="subtext">Olá, {userData?.name}</IonLabel>
                </div>
                <div>
                  <IonLabel className="maintext">
                    Agendamento de consulta
                  </IonLabel>
                </div>
                <div className="especialista-txt">
                  Confirme seu agendamento e seus dados
                </div>
                {isLoading && <IonProgressBar type="indeterminate" />}
              </div>
            </div>
          )}
          <div className={"appointment-wapper app-wrapper"}>
            {steps !== 4 && (
              <div className="web-specailist">
                {steps !== 4 && steps !== 2 ? (
                  <div className="especialista-div">
                    Escolha <strong>um especialista.</strong>
                  </div>
                ) : (
                  steps === 2 && (
                    <div className="especialista-div">
                      Faça seu<strong> agendamento.</strong>
                    </div>
                  )
                )}
                {isLoading && <IonProgressBar type="indeterminate" />}
                <div className="align-items-center d-flex departement-text justify-content-around">
                  <IonImg
                    src="assets/images/arrow-left-orange.svg"
                    onClick={() => {
                      steps === 0
                        ? onBack()
                        : isStaffNotAvailable
                        ? onNoStaffBack()
                        : setSteps(steps - 2);
                    }}
                  />
                  {selectedDepartment}
                </div>
              </div>
            )}
            <div className={"professional-wapper"}>
              {staffData && steps === 0 && (
                <>
                  <div className="mainlistcard">
                    {staffData?.map((item, index) => (
                      <div
                        key={index}
                        className={
                          item.staff_id === selectedStaff?.staff_id
                            ? "professional-card active"
                            : "professional-card pro-card"
                        }
                        onClick={() => {
                          setSelectedStaff(item);
                        }}
                      >
                        <div>
                          <img
                            className="professional-picture"
                            src={
                              item.avatar
                                ? item.avatar
                                : "/assets/images/profile-avtar-default.png"
                            }
                            onError={storeService.addDefaultSrc}
                          />
                        </div>
                        <div>
                          <div className="professional-name">{item.name}</div>
                          <div className="professional-crp">{item.crm}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="btn-right">
                    <input
                      type="button"
                      value="Confirmar"
                      disabled={selectedStaff ? false : true}
                      className={
                        selectedStaff
                          ? "professional-btn "
                          : "professional-btn inactive"
                      }
                      onClick={() => {
                        setSteps(2);
                      }}
                    />
                    <input
                      type="button"
                      value="Cancelar"
                      className="professional-btn canelar"
                      onClick={() => {
                        setSelectedTime(null);
                        setSelectedDate(null);
                        // clearState();
                        props.history.replace({
                          search: "",
                        });
                        props.history.push("/home");
                      }}
                    />
                  </div>
                </>
              )}
              <div className={steps === 2 ? "date-time-main" : "hide-section"}>
                <div className="mainlistcard">
                  <div className="professional-card pro-card">
                    {selectedStaff ? (
                      <>
                        <IonLabel>Agende sua consulta</IonLabel>
                        <div>
                          <img
                            className="professional-picture"
                            src={
                              selectedStaff?.avatar
                                ? selectedStaff?.avatar
                                : "/assets/images/profile-avtar-default.png"
                            }
                            onError={StoreService.addDefaultSrc}
                          />
                        </div>
                        <div>
                          <div className="professional-name">
                            {selectedStaff?.name}
                          </div>
                          <div className="professional-crp">
                            {selectedStaff?.crm}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <IonLabel>Agende sua consulta</IonLabel>
                        <div>
                          <img
                            className="no-professional-picture"
                            src="/assets/images/heart.svg"
                          />
                        </div>
                        <div>
                          <div className="professional-name">
                            Profissional da equipe
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div
                  className={
                    steps !== 2
                      ? "calender-wapper hide-section"
                      : "time-slot-main calender"
                  }
                >
                  <div className="appointment-header">Escolha uma data</div>
                  <Calendar
                    locale="pt-BR"
                    minDate={new Date()}
                    onChange={(d) => {
                      onDateChange(d);
                    }}
                    onActiveStartDateChange={(d) => onArrowChange(d)}
                    onClickMonth={(d) => onMonthYearChange(d)}
                    onClickYear={(d) => onMonthYearChange(d)}
                    tileClassName={({ date, view }) => {
                      if (
                        dateMarks.find(
                          (x) => x === moment(date).format("DD-MM-YYYY")
                        )
                      ) {
                        return "highlight";
                      } else {
                        return "nonHighlight";
                      }
                    }}
                    value={selectedDate}
                  />
                </div>

                <div
                  className={
                    steps === 2 && selectedDate
                      ? "time-slot-main time-slot-div"
                      : "time-slot-main hide-section "
                  }
                >
                  <IonLabel>Escolha um horário</IonLabel>
                  <div className="time-slot-wrap">
                    {timeSlot?.length === 0 && <div className="my-4">No momento, não há intervalos de tempo disponíveis para a data selecionada. Selecione outra data.</div>}
                    {timeSlot.length > 0 && (
                      <Slider {...settings}>
                        {timeSlot?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                setSelectedTime(item?.slots);
                                setSelectedTimeDetails(item);
                                // setIsTimeShow(false);
                              }}
                              className={
                                selectedTime === item.slots
                                  ? "time-slot active"
                                  : "time-slot"
                              }
                            >
                              {item.slots}
                            </div>
                          );
                        })}
                      </Slider>
                    )}
                  </div>
                  <input
                    type="button"
                    value="Confirmar agendamento"
                    disabled={selectedTime == null ? true : false}
                    className={
                      selectedTime && selectedDate
                        ? "professional-btn "
                        : "professional-btn inactive"
                    }
                    onClick={() => {
                      setIsContactShow(true);
                      setSteps(4);
                    }}
                  />
                  <input
                    type="button"
                    value="Cancelar"
                    className="professional-btn canelar"
                    onClick={() => {
                      setSelectedTime(null);
                      setSelectedDate(null);
                      // clearState();
                      props.history.replace({
                        search: "",
                      });
                      props.history.push("/home");
                    }}
                  />
                </div>
              </div>
              {isBookingLoader && selectedDepartment && selectedTime && (
                <IonProgressBar type="indeterminate" className="mb-2" />
              )}
              <div className="contact-summary-main">
                <div
                  className={
                    steps !== 4 ? "hide-section" : "summary-wapper summary"
                  }
                >
                  <div className="summary-card">
                    <div className="summary-card-inner-m">
                      {/* <div className="especialista-div">Especialista</div> */}
                      <div className="departement-text">
                        {selectedDepartment}
                      </div>
                      <div className="summary-profile-div">
                        <img
                          className="summary-picture-m"
                          src={
                            selectedStaff?.avatar
                              ? selectedStaff?.avatar
                              : "/assets/images/profile-avtar-default.png"
                          }
                        />
                        <div className="summary-name">
                          {selectedStaff?.name}
                        </div>
                        <div className="">{selectedStaff?.crm}</div>
                      </div>
                    </div>
                    <div className="summary-date-time">
                      {moment(selectedDate, "DD/MM/YYYY").format("DD")}/
                      {moment(selectedDate, "DD/MM/YYYY").format("MM")}, 
                      {formatWeekDays(moment(selectedDate, "DD/MM/YYYY").day())},
                      às {selectedTime?.replace(":", "h")}
                    </div>
                    <div
                      className="summary-edit"
                      onClick={() => {
                        setSteps(2);
                      }}
                    >
                      <span className="text-pointer">Editar</span>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    steps !== 4
                      ? "contact-details-main hide-section"
                      : "contact-details-main contact-div"
                  }
                >
                  <div className="appointment-header">
                    Verifique seu e-mail e telefone{" "}
                  </div>
                  <div className="formik-form">{contactFrom(true)}</div>
                </div>
                <div className={steps !== 4 ? "hide-section" : "lastbuttondiv"}>
                  <div
                    className={
                      isBookingLoader
                        ? "disable-confirm-btn"
                        : "summary-conf-btn"
                    }
                    onClick={() => handlesubmitButton()}
                  >
                    <span>Confirmar Agendamento</span>
                  </div>
                  <input
                    className="summary-cancel-btn"
                    value="Cancelar"
                    type="button"
                    onClick={() => setOnCancel(true)}
                    disabled={isBookingLoader}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DesktopScreen;
