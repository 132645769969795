import APP_CONFIG from "../config/app.config";
const CryptoJs = require("crypto-js");

export class CryptoService {
  private _crypto: typeof CryptoJs;

  private _secret = APP_CONFIG.SECRET;

  constructor() {
    this._crypto = CryptoJs;
  }

  public async AES(key: string) {
    console.log(this._crypto);

    return this._crypto;
  }

  //   public async set(key: string, value: unknown) {
  //     return this.storeProvider.set(key, value);
  //   }
}

export default new CryptoService();
