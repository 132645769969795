import {
  IonContent,
  IonImg,
  IonLabel,
  IonPage,
  IonProgressBar,
  useIonViewDidEnter,
  useIonViewWillLeave
} from "@ionic/react";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import storeService from "../../services/store.service";
import "./TokenAuth.scss";
import axios from "axios";
const TokenAuth: React.FC<any> = (props) => {

  const [isLoading, setIsLoading] = useState<any>(false);
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search.substring(1));
  
  let baseString = queryParams?.get("token") || "";
  const [loginError, setLoginError] = useState<string>("");

  
  useIonViewDidEnter(() => {
    if (!isEmpty(baseString)) {
      storeService.removeKey("user");
      verifyToken();
    } else {
      storeService.get("user").then((resp: any) => {
        if (resp) {
          if (!resp?.terms_of_use) history.push("/terms-of-user");
          else if (!resp?.privacy_policy) history.push("/privacy-policy-info");
          else history?.push("/home");
        } else{
          history?.replace("/login")
        }
      });
    }
  })

  // -------------------Token verify and decode api-------------------
  const verifyToken = async () => {
    setIsLoading(true);
    await storeService.set("userToken", baseString);
    axios
      .get(
        `${process.env.REACT_APP_API_PARSE_BASE_URL}doctor/patient/get_onboarding_details/${baseString}`)
      .then(async (response: any) => {
        if(!isEmpty(response?.data?.result)) {
          if (
            !response.data.result.isActiveUser ||
            response.data.result.isActiveUser === false
          ) {
            await storeService.set("signup", response.data.result);
            const idData = response.data?.result?.terms?.map(
              (index: any) => index?._id
            );
            await storeService.set("policyData", JSON.stringify(idData));
            history.replace("/login");
          } else {
            await storeService.set("user", response.data.result);
            history?.replace("/home");
          }
        } else {
          setLoginError("por favor insira um token válido");
          setIsLoading(false);
        }
      })
      .catch(async (error: any) => {
        console.log("error", error);
        
        setIsLoading(false);
        if (error?.response?.data?.message) setLoginError(error?.response?.data?.message);
        else setLoginError("por favor insira um token válido");
      });
  };

  useIonViewWillLeave(() => {
    setIsLoading(false);
    setLoginError(null);
  });

  return (
    <IonPage>
      <IonContent>
        <div className="main-container">
          <IonImg src="assets/images/amplasauudelogo.svg" className="w-25" style={{ height: 100 }} />
          {isLoading && (
            <IonProgressBar type="indeterminate" className="auth-content" />
          )}
          <IonLabel className="text-danger">{loginError}</IonLabel>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default TokenAuth;
