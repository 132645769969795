import * as React from "react";
import Rater from "react-rater";
import "react-rater/lib/react-rater.css";
import { IonAvatar, IonButton, IonCard, IonContent, IonTextarea, IonItem, IonLabel, IonList, IonPage, useIonToast, getPlatforms, IonGrid, IonRow, IonCol, IonInput, IonDatetime, IonImg } from '@ionic/react'

import {
  Container,
  Content,
  Title,
  Image,
  LeftColumn,
  RightColumn,
  ButtonsDiv,
  SendButton,
  CancelButton,
  TextArea,
} from "./styles";
// import reviewPic from "../../image/images/reviewImg.png";
import familyFull from "../../image/images/familyFull.png"
import platform from "../../helper/platform";
import storeService from "../../services/store.service";
import "./reviewscreen.scss";
export const ReviewScreen: React.FC<{
  supportId: string;
  onReview: (
    supportId: string,
    payload: { patientReviewNotes: string; patientReviewRating: number | null }
  ) => Promise<void>;
}> = ({ supportId, onReview }) => {
  const [reviewing, setReviewing] = React.useState(false);
  const [cachedSupportId] = React.useState(supportId);

  const [notesForm, setNotesForm] = React.useState("");
  const handleNotesChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setNotesForm(event.target.value);
    },
    [setNotesForm]
  );

  const [rating, setRating] = React.useState<number | null>(null);
  const handleRate = React.useCallback(
    (event: { rating: number }) => {
      setRating(event.rating);
    },
    [setRating]
  );

  const review = React.useCallback(async () => {
    if (reviewing) return;
    setReviewing(true);
    try {
      const patientReviewNotes = notesForm || " ";
      await onReview(cachedSupportId, {
        patientReviewNotes,
        patientReviewRating: rating
      });
    } catch (error) {
      console.error("Error retrieved when reviewing support");
    }
    setReviewing(false);
  }, [reviewing, setReviewing, cachedSupportId, notesForm, rating, onReview]);

  const [userData, setUserData] = React.useState<any>(null)
  React.useEffect(() => {
    storeService.get("user").then((res) => {
      setUserData(res)
    }).catch((error) => {
      console.log("error", error);

    })
  }, [])

  return (
    <Container>
      {platform.isMobileweb && 
        <div className="back-header position-relative reviewback">
          {/* <IonImg src="/assets/images/back-icon.svg" className="back-icon" /> */}
          <IonImg
            src="/assets/images/back-icon.svg"
            className="back-icon"
            
          />
          <div className="review-evulation">Avaliação</div>  
        </div>
      }
      <div className="container auto-h-container">
        <div className="width-90">
          {!platform.isMobileweb && 
            <>
            <div className="symptoms-patient-name d-none d-md-block page-title">Olá, {userData?.name}</div>
            <div className="availacao d-none d-md-block page-title-lg mt-2">Avaliação</div>
            </>
          }
          <div className="row mt-lg-3 mx-0">
            {!platform.isMobileweb && 
              <div className="col-lg-6 align-items-center justify-content-center d-none d-md-flex reviewimg">
                <IonImg src="/assets/images/review-screen.svg" className="symptoms-screen-img mb-3" />
              </div>
            }
            <div className="col-xl-6 col-sm-12 px-0">
              <div className="col-xl-10 mx-auto mt-lg-0">
                  {/* <div className="review-header-content">
                    <IonLabel className="review-header-title-txt">Consultas</IonLabel>
                  </div> */}
                  <div className="review-title-sub-header-txt">
                    <IonLabel className="symptoms-sub-text symptoms-sub-text-lg d-block">Como foi a <span className="text-dark-green">Consulta?</span></IonLabel>
                    <IonLabel className="symptoms-sub-text symptoms-sub-text-lg d-block">Ajude-nos com <span className="text-dark-green">a sua avaliação</span></IonLabel>
                  </div>
                  {platform.isMobileweb && 
                    <div className="col-xl-6 align-items-center justify-content-center mb-4">
                      <IonImg src="/assets/images/review-screen.svg" className="review-screen-img-mobile md-half" />
                    </div>
                  }
                  <div className="reviewbox-bottom">
                  <div className="custom-card">
                    <div className="rateDiv">
                    <Rater total={5} rating={rating || 0} interactive onRate={handleRate} />
                    </div>
                    <div className="card-body">
                      <IonTextarea rows={3}
                          className="review-text-area-view"
                          placeholder="Escreva aqui sua opinião..."
                          value={notesForm}
                          onIonChange={e => handleNotesChange}
                          disabled={reviewing}></IonTextarea>

                      {/* <TextArea>
                        <textarea
                          rows={3}
                          className="form-control"
                          placeholder="Escreva aqui sua opinião..."
                          value={notesForm}
                          onChange={handleNotesChange}
                          disabled={reviewing}
                        ></textarea>
                      </TextArea> */}
                    </div>
                  </div>
                  <ButtonsDiv className="bottombuttons">
                  {/*  <CancelButton onClick={review}>Cancelar</CancelButton>
                    <SendButton disabled={reviewing} onClick={review}>Envie sua avaliação</SendButton> */}
                    <IonGrid className="edit-grid-view">
                        <IonRow className="footer-btn-view mb-0 mt-md-3">
                          <IonCol size="6">
                            
                              <IonButton expand="full" className="mb-0 cancel-border-btn m-border-green my-0" onClick={review}>Cancelar</IonButton>
                         
                          </IonCol>
                          <IonCol size="6">
                          
                              <IonButton expand="block" className="mb-0 primary-btn rating-active-btn" onClick={review} type="submit">Enviar</IonButton>
                          
                          </IonCol>
                        </IonRow>
                    </IonGrid>
                  </ButtonsDiv>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </Content> */}
    </Container>
  );
};
