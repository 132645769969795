import { Route } from 'react-router-dom';
import {
  IonApp,
  IonRouterOutlet,
  useIonViewDidEnter,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import History from './pages/history/HistoryConsulta';
import Medicine from './pages/medicine/Medicine';
import Home from './pages/home/HomeScreen';
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import Login from './pages/login/Login'
import ExamScreen from './pages/exams/Exams'
import ProfileScreen from './pages/profile/UpdateProfile'
/* Theme variables */
import "bootstrap/dist/css/bootstrap.min.css";
import './theme/variables.css';
import './App.scss'
import { AwaitingScreen } from './components/patient-support/AwaitingScreen';
import { createBrowserHistory } from 'history';
import { useEffect } from 'react';
import StoreService from './helper/storage'
import CalendarScreen from "./pages/home/Appointment/CalendarScreen"
import PatientSupport from './components/patient-support';
import SettingsPage from './settings';
import PrivacyPolicy from './pages/privacy-policy';
import TermsOfUser from './pages/privacy-policy/termsOfUse';
import PrivacyPolicyInfo from './pages/privacy-policy/privacy-policy-info';
import SettingsPrivacyPolicyInfo from './pages/privacy-policy/settings-privacy';
import SettingsTermsOfUse from './pages/privacy-policy/settings-terms';
import TokenAuth from './pages/Auth/TokenAuth';

const history = createBrowserHistory();
const App: React.FC = () => {
  useEffect(()=>{   
      StoreService.get('user').then(res => {
          if (res) {
            if(history.location.pathname=="/"){
             return history.push("/home");             
            }else{
              return history.location.pathname
            }
          }
      })

  },[])
  return(

  <IonApp>    
    <IonReactRouter history={history}>
    <IonRouterOutlet id="main">
          <Route path="/" component={TokenAuth} exact />
          <Route path="/login" component={Login} exact/>
          <Route path="/home" component={Home} />
          <Route path="/exams" component={ExamScreen}  />
          <Route path="/medicine" component={Medicine} />
          <Route path="/history" component={History} />
          <Route path="/profile" component={ProfileScreen} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/terms-of-user" component={TermsOfUser} />
          <Route path="/privacy-policy-info" component={PrivacyPolicyInfo} />
          <Route path="/config-privacy-policy-info" component={SettingsPrivacyPolicyInfo} />
          <Route path="/config-terms-use" component={SettingsTermsOfUse} />
          <Route path="/settings" component={SettingsPage} />
          <Route path="/support" component={PatientSupport} exact />
          <Route
            exact
            path="/scheduling/calendar-screen"
            render={(props: any) => (
              <CalendarScreen {...props} />
            )}
          />
      </IonRouterOutlet>      
    </IonReactRouter>    
  </IonApp>
)}

export default App;
