import * as React from 'react'

export const FailedVideoRoomExpiredScreen: React.FC<{ onFinishClick: () => Promise<void> | void }> = ({ onFinishClick }) => {
  const [finishing, setFinishing] = React.useState(false)
  const finish = React.useCallback(async () => {
    if (finishing) return
    setFinishing(true)
    try {
      await onFinishClick()
    } catch (error) {
      console.error('Error when finishing through Expired Video Room screen')
    }
    setFinishing(false)
  }, [finishing, setFinishing, onFinishClick])
  return (
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <h5 className="connection_text text-center mb-5 width_30">Ops, a sala deste atendimento já expirou!</h5>
        <button className="btn btn-danger" onClick={finish} disabled={finishing} style={{ width: "200px" }}>Finalizar atendimento</button>
      </div>
    </div>
  )
}
