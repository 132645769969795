import {
  IonButton,
  IonIcon,
  IonLabel,
  IonModal,
  IonProgressBar,
} from "@ionic/react";
import { UserEmail } from "./userDetailStyle";
import { TextField } from "@material-ui/core";
import { Formik } from "formik";
import { useRef, useState } from "react";
import "./AddUserDetails.scss";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import storeService from "../../services/store.service";
import { countBy } from "lodash";

const AddUserDetails: React.FC<any> = ({ user, setUser, setIsModalOpen }) => {
  const [formData, setFormData] = useState({
    phone: "",
    countryCode: "",
    countryFormat: null
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const ref = useRef(null);
  const submituserinfo = (
    values: any,
    setSubmitting: any,
    resetForm: any
  ) => {};

  const handlePhoneChange = (
    phone: string,
    country: any,
    setErrors,
    errors
  ) => {
    const formDataPromise = new Promise((resolve) => {
      resolve(
        setFormData({
          phone,
          countryCode: country?.dialCode,
          countryFormat: country?.format
        })
      );
    });

    formDataPromise.then(() => {
      if (phone?.length) {
        setErrors({ ...errors, phone: "" });
      }
      if(formData.phone[0] == '0'){
        setErrors({ ...errors, phone: "O código do país é inválido" });
      } else if (!phone.slice(country?.dialCode?.length)) {
        setErrors({ ...errors, phone: "celular é obrigatório" });
      } else if (phone.length < countBy(country?.format)["."]) {
        setErrors({...errors, phone:"celular inválido"});
      }
    });
  };

  const getCountryCode = (countryCode: string) => {
    return countryCode?.charAt(0) === '+' ? countryCode : "+" + countryCode
  }

  return (
    <IonModal
      isOpen={true}
      backdropDismiss={true}
      cssClass="policy-content addusermodel"
    >
      <div className="contenttitle">
        <IonLabel>
          Identificamos que não temos seu e-mail ou telefone de contato.
        </IonLabel>
      </div>
      <div className="contentsubtitle">
        <IonLabel>
          Qual seu melhor{" "}
          <span>
            {!user?.email && "e-mail"}{" "}
            {!user?.email &&
              !!user?.telePhone &&
              !user?.telePhone[0]?.number &&
              " e "}
            {!!user?.telePhone && !user?.telePhone[0]?.number && "telefone"}
          </span>{" "}
          para contato?
        </IonLabel>
      </div>
      <Formik
        innerRef={ref}
        initialValues={{
          phone: "",
          email: "",
        }}
        validate={(values) => {
          let errors: any = {};
          if (!user?.email) {
            if (!values.email) {
              errors.email = "Email é obrigatório";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = "Email é inválido";
            }
          }
          if (!user?.telePhone[0]?.number) {
            if(formData.phone[0] == '0'){
              errors.phone = "O código do país é inválido"
            }
            else if (!formData?.phone?.slice(formData?.countryCode?.length)) {
              errors.phone = "celular é obrigatório";
            }else if (formData?.phone?.length < countBy(formData?.countryFormat)["."])
            errors.phone = "celular inválido";
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let updateData = [];
          let userUpdateDetails = [];
          if (values.email !== "" && formData.phone !== "") {
            updateData.push({
              email: values.email,
              phone: {
                number: formData?.phone.slice(formData?.countryCode?.length),
                countryCode: getCountryCode(formData?.countryCode)
              },
            });
            userUpdateDetails.push({
              email: values.email,
              phone: formData?.phone.slice(formData?.countryCode?.length),
              countryCode: getCountryCode(formData?.countryCode)
            });
          } else if (values.email !== "") {
            updateData.push({
              email: values.email,
            });
            userUpdateDetails.push({
              email: values.email,
            });
          } else {
            updateData.push({
              phone: {
                number: formData?.phone.slice(formData?.countryCode?.length),
                countryCode: getCountryCode(formData?.countryCode)
              },
            });
            userUpdateDetails.push({
              phone: formData?.phone.slice(formData?.countryCode?.length),
              countryCode: getCountryCode(formData?.countryCode)
            });
          }
          setIsLoading(true);

          if (user.telePhone[0]) {
            axios
              .post(
                `${process.env.REACT_APP_MIX_PARSE_API}doctor/update_profile_details/${user?.user_id}`, userUpdateDetails[0])
            .then(async (response: any) => {
              if (response.data.data.result === true) {
                setIsLoading(false);

                if (values.email !== "") {
                  user.email = values?.email;
                }
                if (formData.phone !== "") {
                  user.telePhone = [
                    {
                      number: formData.phone.slice(
                        formData?.countryCode?.length
                      ),
                      country_code: getCountryCode(formData?.countryCode),
                    },
                  ];
                }
                storeService.set("user", user);
                setUser({ ...user });
                setIsModalOpen(false);
              }
            })
            .catch((err: any) => {
              console.log("err", err);
              setIsLoading(false)
            })
          } else {
            axios
              .post(
                `${process.env.REACT_APP_API_BASE_URL}/doctor/update-user-details/${user?.user_id}`,
                updateData[0]
              )
              .then((res) => {
                if (res.status === 200) {
                  setIsLoading(false);
                  if (res.data.data.updatedPhoneNo) {
                    user.telePhone = [
                      {
                        number: res.data.data.updatedPhoneNo,
                        country_code: res.data.data.updatedCountryCode,
                      },
                    ];
                  }
                  if (res.data.data.updatedEmail) {
                    user.email = res.data.data.updatedEmail;
                  }
                  storeService.set("user", user);
                  setUser({ ...user });
                  setIsModalOpen(false);
                }
              })
              .catch((err) => {
                setIsLoading(false);
              });
          }

          submituserinfo(values, setSubmitting, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit} className="email-popup">
            <div className="userinfoformdiv">
              <div className="position-relative">
                {!user?.email && (
                  <>
                    <UserEmail>
                      <TextField
                        className="text-field"
                        id="outlined-basic"
                        placeholder="Escreve seu e-mail"
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        InputProps={{
                          startAdornment: (
                            <img src="assets/images/userinfo-email.svg" />
                          ),
                        }}
                      />
                    </UserEmail>
                    <label className="smallmessage">
                      {errors.email && touched.email && errors.email}
                    </label>
                  </>
                )}
              </div>
              <div className="position-relative">
                {!user.telePhone[0]?.number?.length && (
                  <>
                    <PhoneInput
                      country={"br"}
                      countryCodeEditable={false}
                      inputProps={{
                        name: "phone",
                        required: true,
                        placeholder: "Escreve seu telefone",
                      }}
                      buttonStyle={{
                        background: "#F5F5F5",
                        padding: "0px 14px !important",
                        height: "44px",
                        borderRadius: "12px",
                        border: "none",
                      }}
                      containerStyle={{
                        background: "#F5F5F5",
                        paddingTop: "10px !important",
                        height: "58px",
                        width: "100%",
                        borderRadius: "5px",
                        marginRight: "14px",
                        marginBottom: "14px",
                      }}
                      inputStyle={{
                        background: "#F5F5F5",
                        padding: "50px !important",
                        width: "97%",
                        borderRadius: "0px",
                        border: "none",
                        color: "#292929",
                        fontSize: "16px",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                      }}
                      value={values.phone}
                      onChange={(phone, country) => {
                        handlePhoneChange(phone, country, setErrors, errors);
                      }}
                      onBlur={handleBlur}
                    />
                    <label className="smallmessage">
                      {errors?.phone && touched.phone && errors.phone}
                    </label>
                  </>
                )}
              </div>
            </div>
            <div className="formbtndiv">
              <IonButton
                expand="block"
                size="large"
                className="enviar-btn"
                type="submit"
                disabled={
                  isLoading ||
                  (!user.email && !values.email) ||
                  (!user.telePhone[0]?.number.length &&
                    !formData?.phone?.slice(formData?.countryCode?.length))
                }
              >
                <IonIcon
                  slot="end"
                  src="/assets/images/userinfo-send-icon.svg"
                />
                ENVIAR
              </IonButton>
              {isLoading && (
                <IonProgressBar
                  type="indeterminate"
                  className="ion-margin-bottom"
                />
              )}
              <div className="informardiv">
                <IonLabel onClick={() => setIsModalOpen(false)}>
                  Informar depois
                </IonLabel>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </IonModal>
  );
};
export default AddUserDetails;
