import React from "react";
import MobileHeader from "./mobileHeader";
import { IonAvatar, IonLabel, IonProgressBar, IonItem } from "@ionic/react";
import Calendar from "react-calendar";
import StoreService from "../../../../services/store.service";
import { formatWeekDays } from "../../../../helper/Common";
const MobileScreen: React.FC<any> = (props) => {
  const {
    // selectedDepartment,
    onBack,
    isContactShow,
    isCalenderShow,
    isLoading,
    isProfessionalListShow,
    staffData,
    selectedStaff,
    setSelectedStaff,
    setSelectedStaffId,
    setIsProfessionalListShow,
    setIsCalenderShow,
    clearState,
    onDateChange,
    onArrowChange,
    onMonthYearChange,
    dateMarks,
    moment,
    setSelectedTime,
    selectedDate,
    setIsContactShow,
    timeSlot,
    setSelectedTimeDetails,
    selectedTime,
    contactFrom,
    setIsSummaryShow,
  } = props;
  let query = new URLSearchParams(props.history.location.search);
  // validatedParam = validatedParam.replace(/%20/g, " ");
  const selectedDepartment = query.get("selectedSpeciality");

  return (
    <>
      <MobileHeader
        selectedDepartment={selectedDepartment}
        onBack={onBack}
        isContactShow={isContactShow}
        isCalenderShow={isCalenderShow}
      />
      {isLoading && <IonProgressBar type="indeterminate" />}
      <div className={"appointment-wapper  app-wrapper"}>
        {!selectedDepartment && (
          <>
            <IonLabel className="maintext">
              Conheça nossas Especialidades
            </IonLabel>
          </>
        )}
        <div
          className={
            isProfessionalListShow && selectedDepartment
              ? "professional-wapper"
              : "hide-section"
          }
        >
          <div className="professional-header">
            <IonLabel>
              Escolha <strong>um especialista</strong>
            </IonLabel>
          </div>
          {staffData?.map((item, index) => (
            <div
              key={index}
              className={
                item.staff_id === selectedStaff?.staff_id
                  ? "professional-card active"
                  : "professional-card"
              }
              onClick={() => {
                setSelectedStaff(item);
                setSelectedStaffId(item.staff_id);
              }}
            >
              <div>
                <img
                  className="professional-picture"
                  src={
                    item.avatar
                      ? item.avatar
                      : "/assets/images/profile-avtar-default.png"
                  }
                  onError={StoreService.addDefaultSrc}

                />
              </div>
              <div>
                <div className="professional-name">{item.name}</div>
                <div className="professional-crp">{item.crm}</div>
              </div>
            </div>
          ))}
          <input
            type="button"
            value="Confirmar"
            className={
              selectedStaff ? "professional-btn " : "professional-btn inactive"
            }
            onClick={() => {
              setIsProfessionalListShow(false);
              setIsCalenderShow(true);
            }}
          />
          <input
            type="button"
            value="Cancelar"
            className="professional-btn canelar"
            onClick={() => {
              onBack()
              // clearState();
            }}
          />
        </div>

        <div
          className={
            !isCalenderShow ? "calender-wapper hide-section" : "calender-wapper"
          }
        >
          <div className="mobile-calander-section">
            <IonItem lines="none" color="transparent">
              <IonAvatar className="summary-picture-m" slot="start">
                <img
                  src={
                    selectedStaff?.avatar
                      ? selectedStaff?.avatar
                      : "/assets/images/profile-avtar-default.png"
                  }
                />
              </IonAvatar>
              <IonLabel>
                <p className="summary-name pb-2">{selectedDepartment}</p>
                <p className="summary-dra-name">{selectedStaff?.name}</p>
              </IonLabel>
            </IonItem>
          </div>
          <div className="appointment-header">Escolha uma data</div>
          <Calendar
            locale="pt-BR"
            minDate={new Date()}
            onChange={(d) => {
              onDateChange(d);
            }}
            onActiveStartDateChange={(d) => onArrowChange(d)}
            onClickMonth={(d) => onMonthYearChange(d)}
            onClickYear={(d) => onMonthYearChange(d)}
            tileClassName={({ date, view }) => {
              if (
                dateMarks.find((x) => x === moment(date).format("DD-MM-YYYY"))
              ) {
                return "highlight";
              } else {
                return "nonHighlight";
              }
            }}
            value={selectedDate}
          />
          <div className="maincircle">
            <div className="time-slot-wapper">
              {timeSlot?.length === 0 && <div className="px-5">No momento, não há intervalos de tempo disponíveis para a data selecionada. Selecione outra data.</div>}
              {timeSlot?.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setSelectedTime(item?.slots);
                      setSelectedTimeDetails(item);
                    }}
                    className={
                      selectedTime === item.slots
                        ? "time-slot active"
                        : "time-slot"
                    }
                  >
                    {item.slots}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="buttonbottom">
            <input
              type="button"
              value="Confirmar agendamento"
              className={
                selectedDate && selectedTime
                  ? "professional-btn "
                  : "professional-btn inactive"
              }
              onClick={() => {
                setIsContactShow(true);
                setIsCalenderShow(false);
              }}
            />
            <input
              type="button"
              value="Cancelar"
              className="professional-btn canelar"
              onClick={() => {
                onBack()
                // clearState();
              }}
            />
          </div>
        </div>
        <div
          className={
            !isContactShow
              ? "contact-details-main hide-section"
              : "contact-details-main"
          }
        >
          <div className={"summary-wapper"}>
            <div className="summary-card">
              <div className="summary-card-inner-m">
                <div className="summary-profile-div">
                  <img
                    className="summary-picture-m"
                    src={
                      selectedStaff?.avatar
                        ? selectedStaff?.avatar
                        : "/assets/images/profile-avtar-default.png"
                    }
                  />
                  <div>
                    <div className="departement-text">{selectedDepartment}</div>
                    <div className="summary-name">{selectedStaff?.name}</div>
                    <div className="summary-crm">{selectedStaff?.crm}</div>
                  </div>
                </div>
              </div>
              <div className="summary-date-time">
                {moment(selectedDate, "DD/MM/YYYY").format("DD")}/
                {moment(selectedDate, "DD/MM/YYYY").format("MM")},
                {formatWeekDays(moment(selectedDate, "DD/MM/YYYY").day())}, às{" "}
                {selectedTime?.replace(":", "h")}
              </div>
              <div
                className="summary-edit"
                onClick={() => {
                  setIsSummaryShow(false);
                  setIsContactShow(false);
                  setIsCalenderShow(true);
                }}
              >
                <span className="text-pointer">Editar</span>
              </div>
            </div>
          </div>
          <div className="appointment-header">
            Verifique seu e-mail e telefone
          </div>
          <div className="formik-form">{contactFrom()}</div>
        </div>
      </div>
    </>
  );
};

export default MobileScreen;
