import { IonImg } from "@ionic/react";
import React from "react";

const MobileHeader: React.FC<any> = ({
  selectedDepartment,
  onBack,
  isContactShow,
  isCalenderShow
}) => {
  return (
    selectedDepartment && <div className="mobile-header-wapper">
      <div className="icon-div">
        {
          selectedDepartment && (
            <IonImg src="assets/images/arrow-left-orange.svg" onClick={() => onBack()} />
          )
        }
      </div>
      {
        !isContactShow ? (
          <div>
            <div className="header-text-div">
              <div className="header-text">
                <IonImg src="assets/images/header-history.svg" />
                Agendamento de Consulta</div>
              {selectedDepartment && !isCalenderShow && (
                <>
                  <div className="departement-text">{selectedDepartment}</div>
                </>
              )}
            </div>
          </div>
        ) : (
          <div>
            <div className="header-text-div">
              <div className="header-text">
                <IonImg src="assets/images/header-history.svg" />
                Agendamento de consulta</div>
            </div>
          </div>
        )
      }
    </div>
  );
};

export default MobileHeader;
