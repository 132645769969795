import React from "react";
import * as DetectRTC from 'detectrtc';
import { IonButton, IonModal } from "@ionic/react";
function loadDetectRTC() {
    return new Promise<void>((resolve) => {
        DetectRTC.load(() => {
            ; (window as any).DetectRTC = DetectRTC
            resolve()
        })
    });
}

export const PresupportError = ({ isPresupportError, setIsPresupportError }) => {

    return <IonModal
        isOpen={isPresupportError}
        onDidDismiss={() => setIsPresupportError(false)}
        cssClass="logout-modal history">
        <div>Iremos solicitar acesso à sua câmera e microfone! Para prosseguir, você deve PERMITIR o acesso!</div>
        <IonButton
            // color="tertiary"
            fill="solid"
            shape="round"
            expand="block"
            className="mb-0 mt-3"
            onClick={() => setIsPresupportError(false)}
            style={{ "--background": "#FE5000" }}
        >
            Cancelar
        </IonButton>
    </IonModal>
}

export const checkconnection = async (setIsPresupportError) => {
    await loadDetectRTC();
    const supportsWebRTC = DetectRTC.isWebRTCSupported;
    if (!supportsWebRTC) {
        const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        return permissions
    } else {
        const isPermissionAlreadyGranted = DetectRTC.isWebsiteHasWebcamPermissions && DetectRTC.isWebsiteHasMicrophonePermissions;
        const hasRequiredMediaOnDevice = DetectRTC.hasWebcam && DetectRTC.hasMicrophone;
        const videoInputDevices = DetectRTC.videoInputDevices && DetectRTC.videoInputDevices[0]?.deviceId;
        if (isPermissionAlreadyGranted && hasRequiredMediaOnDevice && videoInputDevices) {
            return Promise.resolve(true)
        } else {
            const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            return permissions
        }
    }
}