import {
  IonLabel, IonRow, useIonToast
} from "@ionic/react";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router";
import Modal from "../../components/Modal";
import { Button } from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import storeService from "../../services/store.service";

const PasswordDeleteModal: React.FC<any> = ({
  isOpen,
  setIsOpen,
  text,
  ...props
}) => {
  const [present] = useIonToast();
  const [password, setPassword] = useState<string>("");
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const history = useHistory()
  const handleChange = (event: any): void => {
    setPassword(event.target.value);
  };
  const [cpfError, setCpfError] = useState<boolean>(false);
  const [cpf, setCpf] = useState<string>("");
  const [birthdate, setBirthdate] = useState<string>("");
  const [birthError, setBirthError] = useState<boolean>(false);


  const logout = () => {
    storeService.logout(history)
  };

  /**
   * Function for delete account
   */
  const deleteAccount = () => {

    let data = {
      CPF: cpf,
      DOB: birthdate,
      deleteAccount: true,
    }
    axios
      .post(
        `${process.env.REACT_APP_API_PARSE_BASE_URL}/auth/revert_terms`,
        data
      )
      .then(async (resp: any) => {

        if (resp?.success) {
          logout();
        } else {
          present(resp?.message, 2500);
        }
      })
      .catch((error: any) => {
        console.error("logn error", error.response);
      });
  }

  return (
    <>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} setPassword={setPassword}>
        <IonLabel className="password-delete-title text-align-center">
          {text || "Insira sua CPF e data de nascimento para excluir a conta"}
        </IonLabel>
        <div className="passWelContent">
          <IonRow className="mb-2">
            <Input
              name="cpf"
              type="tel"
              className="custom-mask maskedInput-border"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
              ]}
              placeholder="CPF"
              errors={cpfError}
              errorText={"Digite o number do seu CPF"}
              value={cpf}
              handleChange={(event: any) => {
                setCpf(event.target.value.replace(/-|_|\./g, ""));
                setCpfError(false);
              }}
            />
          </IonRow>
          <IonRow>
            <Input
              className="custom-mask maskedInput-border"
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              placeholder="Data de nascimento"
              value={birthdate}
              type="tel"
              name="birthdate"
              errors={birthError}
              errorText={"Digite sua data de nascimento"}
              handleChange={(event: any) => {
                let temp = event.target.value.replaceAll("_", "");
                setBirthdate(temp);
                setBirthError(false);
              }}
            />
          </IonRow>
        </div>
        <Button
          // className=""
          disabled={!cpf || cpf.length < 11 || !birthdate || birthdate.length < 10}
          text="Confirmar exclusão"
          expand="full"
          // shape="round"
          color="primary"
          fill="outline"
          className="greenoutline-btn w-100"
          handleClick={() => {
            deleteAccount()
          }}
        />
        <Button
          expand="full"
          // shape="round"
          fill="clear"
          color="danger"
          className="greenoutline-btn w-100"
          text="Cancelar" handleClick={() => {
            setIsOpen(false);
            setCpf("");
            setCpfError(false);
            setBirthdate("");
            setBirthError(false);
          }
          } />
      </Modal>
      <Modal
        isOpen={isDeleteOpen}
        setIsOpen={setIsDeleteOpen}
        btnImg={"/assets/images/checkicon.svg"}
        text="Conta excluída com sucesso."
      />
    </>
  );
};
export default PasswordDeleteModal;
