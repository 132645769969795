export const isTrainningCassi = window.location.href.indexOf('dev.medicocassi.iron.fit') !== -1
export const isProdCassi = !isTrainningCassi && window.location.href.indexOf('medicocassi.iron.fit') !== -1
export const isDevCassi = !isTrainningCassi && !isProdCassi && window.location.href.indexOf('medicocassi.dev.iron.fit') !== -1

export const isProdPlansaude = window.location.href.indexOf('plansaude.iron.fit') !== -1
export const isDevPlansaude = !isProdPlansaude && window.location.href.indexOf('plansaude.dev.iron.fit') !== -1

export const isProdNori = window.location.href.indexOf('nori.iron.fit') !== -1
export const isDevNori = !isProdNori && window.location.href.indexOf('nori.dev.iron.fit') !== -1

export const isProdBemCassi = window.location.href.indexOf('bemcassi.iron.fit') !== -1
export const isDevBemCassi = !isProdBemCassi && window.location.href.indexOf('bemcassi.dev.iron.fit') !== -1

export const isProdIbrasa = window.location.href.indexOf('ibrasa.iron.fit') !== -1
export const isDevIbrasa = !isProdIbrasa && window.location.href.indexOf('ibrasa.dev.iron.fit') !== -1

export const isProdSirioLibanes = window.location.href.indexOf('siriolibanes.iron.fit') !== -1
export const isDevSirioLibanes = !isProdSirioLibanes && window.location.href.indexOf('siriolibanes.dev.iron.fit') !== -1

export const isProdSesiSp = window.location.href.indexOf('sesisp.iron.fit') !== -1
export const isDevSesiSp = !isProdSesiSp && window.location.href.indexOf('sesisp.dev.iron.fit') !== -1

export const isProdSesiDf = window.location.href.indexOf('sesidf.iron.fit') !== -1
export const isDevSesiDf = !isProdSesiDf && window.location.href.indexOf('sesidf.dev.iron.fit') !== -1

export const isProdPac19 = window.location.href.indexOf('pac-19.iron.fit') !== -1
export const isDevPac19 = !isProdPac19 && window.location.href.indexOf('pac-19.dev.iron.fit') !== -1

export const isTrainning = isTrainningCassi
export const isProd = isProdCassi || isProdPlansaude
export const isDev = !isTrainning && !isProd

export const isLocal = window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1

export const whitelabelCustomer = getWhitelabelCustomer()

export const isCassi = whitelabelCustomer === 'cassi'
export const isPlansaude = whitelabelCustomer === 'plansaude'
export const isPac19 = whitelabelCustomer === 'pac19'
export const isNori = whitelabelCustomer === 'nori'
export const isSesiSp = whitelabelCustomer === 'sesisp'
export const isSesiDf = whitelabelCustomer === 'sesidf'
export const isBemCassi = whitelabelCustomer === 'bemcassi'
export const isSirioLibanes = whitelabelCustomer === 'siriolibanes'
export const isIbrasa = whitelabelCustomer === 'ibrasa'


export function getApsBaseUrl() {
  if (whitelabelCustomer === 'plansaude' || whitelabelCustomer === 'nori') {
    if (isProd) return 'https://aps.iron.fit'
    return 'https://devapscassi.iron.fit'
  }
  if (isTrainningCassi) return 'https://treinamentoapscassi.iron.fit'
  if (isProdCassi) return 'https://aps.iron.fit'
  return 'https://devapscassi.iron.fit'
}

function getWhitelabelCustomer() {
  if (isLocal) return process.env.MIX_LOCAL_WHITELABEL_CUSTOMER || 'ampla-saude'
  if (isProdPlansaude || isDevPlansaude) {
    return 'plansaude'
  }

  if (isProdNori || isDevNori) {
    return 'nori'
  }

  if (isProdSesiSp || isDevSesiSp) {
    return 'sesisp'
  }

  if (isProdSesiDf || isDevSesiDf) {
    return 'sesidf'
  }

  if (isProdBemCassi || isDevBemCassi) {
    return 'bemcassi'
  }

  if (isProdSirioLibanes || isDevSirioLibanes) {
    return 'siriolibanes'
  }

  if (isProdIbrasa || isDevIbrasa) {
    return 'ibrasa'
  }

  if(isProdPac19 || isDevPac19) {
    return 'pac19'
  }

  return 'ampla-saude'
}

// if (isPlansaude) window.document.title = 'Telemedicina Plansaúde ON'
// if (isNori) window.document.title = 'Telemedicina Nori'
// if (isCassi) window.document.title = 'Telemedicina Cassi'
// if (isSesiSp) window.document.title = 'Telemedicina Sesi São Paulo'
// if (isSesiDf) window.document.title = 'Telemedicina Sesi Distrito Federal'
// if (isBemCassi) window.document.title = 'Telemedicina Bem Cassi'
// if (isIbrasa) window.document.title = 'Telemedicina Ibrasa'
// if (isSirioLibanes) window.document.title = 'Adma'
// if (isPac19) window.document.title = 'Telemedicina PAC-19'
