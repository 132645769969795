import axios from "axios";
import { corporation } from "../helper/corporation";

axios.interceptors.request.use((config) => {
  if (config?.data) {
    config.data["corporation"] = corporation;
  } else {
    if (!config.url.includes("slug=")) {
      if (config.url.includes("?")) {
        config["url"] += `&slug=${corporation}`;
      } else {
        config["url"] += `?slug=${corporation}`;
      }
    }
  }
  return config;
});
