import * as React from 'react'

export const FailedDuplicatedVideoAccessScreen: React.FC<{ onUseHereClick: () => void }> = ({ onUseHereClick }) => {
  return (
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <h5 className="connection_text text-center mb-5" style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>Ops, identificamos acesso duplicado ao atendimento. Por favor, mantenha apenas uma janela e feche as demais.</h5>
        <button className="btn btn-success" onClick={onUseHereClick} style={{ width: "280px" }}>Conectar na chamara por aqui</button>
      </div>
    </div>
  )
}
