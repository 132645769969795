import * as React from "react";
import ironFit, {
  twilio,
  liveSupport,
  staffs,
  patients,
} from "../../clients/iron-fit-patient";
import { isPlansaude } from "../../utils";
import { PatientVideo } from "./PatientVideo";
import { StaffVideo } from "./StaffVideo";
import { StaffAudio } from "./StaffAudio";
import { parseApi } from "../../clients/iron-fit-parse";
import { whitelabelCustomer } from "../../utils";
import _ from "lodash";
import ChatComponent from "./ChatCompnent";
import platform from "../../helper/platform";
import { IonModal } from "@ionic/react";

interface DoctorInfo {
  name: string;
  register: string;
}

export const LiveVideo: React.FC<{ supportId: string, history?: any }> = ({ supportId, history }) => {
  const [doctorInfo, setDoctorInfo] = React.useState<DoctorInfo | null>(null);
  const { connect } = twilio.useVideoContext();
  const roomState = twilio.useRoomState();
  const { patientId } = liveSupport.useLivePatientSync();

  const [videoToken] = liveSupport.usePatientVideoAccessToken(
    supportId,
    patientId
  );
  React.useEffect(() => {
    if (!videoToken) return;
    if (roomState !== "disconnected") return;
    connect(videoToken).catch((error) => {
      console.error("Cannot connect with twilio", error);
    });
  }, [videoToken, roomState]);

  const [finishing, setFinishing] = React.useState(false);
  const finish = React.useCallback(async () => {
    if (finishing) return;
    setFinishing(true);
    try {
      await ironFit.liveSupport().finishSupport(supportId);
    } catch (error) {
      console.error("Error finishing support");
    }
    setFinishing(false);
  }, [finishing, setFinishing, supportId]);

  const [audioEnabled, toggleAudio] = twilio.useLocalAudioToggle();

  const [support] = liveSupport.useLiveSupport(supportId);
  const staffId = support && support.staffId;
  const parseId = support && support.parseId;
  const isRoomConnected = roomState === "connected";

  const micButtonClassName = isPlansaude ? "btn-warning" : "btn-link";
  const [staff, staffLoading] = staffs.useStaff(staffId);
  const [chatModal, setChatModal] = React.useState<boolean>(false);

  React.useEffect(() => {
    const fetchDoctorInfo = async () => {
      try {
        if (!support || whitelabelCustomer !== "sesidf") return;
        const result = await parseApi.get(
          `teleconsultation/${support.parseId}`
        );

        if (result.status === 200) {
          setDoctorInfo(result.data.data.doctor);
        }
      } catch (e) {}
    };
    fetchDoctorInfo();
  }, [support, whitelabelCustomer]);

  const { localTracks: publishedLocalTracks } = twilio.useVideoContext();

  const goToHome = () => {
    for (const track of publishedLocalTracks) {
      track.stop();
    }
    window.location.href = "/home";
  };

  return (
    <>
      {platform.isMobileweb && (
        <>
          <IonModal
            isOpen={true}
            cssClass="live_video_mobile"
            onDidDismiss={() => {
              setChatModal(false);
            }}
          >
            <div className="livevideo-ampla-saude">
              <div className="live_support position-relative rounded">
                <button
                  type="button"
                  className="btn cancel-icon test text-capitalize mx-1"
                  onClick={goToHome}
                  disabled={finishing}
                >
                  <img src="/assets/images/cancel-icon.svg" alt="cancel" />
                </button>
                <div id="remote-media1" className="remote-media-class">
                  {isRoomConnected && staffId && (
                    <StaffAudio staffId={staffId} />
                  )}
                  {isRoomConnected && staffId && (
                    <StaffVideo staffId={staffId} />
                  )}
                </div>
                <div className="suppott_btn text-center my-3 position-absolute show_controler">
                  <div>
                    <div className="profile-info">
                      {staff?.avatarUrl ? (
                        <img src={staff?.avatarUrl} className="staff-avatar" />
                      ) : (
                        <img src="/assets/images/defaultProfile.png" />
                      )}
                      <div className="namediv">
                        {staff?.name && <span>{staff?.name}</span>}
                        {doctorInfo?.register && (
                          <span>CRM {doctorInfo?.register}</span>
                        )}
                      </div>
                    </div>
                    {audioEnabled && (
                      <button
                        type="button"
                        className={`btn ${micButtonClassName} btn-lg text-capitalize mx-1 text-dark`}
                        onClick={toggleAudio}
                      >
                        <img src="/assets/images/mic.svg" alt="Mute" />
                      </button>
                    )}
                    {!audioEnabled && (
                      <button
                        type="button"
                        className={`btn ${micButtonClassName} btn-lg text-capitalize mx-1 text-dark`}
                        onClick={toggleAudio}
                      >
                        <img src="/assets/images/mic-mute.png" alt="Unmute" />
                      </button>
                    )}                    
                  </div>
                </div>
                <div
                  className="user_two rounded d-flex align-items-center justify-content-center flex-column not_avail rounded"
                  id="local_mvp_video"
                >
                  <PatientVideo />
                </div>
              </div>
            </div>
          </IonModal>
          {/* <IonModal cssClass="chatlistdiv"
            isOpen={chatModal}
            onDidDismiss={() => { 
              setChatModal(false);
            }}
          >
            <div className="chat-design">
              {parseId && 
                <ChatComponent
                  patient_id={patientId}
                  appointment_Id={parseId}
                  staff_id={staffId}
                  setChatModal={setChatModal}
                />
              }
            </div>
          </IonModal> */}
        </>
      )}
      {!platform.isMobileweb && (
        <div className="livevideo-ampla-saude">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="live_support position-relative rounded">
                  <div id="remote-media1" className="remote-media-class">
                    {isRoomConnected && staffId && (
                      <StaffAudio staffId={staffId} />
                    )}
                    {isRoomConnected && staffId && (
                      <StaffVideo staffId={staffId} />
                    )}
                  </div>
                  <div className="suppott_btn text-center my-3 position-absolute show_controler">
                    <button
                      type="button"
                      className="btn cancel-icon test text-capitalize mx-1"
                      onClick={goToHome}
                      disabled={finishing}
                    >
                      <img src="/assets/images/cancel-icon.svg" alt="cancel" />
                    </button>
                    {audioEnabled && (
                      <button
                        type="button"
                        className={`btn ${micButtonClassName} btn-lg text-capitalize mx-1 text-dark`}
                        onClick={toggleAudio}
                      >
                        <img src="/assets/images/mic.svg" alt="Mute" />
                      </button>
                    )}
                    {!audioEnabled && (
                      <button
                        type="button"
                        className={`btn ${micButtonClassName} btn-lg text-capitalize mx-1 text-dark`}
                        onClick={toggleAudio}
                      >
                        <img src="/assets/images/mic-mute.png" alt="Unmute" />
                      </button>
                    )}
                    <div>
                      <div className="profile-info">
                        {staff?.avatarUrl ? (
                          <img
                            src={staff?.avatarUrl}
                            className="staff-avatar"
                          />
                        ) : (
                          <img src="/assets/images/defaultProfile.png" />
                        )}
                        {staff?.name && <span>{staff?.name} </span>}
                        {doctorInfo?.register && (
                          <span>/ CRM: {doctorInfo?.register}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="user_two rounded d-flex align-items-center justify-content-center flex-column not_avail rounded"
                    id="local_mvp_video"
                  >
                    <PatientVideo />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="chat-design">
                  {/* {parseId &&
                    <ChatComponent
                    patient_id={patientId}
                    appointment_Id={parseId}
                    staff_id={staffId}
                    setChatModal={setChatModal}
                  />} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
