import axios from 'axios';

// const parseStaffIdEl = document.head.querySelector<HTMLTemplateElement>('meta[name="staff_id"]')
// export const parseStaffId = parseStaffIdEl ? String(parseStaffIdEl.content) || undefined : undefined
// const parseStaffTypeEl = document.head.querySelector<HTMLTemplateElement>('meta[name="staff_type"]')
// const parseStaffType = parseStaffTypeEl ? String(parseStaffTypeEl.content) as StaffType : undefined
// const parseStaffNameEl = document.head.querySelector<HTMLTemplateElement>('meta[name="staff_name"]')
// const parseStaffName = parseStaffNameEl ? String(parseStaffNameEl.content) || undefined : undefined
// const parseStaffApsKnowledgeEl = document.head.querySelector<HTMLTemplateElement>('meta[name="staff_aps_knowledge"]')
// const parseStaffApsKnowledge = parseStaffApsKnowledgeEl ? !!Number(parseStaffApsKnowledgeEl.content) || undefined : undefined
// export const parseStaff: Staff | undefined = parseStaffType && { type: parseStaffType }
// if (parseStaff && parseStaffName) parseStaff.name = parseStaffName
// if (parseStaff && parseStaffApsKnowledge) parseStaff.apsKnowledge = true
// const parseStaffEmailEl = document.head.querySelector<HTMLTemplateElement>('meta[name="staff_email"]')
// export const parseStaffEmail = parseStaffEmailEl ? String(parseStaffEmailEl.content) || undefined : undefined

// const parseSessionTokenEl = document.head.querySelector<HTMLTemplateElement>('meta[name="token"]')
// const parseSessionToken = parseSessionTokenEl && parseSessionTokenEl.content
const parseSessionToken = ""
export const parseApi = axios.create({
  baseURL: process.env.REACT_APP_MIX_PARSE_API,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Parse-Session-Token': parseSessionToken,
  },
})
