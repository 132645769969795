import * as React from 'react'
import { getApsBaseUrl } from '../../utils'

export const RedirectingApsScreen: React.FC = () => {
  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      const apsBaseUrl = getApsBaseUrl()
      const redirectUrl = new URL('/patient-appointments', apsBaseUrl)
      redirectUrl.search = window.location.search
      redirectUrl.searchParams.delete('aps_redirect')
      window.location.href = redirectUrl.href
    }, 2000)
    return () => {
      clearTimeout(timeoutId)
    }
  }, [])
  return(
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <img src="/assets/image/media/btn_loader.gif" alt="" width="60" />
        <h5 className="connection_text mt-4 mb-5">Redirecionando...</h5>
      </div>
    </div>
  )
}
