import React from "react";
import ReactDOM from "react-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./AwaitingScreen.scss";
const renderTime = (data: any) => {
  const tempTime = data.remainingTime;
  const minutes: any =
    Math.floor(tempTime / 60) > 10
      ? Math.floor(tempTime / 60)
      : "0" + Math.floor(tempTime / 60);
  const seconds =
    tempTime - parseInt(minutes) * 60 >= 10
      ? tempTime - parseInt(minutes) * 60
      : "0" + (tempTime - parseInt(minutes) * 60);
  return <div className="counter-text">{`${minutes}:${seconds}`}</div>;
};

const CountDownTimer = (props: any) => {
  const { duration = 0 } = props;
  return (
    <div className="counter-div">
      <CountdownCircleTimer
        size={156}
        rotation={"counterclockwise"}
        isPlaying
        duration={duration}
        colors={"#00c1f2"}
        //   onComplete={() => [true, 1000]}
      >
        {renderTime}
      </CountdownCircleTimer>
    </div>
  );
};

export default CountDownTimer;
