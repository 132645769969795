import { reverse, get } from 'lodash'
import { Patient } from '@irontrainers/live-assist/lib/rtdb'

import { parseApi } from '../../../clients/iron-fit-parse'
import { whitelabelCustomer } from '../../../utils'
import queryString from 'querystring';
import jwt from 'jwt-decode'


const getAppName = () => {
  const queryParams = new URLSearchParams(window.location.search.substring(1));
    const baseString = queryParams.get('name') || '';

    if(whitelabelCustomer === 'cassi' || whitelabelCustomer === 'bemcassi'){
    const { User }:any = jwt(baseString)
    if(User.grupo === 'SL') {
      return 'Saúde na Linha Cassi'
    } else if(User.grupo === 'APS') {
      return 'Cassi APS'
    } else if(User.grupo === 'BC') {
      return 'Bem Cassi'
    } else {
      return 'Cassi'
    }
  } else if (whitelabelCustomer === 'plansaude' || whitelabelCustomer === "pac19"){
    return 'plansaude'
  } else if (whitelabelCustomer === 'sesisp'){
    return 'sesisp'
  } else if (whitelabelCustomer === 'sesidf'){
    return 'sesidf'
  } else if(whitelabelCustomer === 'nori'){
    return 'nori'
  } else if(whitelabelCustomer === 'bemcassi'){
    return 'Bem Cassi'
  } else if(whitelabelCustomer === 'siriolibanes'){
    return 'Sírio-Libanês'
  } else if(whitelabelCustomer === 'ibrasa'){
    return 'Ibrasa'
  }
  return 'ampla-saude'
}

export async function legacyAuth(paramsPatientAttributes: ParamsPatientAttributes): Promise<[string, Patient]> {
  const { cpf, password, email, healthcare_team } = paramsPatientAttributes
  // console.warn("paramsPatientAttributes",paramsPatientAttributes);
  
  // const appName = `${whitelabelCustomer}${isAps ? ' APS' : ''}`
  try {
    const response = await parseApi.post('auth/cassi', { cpf, password, email, healthcare_team })
    const isUserBlocked = get(response, 'data.data.user.isBlocked', false)
    if (isUserBlocked) throw new Error('User is blocked')
    const userId = get(response, 'data.data.user.id')
    if (!userId) throw new Error('Cannot obtain userId from auth v1 payload')

    const user = get(response, 'data.data.user', {})
    const patient: Patient = {}
    patient.name = user.name
    patient.email = user.email
    patient.app_name = getAppName()
  
    if (user.corporation) patient.corporationId = user.corporation
    if (user.phones && user.phones[0]) patient.number = user.phones[0].number

    if (!patient.name && paramsPatientAttributes.name) patient.name = paramsPatientAttributes.name
    if (!patient.email && paramsPatientAttributes.email) patient.email = paramsPatientAttributes.email
    if (!patient.number && paramsPatientAttributes.phones && paramsPatientAttributes.phones[0]) patient.number = paramsPatientAttributes.phones[0].number

    return [userId, patient]
  } catch (error) {
    if (error && error.message === 'User is blocked') throw error
    console.error('auth v1 error', error)
  }

  try {
    const response = await parseApi.post('auth/signup/telemedicine', paramsPatientAttributes)
    const isUserBlocked = get(response, 'data.user.isBlocked', false)
    if (isUserBlocked) throw new Error('User is blocked')
    const userId = get(response, 'data.user.id')
    if (!userId) throw new Error('Cannot obtain userId from signup v1 payload')

    const user = get(response, 'data.user', {})
    const patient: any = {}
    patient.name = user.name
    patient.email = user.email
    patient.app_name = getAppName()

    if (!patient.name && paramsPatientAttributes.name) patient.name = paramsPatientAttributes.name
    if (!patient.email && paramsPatientAttributes.email) patient.email = paramsPatientAttributes.email
    if (!patient.number && paramsPatientAttributes.phones && paramsPatientAttributes.phones[0]) patient.number = paramsPatientAttributes.phones[0].number

    return [userId, patient]
  } catch (error) {
    console.error('signup v1 error', error)
    throw error
  }
}

export async function cpfAuth(cpf: string, birthdate: string, corporation: string): Promise<[string, Patient]> {
  let corpException = corporation;
  if (whitelabelCustomer === "pac19") {
    corpException = "plansaude"
  }
  const appName = `${whitelabelCustomer}`
  try {
    const response = await parseApi.post('auth/plansaude/login', { cpf, birthdate, corporation:corpException })
    const isUserBlocked = get(response, 'data.data.user.isBlocked', false)
    if (isUserBlocked) throw new Error('User is blocked')
    const userId = get(response, 'data.result.user.id')
    if (!userId) throw new Error('Cannot obtain userId from auth v1 payload')

    const user = get(response, 'data.result.user', {})
    const patient: any = {}
    patient.name = user.name
    patient.email = user.email
    patient.app_name = appName
    patient.corporationId = corpException
    patient.userType = user.userType
    if (user.phones && user.phones[0]) patient.number = user.phones[0].number

    return [userId, patient]
  } catch (error) {
    const responseErrorMessage = get(error, 'response.data.message', error.message)
    if (responseErrorMessage === 'Didn\'t find token user') throw new Error('Não encontramos um Usuário com estes dados')
    throw new Error(responseErrorMessage)
  }
}

export interface ParamsPatientAttributes {
  id?: number
  cpf?: string
  password?: string
  birthdate?: string
  name?: string
  email?: string
  corporation?: string
  is_dependent?: boolean
  address?: { state?: string, city?: string }
  phones?: { number: string, primary: boolean }[]
  contract?: { number?: string, description?: string },
  healthcare_team?: string
  isAps?: boolean
}
export function getParamsPatientAttributes(authBaseString: string): ParamsPatientAttributes {

  console.log(authBaseString);

  let cassiPatientParams = null
  if(localStorage.getItem('@patientDashboard') !== null){
    cassiPatientParams = JSON.parse(localStorage.getItem('@patientDashboard') || '');
  } else {
    cassiPatientParams = atob(authBaseString).split('|')
  }
  const cassiPatientAttributes: ParamsPatientAttributes = {};

  if (cassiPatientParams && cassiPatientParams.id_cassi) cassiPatientAttributes.id = parseInt(cassiPatientParams.id_cassi, 10);
  if (cassiPatientParams && cassiPatientParams.cpf) cassiPatientAttributes.cpf = cassiPatientParams.cpf;
  if (cassiPatientParams && cassiPatientParams.password) cassiPatientAttributes.password = cassiPatientParams.password;
  if (cassiPatientParams && cassiPatientParams.birthdate) cassiPatientAttributes.birthdate = cassiPatientParams.birthdate;
  if (cassiPatientParams && cassiPatientParams.name) cassiPatientAttributes.name = cassiPatientParams.name;
  if (cassiPatientParams && cassiPatientParams.email) cassiPatientAttributes.email = cassiPatientParams.email;
  cassiPatientAttributes.corporation = 'ampla-saude';

  if (cassiPatientParams && cassiPatientParams.is_dependent) {
    cassiPatientAttributes.is_dependent = parseInt(cassiPatientParams.is_dependent) !== 1;
  }

  if (cassiPatientParams && (cassiPatientParams.state || cassiPatientParams.city)) {
    cassiPatientAttributes.address = {};
    cassiPatientAttributes.address.state = cassiPatientParams.state;
    cassiPatientAttributes.address.city = cassiPatientParams.city;
  }

  if (cassiPatientParams && (cassiPatientParams.cellphone || cassiPatientParams.phone)) {
    cassiPatientAttributes.phones = [];
    if (cassiPatientParams.cellphone) {
      cassiPatientAttributes.phones.push({ number: cassiPatientParams.cellphone, primary: true });
    }
    if (cassiPatientParams.phone) {
      cassiPatientAttributes.phones.push({ number: cassiPatientParams.phone, primary: false });
    }
  }

  if (cassiPatientParams && (cassiPatientParams.cellphone || cassiPatientParams.phone)) {
    cassiPatientAttributes.contract = {};
    if (cassiPatientParams.contract_number) cassiPatientAttributes.contract.number = cassiPatientParams.contract_number;
    if (cassiPatientParams.contract_number) {
      cassiPatientAttributes.contract.description = cassiPatientParams.contract_description;
    }
  }

  if (cassiPatientParams && cassiPatientParams.app_name) {
    cassiPatientAttributes.healthcare_team = cassiPatientParams.app_name;
    if (cassiPatientParams.app_name === 'APS') cassiPatientAttributes.isAps = true;
  }


  console.log(cassiPatientAttributes);
  return cassiPatientAttributes;
}


export function getParamsPatientAttributesByBase64(cassiBaseString: string): ParamsPatientAttributes {
  const cassiPatientParams = atob(cassiBaseString).split('|')
  const cassiPatientAttributes: ParamsPatientAttributes = {}
  if (cassiPatientParams[0]) cassiPatientAttributes.id = parseInt(cassiPatientParams[0])
  if (cassiPatientParams[1]) cassiPatientAttributes.cpf = cassiPatientParams[1]
  if (cassiPatientParams[1]) cassiPatientAttributes.password = cassiPatientParams[1]
  if (cassiPatientParams[2]) cassiPatientAttributes.birthdate = reverse(cassiPatientParams[2].split('/')).join('-')
  if (cassiPatientParams[3]) cassiPatientAttributes.name = cassiPatientParams[3]
  if (cassiPatientParams[4]) cassiPatientAttributes.email = cassiPatientParams[4]
  cassiPatientAttributes.corporation = 'ampla-saude'
  if (cassiPatientParams[5]) cassiPatientAttributes.is_dependent = parseInt(cassiPatientParams[5]) !== 1
  if (cassiPatientParams[6] || cassiPatientParams[7]) {
    cassiPatientAttributes.address = {}
    if (cassiPatientParams[6]) cassiPatientAttributes.address.state = cassiPatientParams[6]
    if (cassiPatientParams[7]) cassiPatientAttributes.address.city = cassiPatientParams[7]
  }
  if (cassiPatientParams[8] || cassiPatientParams[9]) {
    cassiPatientAttributes.phones = []
    if (cassiPatientParams[8]) cassiPatientAttributes.phones.push({ number: cassiPatientParams[8], primary: true })
    if (cassiPatientParams[9]) cassiPatientAttributes.phones.push({ number: cassiPatientParams[9], primary: false })
  }
  if (cassiPatientParams[10] || cassiPatientParams[11]) {
    cassiPatientAttributes.contract = {}
    if (cassiPatientParams[10]) cassiPatientAttributes.contract.number = cassiPatientParams[10]
    if (cassiPatientParams[10]) cassiPatientAttributes.contract.description = cassiPatientParams[11]
  }
  return cassiPatientAttributes
}
