import * as React from "react";
import { liveSupport } from "../../clients/iron-fit-patient";
import { secureWebStorage } from "../../clients/secure-web-storage";
import { detect } from "detect-browser";
import ironFit, { liveSupport as live } from "../../clients/iron-fit-patient";
import {
  liveSupport as liveSupports,
  staffs,
} from "@irontrainers/live-assist/react";
import { getPlatforms, IonButton, IonImg } from "@ionic/react";
import { map } from "lodash";
import platform from "../../helper/platform";
import storeService from "../../services/store.service";
import { SpecialityButton } from "./SpecialityButton";

export const WelcomeBanners: React.FC<{
  onStartClick: () => void;
  onSignoutClick: () => void;
  patientId: any;
  doctors: any;
  selectedDepartment: any;
  setSelectedDepartment: any;
}> = ({
  onStartClick,
  onSignoutClick,
  patientId,
  doctors,
  selectedDepartment,
  setSelectedDepartment,
}) => {
  const {
    loading,
    isSupportAvailable,
    supportStartForecast,
    awaitingSince,
    patient,
  } = liveSupport.useLivePatientSync();


  const disabledStart = loading || !isSupportAvailable || !!awaitingSince;
  const browser = detect();
  const [screen, setScreen] = React.useState<string>("callScreen");
  const [livePatient, livePatientLoading] = live.useLivePatient(patientId);
  const patientCorporationId = livePatient?.corporationId;
  const runningSupportId = livePatient?.runningSupportId;
  const [support] = liveSupport.useLiveSupport(runningSupportId);

  const forecastMinutes = (time: any) => {
    return Math.round((time || 0) / 60 / 1000);
  };

  const [userData, setUserData] = React.useState<any>(null);
  React.useEffect(() => {
    storeService
      .get("user")
      .then((res) => {
        setUserData(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  return (
    <>
      {screen === "callScreen" && (
        <div className="row">
          {!platform.isMobileweb && (
            <div className="col-md-5 position-relative">
              <IonImg
                src="/assets/images/home-amplairon.png"
                className="symptoms-img"
              />
            </div>
          )}
          <div className="col-md-12 col-xl-7 rightpart">
          {!platform.isMobileweb && (
                    <div className="patient-name">Olá, {userData?.name}</div>
                  )}
            <div className="row">
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="innermainbox">
                <SpecialityButton
                  doctors={doctors}
                  selectedDepartment={selectedDepartment}
                  setSelectedDepartment={setSelectedDepartment}
                />
                </div>
                <div className="bottomtextdiv text-center w-100 mt-3 d-none d-md-block">Agende uma consulta com o especialista
médico que você desejar</div>
              </div>
              <div className="col-md-6">
                <div className="innermainbox">
                 
                  <div className="boxes">
                    <div className="pronto-atend-view">
                      <div className="pronto-atend-header-txt">
                        Pronto Atendimento Digital
                      </div>
                      <div className="pronto-atend-sub-txt">
                        Seja atendido o quanto antes por um de nossos
                        profissionais de saúde.
                      </div>
                    </div>

                    <div className="pronto-btn-view">
                      <IonButton
                        expand="block"
                        className="pronto-atend-btn btn-block"
                        onClick={() => {
                          setScreen("symptomsScreen");
                        }}
                      >
                        <IonImg
                          src="/assets/images/video_corner.svg"
                          className="video-corner-icon"
                        />
                        Pronto Atendimento
                      </IonButton>
                    </div>
                  </div>
                  
                </div>
                <div className="bottomtextdiv text-center mt-3 d-none d-md-block">
                Seja atendido pelo médico de plantão. <br />
                Tempo estimado de espera{" "}
                    <b> {forecastMinutes(supportStartForecast)} minutos.</b>
                  </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
