import {
  IonCol,
  IonContent,
  IonGrid, IonImg,
  IonLabel,
  IonPage,
  IonRow,
  getPlatforms,
  useIonToast,
  useIonViewDidEnter
} from "@ionic/react";
import axios from 'axios';
import moment from "moment";
import React, { useState } from "react";
import Modal from "../../components/Modal";
import { Button } from "../../components/UI/Button";
import AppFooter from "../../components/appfooter/AppFooter";
import Header from "../../components/header/Header";
import MobileHeader from "../../components/mobileHeader/mobileHeader";
import PDFViwer from "../../components/pdfViewer";
import { default as storeService } from "../../services/store.service";
import "../../assets/styles/base.scss"

const PrivacyPolicyInfo: React.FC<any> = (props) => {
  const navigateToPolicy = () => props.history?.replace("/privacy-policy");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  const [user, setUser] = useState<any>(null);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfURLExt, setpdfURLExt] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isparent, setIsParent] = useState<boolean>(false);
  const [termsData , setTermsData] = useState(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [routing, setRouting] = useState<boolean>(null);
  const [present] = useIonToast();

  useIonViewDidEnter(() => {
    storeService.get("user").then((res) => {
      if (res) {
        setUser(res);
        setIsParent(false);
      } 
      getAllTermsPrivacyList((termsData: any) => {
        const data = termsData?.data?.terms.find(d => d.typeOfTerm === "privacy_policy");
        setTermsData(data)
        const { termFilePDF } = data;
        setpdfURLExt(termFilePDF?.substr(termFilePDF.lastIndexOf(".") + 1));
        setPdfLink(termFilePDF);
      })
    });
    storeService.get("new_user").then((res)=>{
      setRouting(res)
     })
  });
  const getAllTermsPrivacyList = async (callback: any) => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/terms-and-conditions/list`).then((listData: any) => {
      callback(listData);
    }).catch((e) => {
      console.log("List data error===", e);
    })
  }



  const acceptNewPrivacyPolicy = async () => {
    await storeService.set("privacy", true);
    storeService.get("terms").then((res) => {
      props.history?.replace(res ? "/home" : "/terms-of-user");
    });
  };


  const storedata = async (user) => {
    await storeService.set("user_id", user?.user_id);
    await storeService.removeKey("signup");
    if (user?.terms_of_use && user?.privacy_policy) {
      present({
        message: "Login realizado com sucesso!",
        duration: 2000,
      });
    }
  }

  const acceptRevokePrivacyPolicy = () => {
    setLoading(true);
    const data: any = {
      patientId: user?.user_id,
      acceptTermIds: [termsData?._id],
      corporationId: termsData?.corporationId
    };
      axios
      .post(
        `${process.env.REACT_APP_API_PARSE_BASE_URL}/terms-and-conditions/accept-revoke`,
        data
      )
      .then(async (res: any) => {
        if (res?.data?.message === "Success!") {
          setLoading(false)
          let user = await storeService.get("user");
            user = { ...user, privacy_policy: true };
            await storeService.set("user", user);
            await storedata(user);
            props.history.replace("/home")
        }
      })
      .catch((error: any) => {
        console.log("errorInCalling", error);
        
      });
  };


  const selectProfile = async () => {
      props.history.replace("/home")
  }

  const handleClick = async () => {
    if (user) {
      setIsOpen(false);
      acceptRevokePrivacyPolicy();
    } else {
      setIsOpen(false);
      await acceptNewPrivacyPolicy();
      selectProfile()
    }
  };


  return (
    <IonPage>
    <IonContent className="privacy-policy-info" {...props}>
    {getPlatforms().includes("desktop") ? (
          <Header props={props} routeName="home" />
        ) : (
          <MobileHeader props={props} />
        )}
      <IonGrid className="termscondition py-xl-4">
        <IonRow className="container px-2 px-md-4">
          <IonCol size="12" sizeLg="2">
            <IonImg
              className="backarrowdiv"
              src={"/assets/images/fillBack.svg"}
              onClick={() =>  routing ? navigateToPolicy() : setIsConfirmOpen(true)}
            />
          </IonCol>

          <IonCol size="12" sizeLg="6">
            <IonLabel className="mainText d-flex align-items-center">
              <IonImg className="iconleft me-2" src={"/assets/images/fileicon.svg"} /> Política
              de Privacidade
            </IonLabel>
            {/* <PrivacyPolicyText /> */}
            <PDFViwer isLoading={loading} pdfURL={pdfLink} pdfURLExt={pdfURLExt} />
          </IonCol>

          <IonCol
            size="12"
            sizeLg="4"
            className="d-flex align-items-center last-secbutton"
          >
            <div className="bottom-button">
              <Button
                className="orange-btn w-100 mb-3"
                color="primary"
                // shape="round"
                fill="solid"
                text="Concordar e continuar"
                handleClick={() => setIsOpen(true)}
                imgSlot="end"
                ImgSrc={"/assets/images/checkicon.svg"}
              />

              <Button
                className="greenoutline-btn w-100"
                // shape="round"
                color="danger"
                text="Cancelar"
                fill="clear"
                handleClick={() =>
                  routing ? navigateToPolicy() : setIsConfirmOpen(true)
                }
              />
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>

      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text="Proteção de Dados"
        subText="A Ampla Saude preza pela segurança, privacidade e confidencialidade, além de garantir que você faça a gestão dos seus dados a qualquer momento, de acordo com a Lei Geral de Proteção de Dados (LGPD)."
        btnSim="Continuar"
        handleClick={handleClick}
      />
      <Modal
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          text="Tem certeza de que não deseja aceitar os termos e a política?"
          btnSim="Sim"
          handleClick={() => {
            storeService.logout(props?.history)
            setIsConfirmOpen(false);
          }}
          btnCol={true}
          btnCancelText="Cancelar"
          btnCancelFill="clear"
          btnCancelColor="danger"
          btnCancelClick={() => setIsConfirmOpen(false)}
        />
     </IonContent>
        {getPlatforms().includes("desktop") && (
          <AppFooter />
        )}
    </IonPage>
  );
};

export default PrivacyPolicyInfo;
