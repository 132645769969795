import React from "react";
import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import "./input.scss";
import MaskedInput from "react-text-mask";

const Input = (props: any) => {
  const {
    mask,
    className = "",
    label = "",
    name = "",
    value = "",
    type = "text",
    handleChange,
    handleBlur,
    errors,
    placeholder,
    icon = "",
    errorText = "",
    iconClick,
    disabled = false,
    max = "",
    pattern = "",
    onfocus,
    onblur,
    onkeyup,
    onkeydown,
    errorclass = "",
    readonly = false
  } = props;

  return (
    
    <IonLabel className="custom-input">
      <IonItem lines="none" className="highlightborder">
        {mask && (
          <MaskedInput
            className={className}
            type={type}
            name={name}
            mask={mask}
            placeholder={placeholder}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
          />
        )}
        {!mask && (
          <IonInput
            className={className}
            value={value}
            type={type}
            name={name}
            placeholder={placeholder}
            onIonChange={handleChange}
            onIonBlur={handleBlur}
            disabled={disabled}
            max={max ? max : undefined}
            pattern={pattern ? pattern : undefined}
            onFocus={onfocus}
            onBlur={onblur}
            onKeyUp={onkeyup}
            onKeyDown={onkeydown}
            readonly={readonly}
          />
        )}

        {icon && (
          <IonIcon
            color="dark"
            class="passwordIcon"
            src={icon}
            onClick={() => iconClick && iconClick()}
          />
        )}
      </IonItem>
      <span className="">{label}</span>
      {errors && (
        <IonLabel className={`validation-error-msg ${errorclass ? errorclass : ""}`}>{errorText}</IonLabel>
      )}
    </IonLabel>
  );
};

export default Input;
