import { Patient } from '@irontrainers/live-assist/lib/rtdb'
import moment from 'moment'
import * as React from 'react'
import { secureWebStorage } from '../../../clients/secure-web-storage'
import storeService from '../../../services/store.service'
import { isIbrasa, isNori, isPac19, isPlansaude, isSesiDf, isSesiSp, isSirioLibanes, whitelabelCustomer } from '../../../utils'
import { getParamsPatientAttributes, getParamsPatientAttributesByBase64, legacyAuth } from './api'

export type UsePatientAuthHooks =
  [string, Patient, false, (cpf: string, birthDate: string) => Promise<any>, () => void]
  | [undefined, undefined, boolean, (cpf: string, birthDate: string) => Promise<any>, () => void]

let persistedCpfAuth = fetchPersistedSession()

export function usePatientAuth(): UsePatientAuthHooks {
  const [authState, setAuthState] = React.useState<{ patient: Patient, patientId: string } | null>()
  const [queryParams,setQueryParams] = React.useState("")
  // const queryParams = React.useMemo(() => storeService.get("supportUrl").then((res)=> {return res}),[])
    
  const initializing = authState === undefined && ((!isPlansaude && !isNori && !isSesiSp && !isSesiDf && 
    !isSirioLibanes && !isIbrasa && !isPac19) || !!persistedCpfAuth)
  const [authBaseString, setAuthBaseString] = React.useState<string>()
  React.useEffect(() => {
    // const supportUrl = storeService.get("support")
    let queryAuthBaseString:any = queryParams
    if (!queryAuthBaseString) return
    setAuthBaseString(queryAuthBaseString)
  }, [setAuthBaseString, queryParams])

  React.useEffect(() => {
    if (!authBaseString) return
    if (authState) return
    let authPatientAttributes = {}
    if(whitelabelCustomer !== 'cassi' && whitelabelCustomer !== 'bemcassi'){
      authPatientAttributes = getParamsPatientAttributesByBase64(authBaseString)
    } else {
      authPatientAttributes = getParamsPatientAttributes(authBaseString)
    }
    const stopRef = { current: false }
    ;(async () => {
      try {
        const [patientId, patient] = await legacyAuth(authPatientAttributes)
        if (stopRef.current) return
        setAuthState({ patientId, patient })
      } catch (error) {
        console.warn('Cannot complete auth with returned error', error)
      }
    })()
    return () => {
      stopRef.current = true
    }
  }, [authBaseString, queryParams, setAuthState])

  const signInWithCpf = React.useCallback(async (cpf: any, birthDate: any) => {
    // if (authState) return
    
    try {
      // const [patientId, authPatient] :any = await cpfAuth(cpf, birthDate, whitelabelCustomer)
      let patientId;
      var authPatient;
      let urlParamsBase64 = ''
      
      storeService 
        .get("user")
        .then((res) => {
          if(res){
            patientId = res.id
            authPatient = {...authPatient,name:res?.name,email:res?.email,app_name:'ampla-saude',corporationId:'ampla-saude', avatar:res?.avatar, corporationName: res?.corporationName, dateOfBirth: res?.birthdate ?  moment(res?.birthdate).format("DD-MM-YYYY") : ""}
            setAuthState({ patientId, patient: authPatient })
            setQueryParams(urlParamsBase64)
          }
      })
      // window.location.href = `/support?name=${urlParamsBase64}`
      // setAuthState({ patientId, patient: authPatient })
      persistSession(cpf, birthDate, whitelabelCustomer)
    } catch (error) {
      console.error('Error signing in with cpf', error)
      throw new Error(error.message || 'Ops, não foi possível fazer login com estes dados!')
    }
  }, [authState, setAuthState])

  const signOut = React.useCallback(() => {
    clearPersistedSession()
    // window.location.href = '/support'
  }, [setAuthState])

  React.useEffect(() => {
    if (!persistedCpfAuth) return
    if (persistedCpfAuth.corporation !== whitelabelCustomer) return
    ;(async () => {
      try {
        const { cpf, birthDate } = persistedCpfAuth
        // const [patientId, patient] = await cpfAuth(cpf, birthDate, whitelabelCustomer)
        // setAuthState({ patientId, patient })
        let patientId;
       let patient;
        storeService
          .get("user")
          .then((res) => {
            if(res) {
              patientId = res.id
              patient ={...patient,name:res?.name,email:res?.email,app_name:'ampla-saude',corporationId:'ampla-saude',avatar:res?.avatar}
              setAuthState({ patientId, patient })            
            }
          })
      } catch (error) {
        persistedCpfAuth = undefined
      }
    })()
  }, [])
  if (!authState) return [undefined, undefined, initializing, signInWithCpf, signOut]
  const { patientId, patient } = authState
  return [patientId, patient, false, signInWithCpf, signOut]
}

export interface CpfAuthPersisted { cpf: string, birthDate: string, corporation: string, expiresIn: number }

function fetchPersistedSession() {
  const cpfAuthPersisted = secureWebStorage.getItem<CpfAuthPersisted>('cpfAuth')
  if (!cpfAuthPersisted) return
  const expirationLimit = Date.now() - (24 * 60 * 60 * 1000)
  if (cpfAuthPersisted && cpfAuthPersisted.expiresIn < expirationLimit) return
  return { cpf: cpfAuthPersisted.cpf, birthDate: cpfAuthPersisted.birthDate, corporation: cpfAuthPersisted.corporation }
}

function clearPersistedSession() {
  secureWebStorage.removeItem('cpfAuth')
}

function persistSession(cpf: string, birthDate: string, corporation: string) {
  const expiresIn = Date.now()
  secureWebStorage.setItem<CpfAuthPersisted>('cpfAuth', { cpf, birthDate, corporation, expiresIn })
}
