import { IonButton, IonIcon, IonImg, getPlatforms } from "@ionic/react";
import React, { useEffect, useState } from "react";
import SelectSearch from "react-select-search";
import StoreService from "./../../helper/storage";
import fuzzySearch from "./fuzzySearch";
export const SpecialityButton: React.FC<any> = ({
  doctors,
  selectedDepartment,
  setSelectedDepartment,
  props,
}) => {
  const [isOpenlist, setIsOpenList] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [gender, setGenderType] = useState("");
  let selectOptions: any = [];
  var flag = 0;
  useEffect(() => {
    StoreService.get("user").then((res) => {
      if (res && res.gender) {
        setGenderType(res.gender.toLowerCase());
      }
    });
  }, []);

  if (!doctors || doctors?.length === 0) {
    return <></>;
  } else if (doctors.length >= 1) {
    doctors.map((doctor: any) => {
      if (gender == "male" && doctor == "Ginecologia e Obstetrícia") {
        return null;
      } else if (gender === "" && doctor == "Ginecologia e Obstetrícia") {
        return null;
      } else {
        selectOptions.push({ name: doctor, value: doctor });
      }
    });
  }

  return (
    <>
      <div
        className={
          getPlatforms().includes("desktop")
            ? "list-div-multispeciality"
            : "listdiv-multi multi-btns multi-btn-mobile"
        }
      >
        <div className="small-heading">
          Agende uma consulta e escolha a especialidade Médica
        </div>
        <div className="position-relative">
          <IonButton
            className={isOpenlist ? "dropDownbtn openDrop" : "dropDownbtn"}
            onClick={() => setIsOpenList(!isOpenlist)}
          >
            {" "}
            <IonIcon
              className="calendaricon"
              src={"assets/images/calendar-icon.svg"}
            ></IonIcon>
            Agendar consulta
            <IonIcon
              className="down-arrow"
              src={"assets/images/downArrow.svg"}
            ></IonIcon>
          </IonButton>
          {isOpenlist && (
            <div className="position-relative mainboxdropdown">
              <SelectSearch
                id="searchSelect"
                printOptions="always"
                className="custom-search-select"
                options={selectOptions}
                placeholder="Escolha um Especialista"
                search
                filterOptions={fuzzySearch}
                autoFocus={true}
                onChange={(e) => {
                  props.history.push({
                    pathname: `/scheduling/calendar-screen?selectedSpeciality=${e}`,
                  });
                  setIsOpenList(false);
                }}
                disabled={!selectOptions}
              />
              <IonImg src="assets/images/search.svg" className="search-icon" />
            </div>
          )}
        </div>
        {/* <div className="sub-text text-center">
          Agende uma consulta com o especialista médico que você desejar
        </div> */}
      </div>
    </>
  );
};
