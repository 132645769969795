import { IonAvatar, IonButton, IonCol, IonImg, IonItem, IonLabel, IonModal, IonRow, IonSegment, IonSegmentButton, } from "@ionic/react";
import React, { useState, useEffect } from "react";
import "./Header.scss";
import StoreService from '../../services/store.service'
import storeService from "../../services/store.service";
const Header: React.FC<any> = ({ props, routeName, profileImg = null }) => {
  const [active, setActive] = useState<any>(null);
  const [profileURL, setProfileURL] = useState<any>("assets/images/profile-icon.svg");
  const [isLgoutConfirmation, setIsLogoutConfirmation] = useState<any>(false)
  const [user, setUser] = useState<any>(null);
  const routeDictionary: any = {
    "medicine": "medicine",
    "home": "home",
    "history": "history",
    "edit-profile": "edit-profile",
    "exams": "exams",
  };
  const logout = () => {
    StoreService.set("user", null);
    StoreService.set("user_id", null);
    setIsLogoutConfirmation(false);
    StoreService.set("user_cpf", null);
    StoreService.set("policyData", null);
    StoreService.set("privacy", null);;
    StoreService.set("terms", null);;
    StoreService.set("new_user", "");
    StoreService.set("userToken", "");
    StoreService.set("signup", "");
    props.history.replace("/login");
    window.history.pushState(null, null, props.history.location.href);
    window.onpopstate = function () {
      props.history.go(1);
    };
  };
  useEffect(() => {
    storeService.get('user').then((res: any) => {
      setProfileURL(res.avatar.url);
      setUser(res)
    }).catch((err: any) => {
      console.warn("err", err);
      setProfileURL("assets/images/profile-icon.svg");
    })
  }, [profileImg, props.location])
  return (
    <div className="header">
      <div className="container">
        <div className="row">

          <div className="col-md-4 d-flex align-items-center col-menu-4">    <IonImg
            className="header-logo-view"
            src="/assets/images/logo.svg"
            onClick={() => {
              setActive("home");
              window.location.href = '/home';
            }}
          />
            <IonLabel className="toptext">Seu médico sem sair de casa</IonLabel></div>
          <div className="col-md-8 middlemenu">
            {user?.privacy_policy && user?.terms_of_use ? (
              <IonSegment >
                <IonSegmentButton
                  value="home"
                  layout="icon-start"
                  className={routeDictionary[routeName] == 'home' && 'active'}
                  onClick={() => {
                    setActive("home");
                    props.history.push("/home");
                  }}>
                  <IonLabel class="menu-home-label"> Home </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton
                  value="history"
                  layout="icon-start"
                  // className="active"
                  className={routeDictionary[routeName] == 'history' && 'active'}
                  onClick={() => {
                    setActive("history");
                    props.history.push("/history");
                  }}
                > <IonImg src="assets/images/header-history.svg" className="signout" /><span>Minhas Consultas</span></IonSegmentButton>
                <IonSegmentButton
                  value="medicine"
                  layout="icon-start"
                  className={routeDictionary[routeName] == 'medicine' && 'active'}
                  onClick={() => {
                    setActive("medicine");
                    props.history.push("/medicine");
                  }}
                > <IonImg src="assets/images/header-medicine.svg" className="signout" /><span>Meus Medicamentos</span></IonSegmentButton>
                <IonSegmentButton
                  value="exams"
                  layout="icon-start"
                  className={routeDictionary[routeName] == 'exams' && 'active'}
                  onClick={() => {
                    setActive("exams");
                    props.history.push("/exams");
                  }}
                > <IonImg src="assets/images/header-exam.svg" className="signout" /><span>Meus Exames</span></IonSegmentButton>
                <IonSegmentButton
                  value="profile"
                  layout="icon-start"
                  class="menu-profile"
                  className={routeDictionary[routeName] == 'profile' && 'active'}
                  onClick={() => {
                    setActive("edit-profile");
                    props.history.push("/profile");
                  }}
                > <IonAvatar className="header-profile"><img src={profileURL || "assets/images/profile-avtar-default.png"} /></IonAvatar> </IonSegmentButton>
                <IonSegmentButton
                  className=""
                  onClick={() => props.history.push("/settings")}
                >
                  <IonImg src={"assets/images/settings.svg"} />
                </IonSegmentButton>
                <IonSegmentButton
                  value="logout"
                  layout="icon-start"
                  className={routeDictionary[routeName] == 'logout' && 'active'}
                  onClick={() => {
                    setActive("logout");
                    setIsLogoutConfirmation(true)
                  }}
                >
                  {/* <IonImg src="assets/images/logout.svg" className="signout" /> */}
                  <IonLabel class="menu-saie-label">Sair</IonLabel>
                  {/* <span>Sair</span> */}
                </IonSegmentButton>

              </IonSegment>)
              :
              <IonSegmentButton
                value="logout"
                layout="icon-start"
                className={[routeDictionary[routeName] == 'logout' && 'active', "logout-style"].join(" ")}
                onClick={() => {
                  setActive("logout");
                  setIsLogoutConfirmation(true)
                }}
              >
                <IonLabel class="menu-saie-label-logout">Sair</IonLabel>
              </IonSegmentButton>
            }
          </div>



        </div>

      </div>


      <IonModal
        isOpen={isLgoutConfirmation}
        cssClass="logout-modal"
        onDidDismiss={() => setIsLogoutConfirmation(false)}
      >
        <span className="logout-title">
          Tem certeza de que deseja sair?
        </span>

        <div>
          <IonButton
            shape="round"
            color="primary"
            expand="block"
            className="custom-logout-btn"
            onClick={logout}
          >
            Sim
          </IonButton>
          <IonLabel
            className="custom-logout-cancel-btn"
            onClick={() => setIsLogoutConfirmation(false)}
          >
            Cancelar
          </IonLabel>
        </div>
      </IonModal>


    </div>
  );
};
export default Header;
