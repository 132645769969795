import {
  IonButton,
  IonCol,
  IonContent,
  IonImg,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  getPlatforms,
  useIonToast,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { InputAdornment, TextField } from "@material-ui/core";
import axios from "axios";
import { Formik } from "formik";
import orderBy from "lodash/orderBy";
import * as Moment from "moment";
import { extendMoment } from "moment-range";
import { useEffect, useRef, useState } from "react";
import "react-calendar/dist/Calendar.css";
import MaskedInput from "react-text-mask";
import * as Yup from "yup";
import { TwilioVideoCallScreen } from "../../../components/patient-support/TwilioVideoCallScreen";
import platform from "../../../helper/platform";
import StoreService from "../../../services/store.service";
import "./CalendarScreen.scss";
// import Layout from "../../components/Layout/Layout";
import { setTimeout } from "timers";
import AppFooter from "../../../components/appfooter/AppFooter";
import Header from "../../../components/header/Header";
import MobileFooter from "../../../components/mobileFooter/mobilefooter";
import MobileHeader from "../../../components/mobileHeader/mobileHeader";
import { corporation } from "../../../helper/corporation";
import DesktopScreen from "./desktop";
import MobileScreen from "./mobile";

const Appointment = (props) => {
  let query = new URLSearchParams(props.history.location.search);
  const selectedDepartment = query.get("selectedSpeciality");
  const moment = extendMoment(Moment);
  const [isCalenderShow, setIsCalenderShow] = useState(false);
  const [isContactShow, setIsContactShow] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [isBookingLoader, setIsBookingLoader] = useState<any>(false);
  const [timeSlot, setTimeSlot] = useState([]);
  const [ContactDetails, setContactDetails] = useState(null);
  const [Contact, setContact] = useState(null);
  const [isProfessionalListShow, setIsProfessionalListShow] = useState(true);
  const [staffData, setStaffData] = useState(null);
  const [isSummaryShow, setIsSummaryShow] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [onCancel, setOnCancel] = useState(false);
  const [steps, setSteps] = useState(0);
  const [present, dismiss] = useIonToast();
  const hiddenFormButton = useRef<HTMLIonButtonElement>(null);
  const [historyData, setHistoryData] = useState<any>(null);
  const [futureData, setFutureData] = useState<any>(null);
  const [selectedStaffId, setSelectedStaffId] = useState<any>(undefined);
  const [roomName, setRoomName] = useState<any>();
  const [videoAccessToken, setVideoAccessToken] = useState<any>();
  const [selectedTimeDetails, setSelectedTimeDetails] = useState<any>(null);
  const [staffScheduleId, setStaffScheduledId] = useState<any>(null);
  const [month, setMonth] = useState<any>(null);
  const [year, setYear] = useState<any>(null);
  const [dateMarks, setdateMarks] = useState<any>([]);
  const [isStaffNotAvailable, setIsStaffNotAvailable] = useState<any>(false);
  const [isCustomCelularToast, setIsCustomCelularToast] = useState<any>(false);
  const [noStaffModal, setNoStaffModal] = useState<any>(false);
  const [isCustomEmailToast, setIsCustomEmailToast] = useState<any>(false);
  const [customCelularToastMsg, setCustomCelularToastMsg] = useState<any>(null);
  const [customEmailToastMsg, setCustomEmailToastMsg] = useState<any>(null);
  const [counter, setCounter] = useState<any>(0);
  const [userData, setUserData] = useState(null);

  useIonViewDidEnter(() => {
    StoreService.get("user").then((res) => {
      if (res) {
        getDeatilsByCPF();
        setUserData(res);
        return;
      } else {
        props.history.push("/login");
      }
    });
  });

  const clearState = () => {
    setCounter(0);
    setTimeSlot([]);
    setIsCalenderShow(false);
    setIsContactShow(false);
    setSelectedDate(null);
    setSelectedTime(null);
    setSelectedStaff(null);
    setIsProfessionalListShow(true);
    setStaffData(null);
    setIsSummaryShow(false);
    setSteps(0);
  };

  useEffect(() => {
    if (selectedTimeDetails?.staff == "") {
      setCounter(counter + 1);
    } else {
      setStaffScheduledId(selectedTimeDetails?.staff);
    }
  }, [selectedTimeDetails]);

  useIonViewDidEnter(() => {
    StoreService.get("user").then((res) => {
      if (res) {
        getAutoSpeciality();
        getHistoryAppointments();
      } else {
        props.history.replace({
          search: "",
        });
        props.history.push("/login");
      }
    });
  });

  useIonViewDidLeave(() => {
    setSteps(0);
    setIsCalenderShow(false);
    props.history.replace({
      search: "",
    });
    setStaffData(null);
    clearState();
  });

  useEffect(() => {
    setSteps(0);
    setIsCalenderShow(false);
    setStaffData(null);
  }, []);

  useEffect(() => {
    if (selectedDepartment) {
      setIsLoading(true);
      const URL = `${process.env.REACT_APP_MIX_PARSE_API}scheduling/new_agenda/get_staff_by_speciality`;
      axios
        .post(URL, { speciality: selectedDepartment, corporation: corporation })
        .then((resp: any) => {
          setIsLoading(false);
          if (resp.status == 200) {
            if (resp.data.result.length == 0) {
              setIsStaffNotAvailable(true);
              if (getPlatforms().includes("desktop")) {
                setSteps(2);
              } else {
                setIsProfessionalListShow(false);
                setIsCalenderShow(true);
                setSteps(1);
              }
            } else {
              setIsStaffNotAvailable(false);
              setStaffData(resp.data.result);
              setSteps(0);
            }
          }
        })
        .catch((err: any) => {
          setIsLoading(false);
          setStaffData(null);
          setSteps(0);
          console.log("err", err);
        });
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (onSuccess) {
      setTimeout(() => {
        setNoStaffModal(false);
        getHistoryAppointments();
        getDeatilsByCPF();
        clearState();
      }, 5000);
    }
  }, [onSuccess]);

  useEffect(() => {
    if (staffData?.length === 1) {
      setSelectedStaff(staffData[0]);
    }
  }, [staffData]);

  useEffect(() => {
    let currentDate = new Date();
    setMonth(currentDate.getMonth() + 1);
    setYear(currentDate.getFullYear());
    // getHistoryAppointments();
    // getDeatilsByCPF();
  }, []);

  const getDeatilsByCPF = () => {
    StoreService.get("user").then((user) => {
      const URL = `${process.env.REACT_APP_MIX_PARSE_API}doctor/patients/get_detail_by_cpf`;
      axios
        .post(URL, { cpf: user?.cpf })
        .then((resp: any) => {
          if (resp.status == 200) {
            setContactDetails(resp.data.data.novas);
          }
        })
        .catch((err: any) => {
          console.log("exam err", err);
        });
    });
  };

  const getHistoryAppointments = async () => {
    const timezone: any = moment.tz.guess();
    setIsBookingLoader(true);
    const currentDate = new Date().toISOString();
    let currentDateAPI: any = new Date();
    currentDateAPI.setHours(23, 59, 59, 999);
    currentDateAPI = currentDateAPI.toUTCString();
    const dataHistory: any = [];
    const futureData: any = [];
    const userId = await StoreService.get("user_id");

    axios
      .get(
        process.env.REACT_APP_MIX_PARSE_API +
        `scheduling/patient/history_appointments/${userId}?date=${currentDateAPI}&timezone=${timezone}&page=1&limit=1000
    `
      )
      .then((res: any) => {
        setIsBookingLoader(false);
        res.data.data.map((appointment: any, index: any) => {
          var appointmentTime = new Date(appointment.UTCdatetime);
          appointmentTime.setMinutes(appointmentTime.getMinutes() + 30);
          if (
            appointmentTime.toISOString() > currentDate &&
            appointment.status !== "Completed"
          ) {
            futureData.push(appointment);
          } else {
            dataHistory.push(appointment);
          }
        });
        const dataHistorys = orderBy(dataHistory, ["UTCdatetime"], ["desc"]);
        const dataFuture = orderBy(futureData, ["UTCdatetime"], ["asc"]);
        setFutureData(dataFuture);
        setHistoryData(dataHistorys);
      })
      .catch((error: any) => {
        setIsBookingLoader(false);
        console.log("error", error);
      });
  };

  const agendaSaved = async (values = null) => {
    const user = await StoreService.get("user");
    setIsBookingLoader(true);
    const currentDate = new Date();
    const startDate = new Date(currentDate);
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 7);

    let beginOfWeek = startDate;
    beginOfWeek.setHours(0, 0, 0, 0);

    let endOfWeek = endDate;
    endOfWeek.setHours(23, 59, 59, 999);
    const _Date = moment(selectedDate, "DD/MM/YYYY");
    let UTCdatetime = moment(
      _Date.format("DD/MM/YYYY") + " " + selectedTime,
      "DD/MM/YYYY HH:mm"
    )
      .utc()
      .format("DD/MM/YYYY HH:mm");

    const data = {
      UTCdatetime: UTCdatetime,
      agenda_end_time: endOfWeek,
      agenda_speciality: selectedDepartment,
      agenda_start_time: beginOfWeek,
      date: moment(selectedDate).format("DD/MM/YYYY"),
      patient_email: Contact?.email || values?.email,
      patient_mobile: Contact?.celular || values?.celular,
      staff: selectedStaff?.staff_id ? selectedStaff?.staff_id : undefined,
      start_time: selectedTime,
    };
    const URL = `${process.env.REACT_APP_MIX_PARSE_API}scheduling/new_agenda/patient/book_adma_appointment/${user?.user_id}`;
    axios
      .post(URL, data)
      .then((resp: any) => {
        if (resp.status == 200) {
          setIsBookingLoader(false);
          setNoStaffModal(true);
          setIsCalenderShow(false);
          setTimeout(() => {
            setNoStaffModal(false);
            clearState();
            props.history.replace({
              search: "",
            });
            props.history.push("home");
          }, 3000);
        }
      })
      .catch((err: any) => {
        setIsContactShow(true);
        present({
          buttons: [{ text: "hide", handler: () => dismiss() }],
          message: err.response?.data?.message,
          duration: 3000,
        });
        setIsBookingLoader(false);
        console.log("exam err", err);
      });
  };

  const onDateChange = async (d) => {
    setSelectedDate(d);
    setIsLoading(true);
    const startDate = new Date(d);
    const endDate = new Date(d);
    let beginOfDay = startDate;
    beginOfDay.setHours(0, 0, 0, 0);

    let endOfDay = endDate;
    endOfDay.setHours(23, 59, 59, 999);
    const user = await StoreService.get("user");
    let c_current_date = new Date();
    c_current_date.setHours(0, 0, 0, 0);

    let n_current_last_date = new Date();
    n_current_last_date.setHours(0, 0, 0, 0);
    n_current_last_date.setMonth(n_current_last_date.getMonth() + 1);
    n_current_last_date = new Date(
      n_current_last_date.getFullYear(),
      n_current_last_date.getMonth() + 1,
      0
    );
    let data: any = {
      timezone: moment.tz.guess(),
      agenda_date: startDate.toUTCString(),
      agenda_start_time: beginOfDay.toUTCString(),
      agenda_end_time: endOfDay.toUTCString(),
      speciality: selectedDepartment,
      staff_id: selectedStaff?.staff_id || undefined,
      c_current_date,
      n_current_last_date,
    };
    // if (isStaffNotAvailable) {
    //   setSelectedStaff(null)
    //   data = {
    //     ...data,
    //     staff_id: undefined,
    //   }
    // } else {
    //   data = {
    //     ...data,
    //     staff_id: selectedStaff?.staff_id,
    //   }
    // }

    const URL = `${process.env.REACT_APP_MIX_PARSE_API}scheduling/new_agenda/patient/health_nurse_agenda/${user?.user_id}`;

    await axios
      .get(URL, { params: data })
      .then(async (resp: any) => {
        if (resp.status == 200) {
          setTimeSlot(resp.data.result);
          if (!resp.data.result.length) {
            setSelectedTime(null);
          }
          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        setTimeSlot([]);
        setSelectedTime(null);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isCustomCelularToast || isCustomEmailToast) {
      setTimeout(() => {
        setIsCustomCelularToast(false);
        setIsCustomEmailToast(false);
      }, 2000);
    }
  }, [isCustomCelularToast, isCustomEmailToast]);

  const handlesubmitButton = async () => {
    if (customEmailToastMsg) {
      await setIsCustomCelularToast(true);
    } else if (customCelularToastMsg) {
      await setIsCustomEmailToast(true);
    }
    if (hiddenFormButton.current) {
      await hiddenFormButton.current.click();
    }
  };

  const removeErrorCustom = (setErrors: any) => {
    // setTimeout(() => {
    //   setErrors({});
    // }, 3000)
  };

  const contactFrom = (hidebutton = false) => {
    return (
      <Formik
        initialValues={{
          celular: ContactDetails?.phones[0]?.number,
          email: ContactDetails?.email,
        }}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Email inválido")
            .required("E-mail é obrigatório"),
          celular: Yup.string().required("celular é necessário"),
        })}
        onSubmit={(values) => {
          agendaSaved(values);
          setContact(values);
          getHistoryAppointments();
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setErrors,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="text-input-header">
              Email -{" "}
              <label
                className={
                  errors.email && touched.email && errors.email
                    ? "errormessage-msg"
                    : "errormessage"
                }
              >
                {" "}
                Preenchimento obrigatório
              </label>
            </div>
            <div className="text-field-wapper text-feild-first">
              <TextField
                className="text-field"
                id="outlined-basic"
                placeholder="e-mail"
                name="email"
                value={values.email}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IonImg
                        className="text-edit-icon"
                        src="/assets/images/profile-input-icon.svg"
                      />
                    </InputAdornment>
                  ),
                }}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </div>
            {errors.email && touched.email && errors.email
              ? (setCustomEmailToastMsg("Preencha o campo de e-mail"),
                removeErrorCustom(setErrors))
              : setCustomEmailToastMsg(null)}
            <div className="text-input-header">
              Telefone -{" "}
              <label
                className={
                  errors.celular && touched.celular && errors.celular
                    ? "errormessage-msg"
                    : "errormessage"
                }
              >
                {" "}
                Preenchimento obrigatório
              </label>
            </div>
            <div className="text-field-wapper text-feild-aliment telphonenumber">
              <MaskedInput
                mask={[
                  "(",
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                value={values.celular}
                type="tel"
                className="text-field"
                id="outlined-basic"
                placeholder="(19)9 95556-5857"
                name="celular"
                onBlur={handleBlur}
                onChange={handleChange}
              />
              <InputAdornment position="end" className="editicondiv">
                <IonImg
                  className="text-edit-icon"
                  src="/assets/images/profile-input-icon.svg"
                />
              </InputAdornment>
            </div>
            {errors.celular && touched.celular && errors.celular
              ? (setCustomCelularToastMsg("Preencha o campo de celular"),
                removeErrorCustom(setErrors))
              : setCustomCelularToastMsg(null)}
            <div className={hidebutton ? "hide-section" : "summary-btns"}>
              <IonButton
                expand="block"
                className="confirm-btn"
                type="submit"
                hidden={true}
                ref={hiddenFormButton}
              >
                <IonImg
                  src="/assets/images/CalenderHour.svg"
                  className="calender-icon"
                />
                Confirmar Agendamento
              </IonButton>

              <input
                type="submit"
                value="Confirmar"
                className="summary-conf-btn-m"
              />
              <input
                type="button"
                className="summary-cancel-btn-m"
                value="Cancelar"
                onClick={() => setOnCancel(true)}
              />
            </div>
          </form>
        )}
      </Formik>
    );
  };

  const onBack = () => {
    setCounter(0);
    setTimeSlot([]);
    setSelectedDate(null);
    setSelectedTime(null);
    if (isProfessionalListShow) {
      props.history.back()
    } else if (isCalenderShow) {
      if (selectedStaff == null) {
      } else {
        setIsProfessionalListShow(true);
        setIsCalenderShow(false);
      }
    } else if (isContactShow) {
      setIsContactShow(false);
      setIsCalenderShow(true);
    } else if (isSummaryShow) {
      setIsSummaryShow(false);
      setIsContactShow(true);
    }
  };

  const getAutoSpeciality = async () => {
    const isHistory = await StoreService.get("isHistory");
    if (isHistory) {
      await StoreService.set("isHistory", false);
      const appointment = await StoreService.get("appointment");
      await StoreService.set("appointment", null);
    }
  };

  useEffect(() => {
    setdateMarks([]);
    getAvailableDate();
  }, [month, year, selectedStaff, isStaffNotAvailable]);

  const onArrowChange = (params) => {
    setIsStaffNotAvailable(false);
    setMonth(params.activeStartDate.getMonth() + 1);
    setYear(params.activeStartDate.getFullYear());
  };

  const onMonthYearChange = (params) => {
    setMonth(params.getMonth() + 1);
    setYear(params.getFullYear());
  };

  const getAvailableDate = () => {
    let startDate = moment([year, month - 1]);
    let endDate = moment(startDate).endOf("month");
    const timezone: any = moment.tz.guess();
    if (selectedStaff) {
      const next_month_year = moment()
        .add(1, "M")
        .startOf("month")
        .format("YYYY");
      const next_month = month + 1;
      let c_current_date = new Date();
      c_current_date.setHours(0, 0, 0, 0);
      let n_current_last_date = new Date();
      n_current_last_date.setHours(0, 0, 0, 0);
      n_current_last_date.setMonth(n_current_last_date.getMonth() + 1);
      n_current_last_date = new Date(
        n_current_last_date.getFullYear(),
        n_current_last_date.getMonth() + 1,
        0
      );
      axios
        .get(
          process.env.REACT_APP_MIX_PARSE_API +
          `scheduling/new_agenda/patient/get/available_dates?speciality=${selectedDepartment}&month=${month}&year=${year}&staff=${selectedStaff?.scheduled_staff_id}&timezone=${timezone}&monthStart=${startDate}&monthEnd=${endDate}&c_current_date=${c_current_date}&n_current_last_date=${n_current_last_date}&next_month_year=${next_month_year}&next_month=${next_month}`
        )
        .then((resp: any) => {
          if (resp.status == 200) {
            if (resp?.data?.next_two_months == false) {
              let dateObj: any = [];
              resp?.data?.data.map((date) => {
                dateObj.push(moment(date?.date).format("DD-MM-YYYY"));
              });
              setdateMarks(dateObj);
            } else if (resp?.data?.next_two_months) {
              setIsStaffNotAvailable(true);
            }
          }
        })
        .catch((err: any) => {
          console.warn("error api", err);
        });
    }
  };
  useEffect(() => {
    if (isStaffNotAvailable && (steps == 2 || isCalenderShow)) {
      setSelectedStaff(null)
    }

  }, [isStaffNotAvailable, steps, isCalenderShow])
  const settings = {
    className: "center",
    centerMode: false,
    infinite: false,
    centerPadding: "10px",
    slidesToShow: 1,
    speed: 500,
    rows: 2,
    slidesPerRow: 4,
    arrows: true,
  };

  const getDaysBetweenDates = (startDate, endDate) => {
    let now = startDate.clone();
    let dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("DD-MM-YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const onNoStaffBack = () => {
    setSteps(0);
    onBack();
  };

  return (
    <>
      <IonPage className="appointment-comp">
        <IonContent>
          {getPlatforms().includes("desktop") ? (
            <Header props={props} routeName="home" />
          ) : (
            <MobileHeader props={props} />
          )}
          <div className="homewrapper align-items-start">
            <div className="container">
              <div className="row">
                <div className="all-div-appointment px-md-5">
                  <IonRow className="appointment-mobile">
                    {platform.isAndroid || platform.isMobile || platform.isMobileweb ? (
                      <MobileScreen
                        selectedDepartment={selectedDepartment}
                        onBack={onBack}
                        isContactShow={isContactShow}
                        isCalenderShow={isCalenderShow}
                        isLoading={isLoading}
                        isProfessionalListShow={isProfessionalListShow}
                        staffData={staffData}
                        selectedStaff={selectedStaff}
                        setSelectedStaff={setSelectedStaff}
                        setSelectedStaffId={setSelectedStaffId}
                        setIsProfessionalListShow={setIsProfessionalListShow}
                        setIsCalenderShow={setIsCalenderShow}
                        clearState={clearState}
                        onDateChange={onDateChange}
                        onMonthYearChange={onMonthYearChange}
                        dateMarks={dateMarks}
                        moment={moment}
                        setSelectedTime={setSelectedTime}
                        selectedDate={selectedDate}
                        setIsContactShow={setIsContactShow}
                        timeSlot={timeSlot}
                        setSelectedTimeDetails={setSelectedTimeDetails}
                        selectedTime={selectedTime}
                        contactFrom={contactFrom}
                        setIsSummaryShow={setIsSummaryShow}
                        onArrowChange={onArrowChange}
                        {...props}
                      />
                    ) : (
                      // desktop screen
                      <DesktopScreen
                        {...props}
                        onSuccess={onSuccess}
                        selectedDate={selectedDate}
                        steps={steps}
                        userData={userData}
                        isLoading={isLoading}
                        onBack={onBack}
                        isStaffNotAvailable={isStaffNotAvailable}
                        onNoStaffBack={onNoStaffBack}
                        selectedDepartment={selectedDepartment}
                        setSteps={setSteps}
                        staffData={staffData}
                        selectedStaff={selectedStaff}
                        setSelectedStaff={setSelectedStaff}
                        setSelectedTime={setSelectedTime}
                        setSelectedDate={setSelectedDate}
                        clearState={clearState}
                        onDateChange={onDateChange}
                        onArrowChange={onArrowChange}
                        onMonthYearChange={onMonthYearChange}
                        dateMarks={dateMarks}
                        moment={moment}
                        timeSlot={timeSlot}
                        isBookingLoader={isBookingLoader}
                        setOnCancel={setOnCancel}
                        setSelectedTimeDetails={setSelectedTimeDetails}
                        setIsContactShow={setIsContactShow}
                        settings={settings}
                        selectedTime={selectedTime}
                        contactFrom={contactFrom}
                        handlesubmitButton={handlesubmitButton}
                      />
                    )}
                  </IonRow>
                  <IonRow>
                    <IonCol></IonCol>
                  </IonRow>
                  <IonModal
                    cssClass="success-popup"
                    isOpen={onSuccess}
                    onDidDismiss={() => setOnSuccess(false)}
                  >
                    <div className="mainmodalbox">
                      <IonImg
                        className="success-model-img"
                        src="assets/images/success_1.svg"
                      ></IonImg>
                      <div className="modaltitel">Consulta online </div>
                      <div className="modaldesc">agendada com sucesso!</div>
                    </div>
                  </IonModal>
                  <IonModal
                    isOpen={onCancel}
                    cssClass="cancel-pop"
                    onDidDismiss={() => setOnCancel(false)}
                  >
                    <IonImg
                      className="success-model-img"
                      src="assets/images/close.svg"
                      onClick={() => {
                        setOnCancel(false);
                      }}
                    ></IonImg>
                    <IonText className="viewModelTitle">
                      Tem certeza que deseja cancelar seu agendamento?
                    </IonText>
                    <input
                      value="sim"
                      type="button"
                      className="cancel-btn"
                      onClick={() => {
                        props.history.back()
                        setSelectedTime(null);
                        setSelectedDate(null);
                        setOnCancel(false);
                      }}
                    />
                    <div
                      className="voltar-btn"
                      onClick={() => {
                        setOnCancel(false);
                      }}
                    >
                      voltar
                    </div>
                  </IonModal>
                  {(!getPlatforms().includes("mobileweb") &&
                    (getPlatforms().includes("android") ||
                      getPlatforms().includes("ios"))) &&
                    videoAccessToken &&
                    userData && (
                      <IonModal
                        isOpen={videoAccessToken}
                        onDidDismiss={() => {
                          setVideoAccessToken(null);
                        }}
                      >
                        <TwilioVideoCallScreen
                          videoToken={videoAccessToken}
                          roomName={roomName}
                          supportId={userData?.uid}
                          patientId={userData?.uid}
                          isAppointmentCall={true}
                        />
                      </IonModal>
                    )}
                  <IonModal
                    isOpen={isCustomCelularToast || isCustomEmailToast}
                    cssClass="custom-toast-modal"
                    onDidDismiss={() => {
                      setIsCustomCelularToast(false);
                      setIsCustomEmailToast(false);
                    }}
                  >
                    <div className="innercontent">
                      <IonImg src="assets/images/close-new.svg" />
                      <p>{customCelularToastMsg || customEmailToastMsg}</p>
                    </div>
                  </IonModal>
                  <IonModal
                    isOpen={noStaffModal}
                    cssClass="nostaff-popup"
                    onDidDismiss={() => setNoStaffModal(false)}
                  >
                    <div className="mainmodalbox">

                      <div className="modaltitel">
                        Consulta agendada com sucesso!{" "}
                      </div>
                      <IonImg
                        className="success-model-img"
                        src="assets/images/success_1.svg"
                      ></IonImg>
                      {!selectedStaff?.staff_id && (
                        <div className="modaldesc">
                          Em breve o nome do especialista estará disponível na
                          plataforma e você receberá um e-mail com as informações da
                          consulta.
                        </div>
                      )}
                    </div>
                  </IonModal>
                </div>
              </div>
            </div>
          </div>
          {getPlatforms().includes("desktop") ? (
            <AppFooter />
          ) : (
            <MobileFooter props={props} />
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Appointment;
