import styled from "styled-components";

export const ModalWrapper = styled.div`
.modal-wrapper {
    background-color: white;
    color: #000;
    border-radius: 12px;
    width: 328px;
    height: auto;
    @media (max-width: 400) {
      width: 300px;
    }

    .ion-page {
      justify-content: center;
      text-align: center;
      padding: 20px;
      position: relative;
      contain: content;
    }    
  }
`;

export const Text = styled.div`
  color: #FE5000;
  font-weight: 700;
  font-size: 16px;
  // letter-spacing: -0.015em;
  margin-bottom: 15px;
`;

export const SubText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;color: #4F4F4F;
  // letter-spacing: -0.015em;
  margin-bottom: 1rem;text-align: center;
`;

export const SubTexts = styled.div`
  color: var(--bluecolor);
  margin-bottom: 2rem;
  font-weight: 700;
  // letter-spacing: -0.015em;
  text-align: center;
`;
export const LinkText = styled.a`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;color: #4F4F4F;
  letter-spacing: -0.015em;
  text-align: center;
`;