// import { SocialSharing } from "@awesome-cordova-plugins/social-sharing";
import { getPlatforms, IonCard, IonContent, IonImg, IonInput, IonLabel, IonList, IonPage, IonProgressBar, IonSegment, IonSegmentButton, useIonToast, useIonViewDidEnter, useIonViewDidLeave } from '@ionic/react';
import axios from 'axios';
import { isEmpty } from 'lodash';
import moment from "moment";
import 'moment-timezone';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { RWebShare } from 'react-web-share';
import AppFooter from '../../components/appfooter/AppFooter';
import Header from '../../components/header/Header';
import MobileFooter from '../../components/mobileFooter/mobilefooter';
import MobileHeader from '../../components/mobileHeader/mobileHeader';
import NoDataComponent from '../../components/noDataComp/NoDataComponent';
import { DownloadFile, format } from "../../helper/Common";
import platform from '../../helper/platform';
import StoreService from '../../services/store.service';
import './Exams.scss';

export const Share = ({entity, label, text, present, sendicon,type}) => {
    const shareOption = async (fileurl: any, present: any, text: string, filename:any) => {
        if (fileurl === "" || isEmpty(fileurl)) {
          present({
            message: "problema na busca de URL entre em contato com o suporte",
            duration: 5000,
          });
        } else {
          //.share(filename, fileurl).then(() => {});
        }
      };
      
    let fileurl;
    var filename
    if(type === "exam-result"){
      fileurl = entity.url;
      filename = entity.name;
    }
    else if(type === "exam-outer"){
      fileurl = entity.file_url;
      filename = entity.file_name;
    }
    else{
      fileurl = entity.file;
      filename = entity?.name || entity.fileName;
    }
  
    return platform.isDesktop  || platform.isMobileweb ? (
      <>
        {fileurl === "" || isEmpty(fileurl) ?
          <a
          className="action-label d-flex align-items-center justify-content-end"
          onClick={(e) => shareOption(fileurl, present, text, filename)}
          >
            {label}
            {sendicon && <IonImg src={sendicon} alt="" />}
          </a>
        :
          <RWebShare
            data={{
              title: filename,
              url: fileurl,
            }}
            onClick={() => {}}
          >
            <a className="action-label d-flex align-items-center justify-content-end">{label}  {sendicon && <IonImg src={sendicon} alt="" />}</a>
  
          </RWebShare>
        }
      </>
    ) : (
      <a
        className="action-label"
        onClick={(e) => shareOption(fileurl, present, text, filename)}
      >
        {label}
        {sendicon && <IonImg src={sendicon} alt="" />}
      </a>
    );
}
const Exams: React.FC<any> = (props) => {
    // const { Share } = Plugins;
    const hiddenProfileButton = useRef<HTMLInputElement>(null);
    const [present, dismiss] = useIonToast();
    const [exams, setExams] = useState<any>(null);
    const [recentExams, setRecentExams] = useState<any>(null);
    const [priority, setPriority] = useState<any>('Pedidos recentes')
    const [isLoading, setIsLoading] = useState<any>(false);
    const [userData, setUserData] = useState(null);
    const [title, setTitle] = useState(null);
    const [examref, setExamRef] = useState(null);
    useIonViewDidEnter(() => {

        StoreService.get('user').then(res => {
            if (res) {
                if (!res?.terms_of_use || !res?.privacy_policy) {
                    props.history.push("/privacy-policy");
                  }
                setUserData(res);
                examsGet();
                return
            } else {
                props.history.push("/login");
            }
        })
    });
    useIonViewDidLeave(() => {
        setTitle(null);
    })
    const deleteExam = async (examId, attachmentId, type) => {
        let userId = userData?.id;
        setIsLoading(true);
        const data = [
          userId,
          examId,
          attachmentId,
          type,
        ];
        const URL = format(`${process.env.REACT_APP_MIX_PARSE_API}clinical_exam/health_plan/{0}/delete_exams/{1}/{2}/type={3}`,data);
        try{
            const result = await axios.delete(URL);
            if (result) {
                setIsLoading(false);
                examsGet();
            }
        } catch(err){
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        examsGet();
    }, [props.location.key])
    const examsGet = async () => {
        setIsLoading(true)
        const user = await StoreService.get("user");
        // const user: any = { user_id: 'HGxaNdsslU' }
        const URL = format(`${process.env.REACT_APP_MIX_PARSE_API}clinical_exam/health_plan/{0}/exams`, [user?.id]);
        let examRecent: any = [];
        let examOld: any = [];
        await axios.get(`${URL}?slug=ampla-saude`).then((resp: any) => {
            setIsLoading(false)
            if (resp.status == 200) {
                setIsLoading(false);
                resp?.data?.data?.map((element: any) => {
                    if (element.status === 'pending') {
                        examRecent.push(element)
                    } else {
                        examOld.push(element)
                    }
                })
                let outerExams = resp?.data?.exam_results?.map((element : any) => ({...element,type: "outer" }));
                setRecentExams(examRecent.concat(outerExams))
                setExams(examOld)
            }
        }).catch((err: any) => {
            setIsLoading(false)
            console.log("exam err", err);
        })
    }
    const dateFormat = (date: any) => moment(date).format("DD/MM/YYYY");


    const Download = (entity: any, event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        present({ message: "Download iniciado", duration: 1000 });
        DownloadFile(
            entity.file,
            "ok",
            () => present({ message: "Arquivo baixado", duration: 2000 }),
            () => present({ message: "Erro ao baixar o arquivo", duration: 2000 })
        );
    };

    const handleProfileButton = (exam: any) => {
        setExamRef(exam)
        if (hiddenProfileButton.current) {
            hiddenProfileButton.current.value = '';
            hiddenProfileButton.current.click();
        }
    };
    const UploadFile = async (event: any) => {
        const user = await StoreService.get("user");
        if (event.target.files) {
            setIsLoading(true);
            const fileUploaded = event.target.files[0];
            if(fileUploaded?.size > 26214400) {
                setIsLoading(false);
                present("o limite de tamanho do arquivo é de 25 MB", 5000);
            } else {
                if(fileUploaded?.type === "application/pdf" 
                || fileUploaded?.type === "image/jpeg"
                || fileUploaded?.type === "image/png"
                || fileUploaded?.type === "image/jpg"){
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                        const data = {
                            attachment: reader.result,
                            exam_id: examref?.id,
                            title,
                            corporation_slug: "ampla-saude",
                        };
                        const URL = format(`${process.env.REACT_APP_MIX_PARSE_API}clinical_exam/health_plan/{0}/upload_exam_result`, [user?.id]);
                        axios.post(URL, data).then((res: any) => {
                            if (res.status == 200) {
                                setIsLoading(false);
                                setTitle(null);
                                setExamRef(null);
                                examsGet();
                                present({ message: "Sucesso!", duration: 3000 })
                            }
        
                        }).catch((error: any) => {
                            setIsLoading(false);
                            console.log("error", error)
                        })
                    };
                    reader.readAsDataURL(fileUploaded);
                } else {
                    present("Formato de arquivo inválido", 5000);
                    setIsLoading(false);
                }
            }
        }
    };
    const goBack = () => {
        props.history.back()
    }
    return (
        <IonPage>
            <IonContent>
                {/* <Header props={props} routeName="exams" /> */}
                {getPlatforms().includes("desktop") ?
                    <Header props={props} routeName="exams" />
                    :
                    <MobileHeader props={props} />
                }

                <div className="exam-mainwrapper">
                    <div className="rightcontent">


                        <div className="container">
                            {getPlatforms().includes("desktop") ?
                                <div className="exam-header-content title_message">
                                    <IonLabel className="exam-topsubtitle" >Olá, {userData?.name}</IonLabel>
                                    {/* <IonImg slot="start" className="exam-title-img-view hide-on-desktop" src="assets/images/side-mobile-exames.svg" /> */}
                                    <h2 className="exam-main-title">Meus Exames</h2>
                                </div>
                                :
                                <div className="exam-header-content">
                                    <IonImg className="exam-main-title-arrow-left" src='assets/images/arrow-left.svg' onClick={() => goBack()} />

                                    <IonLabel className="exam-topsubtitle-mobile" >Meus exames</IonLabel>
                                </div>
                            }

                            <div className="row">

                                {getPlatforms().includes("desktop") ?
                                    <div className="col-md-4 d-flex align-items-center">
                                        <IonImg className="" src="assets/images/exam-frame.svg" />
                                    </div>
                                    :
                                    ""
                                }

                                <div className="exam-sub-content">


                                    {isLoading &&
                                        (
                                            <IonProgressBar type="indeterminate" className="ion-margin-bottom" />
                                        )}
                                    <IonSegment className="exam-header-tab-view" value={priority} onIonChange={e => setPriority(e.detail.value)}>
                                        <IonSegmentButton value="Pedidos recentes" defaultChecked={true}>
                                            <IonLabel>Pedidos Recentes</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="Pedidos Antigos">
                                            <IonLabel>Pedidos Antigos</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>



                                    {priority == 'Pedidos Antigos' ? (
                                        exams?.length > 0 ? (
                                            <IonList className="exam-pedidos-list-content">
                                                {exams?.map((exam: any, key: any) => {
                                                    return (
                                                        <IonCard className="exam-pedidos-card-content" key={key}>
                                                            <div className="exam-pedidos-content-box">
                                                                <div>
                                                                    <IonImg className="exam-pedidos-img" src='assets/images/side-mobile-select-exames-gray.svg' />
                                                                </div>
                                                                <div className="exam-pedidos-box">
                                                                    <div className="exam-pedidos-detail-content">
                                                                        <div>
                                                                            <IonLabel className="exam-pedidos-date">  {dateFormat(exam.date)} </IonLabel>
                                                                        </div>
                                                                        <div className="exam-visuasls" onClick={() => window.open(exam?.file)}>
                                                                            <IonImg src='assets/images/antigas-visuals.svg' />
                                                                            <IonLabel className="exam-pedidos-detail-label">Visualizar</IonLabel>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <IonLabel className="exam-pedidos-detail-txt">{exam?.title} </IonLabel>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </IonCard>
                                                    )
                                                })}
                                            </IonList>
                                        ) : (
                                            <NoDataComponent text="Não há Exames disponíveis no momento" />
                                        )
                                    ) : (
                                        recentExams?.length > 0 ? (
                                            <>
                                                <IonList className={recentExams?.length > 0 ? "exam-list-content" : "exam-nodata-list-content"}>
                                                    {recentExams?.map((exam: any, key: any) => {
                                                        return (
                                                            <IonCard className="exam-card-content" key={key}>
                                                                <div className="exam-content-box">
                                                                    <div>
                                                                        <IonImg className="exam-content-header-img" src='assets/images/side-mobile-select-exames.svg' />
                                                                    </div>
                                                                    <div className="exam-box">
                                                                        <div className="exam-detail-content">
                                                                            <div>
                                                                                <IonLabel className="exam-pedi-date-label">{dateFormat(exam.date)}</IonLabel>
                                                                            </div>
                                                                            {exam.type !== "outer" &&
                                                                                <div className="exam-visuasls" onClick={() => window.open(exam?.file)}>
                                                                                    <IonImg src='assets/images/medicine-visuals.svg' />
                                                                                    <IonLabel className="exam-detail-label">Visualizar</IonLabel>
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        <div>
                                                                            <IonLabel className="exam-detail-sub-txt-label">{exam?.title}</IonLabel>
                                                                        </div>

                                                                        <div className="exam-footerbott">
                                                                            <hr className='exam-hr-content' />
                                                                            <div className="exam-action-content">
                                                                                {exam.type !== "outer" ? 
                                                                                    <>
                                                                                    <a className="exam-action-label action-btn" onClick={(e) => Download(exam, e)}>Download
                                                                                        <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                                                    </a>
                                                                                    <a className="exam-action-label action-btn" onClick={() => handleProfileButton(exam)}>Enviar resultado
                                                                                        <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                                                    </a>
                                                                                    <input
                                                                                        type="file"
                                                                                        ref={hiddenProfileButton}
                                                                                        onChange={(event: any) => UploadFile(event)}
                                                                                        style={{ display: "none" }}
                                                                                    />
                                                                                    </>
                                                                                :
                                                                                    <>
                                                                                    <div className="col-9 p-0" onClick={() => window.open(exam?.file_url)}>
                                                                                        <IonLabel className='d-flex align-items-center exam-outer-content'>
                                                                                            <IonImg src='assets/images/visular-icon.svg'/>
                                                                                            <IonLabel className="exam-detail-label">{exam?.file_name}</IonLabel>
                                                                                        </IonLabel>
                                                                                    </div>
                                                                                    <div className="col-3 p-0">
                                                                                    <IonLabel className="d-flex align-items-center download justify-content-end">
                                                                                        <Share text="" label="" sendicon="assets/images/exam-send.svg" entity={exam} type="exam-outer" present={present} />
                                                                                        <IonImg
                                                                                        src="assets/images/exam-delete-icon.svg"
                                                                                        style={{ minWidth: "30px" }}
                                                                                        className="ms-2 ms-md-3"
                                                                                        onClick={() => deleteExam(exam.id, null, exam.type)}
                                                                                        />
                                                                                    </IonLabel>

                                                                                    </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </IonCard>
                                                        )
                                                    })}
                                                </IonList>

                                            </>
                                        ) : (
                                            <NoDataComponent text="Não há Exames disponíveis no momento" />
                                        )
                                    )}
                                    <div className="bottomexam-box">
                                        <IonLabel className="exambox-subtitle">Enviar outros exames</IonLabel>
                                        <div className="row">
                                            <div className="col-12">
                                                <IonInput className="examinput" type="text" name="exams" value={title} onKeyUp={(event: any) => setTitle(event.target.value)} placeholder="Escreva o nome do exame"> </IonInput>
                                            </div>
                                            <div className="col-12 text-right d-flex my-2 align-items-center justify-content-end">
                                                <a className="input-exam-action-label d-flex" 
                                                    onClick={(e) => {
                                                    isEmpty(title.trim())
                                                        ? present({
                                                        message: "Por favor, insira o nome do exame",
                                                        duration: 3000,
                                                        })
                                                        :
                                                        handleProfileButton(null)
                                                    setExamRef(null);
                                                    }}                                                
                                                >Enviar resultado
                                                    <IonImg className="input-sub-icon" src='assets/images/medicine-share.svg' />
                                                </a>
                                                <input
                                                    type="file"
                                                    ref={hiddenProfileButton}
                                                    onChange={(event: any) => UploadFile(event)}
                                                    style={{ display: "none" }}
                                                />
                                            </div>

                                        </div>


                                    </div>

                                </div>
                            </div>


                        </div>



                    </div>
                </div>


                {/* <AppFooter /> */}
                {getPlatforms().includes("desktop") ?
                    <AppFooter />
                    :
                    <MobileFooter props={props} />
                }
            </IonContent>
        </IonPage>
    )
}
export default Exams








{/* {priority == 'Pedidos Antigos' ? (

                                // <IonList className={exams?.length > 0 ? "exam-pedidos-list-content" : "exam-nodata-list-content"}> 
                                <IonList className="exam-pedidos-list-content">

                                    <IonCard className="exam-pedidos-card-content" >
                                        <div className="exam-pedidos-content-box">
                                            <div>
                                                <IonImg className="exam-pedidos-img" src='assets/images/side-mobile-select-exames-gray.svg' />
                                            </div>
                                            <div className="exam-pedidos-box">
                                                <div className="exam-pedidos-detail-content">
                                                    <div>
                                                        <IonLabel className="exam-pedidos-date"> 20/12/2021</IonLabel>
                                                    </div>
                                                    <div className="exam-visuasls">
                                                        <IonImg src='assets/images/antigas-visuals.svg' />
                                                        <IonLabel className="exam-pedidos-detail-label">Visualizar</IonLabel>
                                                    </div>
                                                </div>
                                                <div>
                                                    <IonLabel className="exam-pedidos-detail-txt">Hemograma</IonLabel>
                                                </div>
                                            </div>
                                        </div>


                                    </IonCard>

                                </IonList>

                            ) : (



                                <IonList className={recentExams?.length > 0 ? "exam-list-content" : "exam-nodata-list-content"}>

                                    <IonCard className="exam-card-content" >
                                        <div className="exam-content-box">
                                            <div>
                                                <IonImg className="exam-content-header-img" src='assets/images/side-mobile-select-exames.svg' />
                                            </div>
                                            <div className="exam-box">
                                                <div className="exam-detail-content">
                                                    <div>
                                                        <IonLabel className="exam-pedi-date-label">20/12/2021</IonLabel>
                                                    </div>
                                                    <div className="exam-visuasls">
                                                        <IonImg src='assets/images/medicine-visuals.svg' />
                                                        <IonLabel className="exam-detail-label">Visualizar</IonLabel>
                                                    </div>
                                                </div>

                                                <div>
                                                    <IonLabel className="exam-detail-sub-txt-label">Hemograma</IonLabel>
                                                </div>

                                                <div className="exam-footerbott">
                                                    <hr className='exam-hr-content' />
                                                    <div className="exam-action-content">
                                                        <a className="exam-action-label">Download
                                                            <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                        </a>
                                                        <a className="exam-action-label">Enviar resultado
                                                            <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </IonCard>

                                </IonList>

                            )} */}


