import React from 'react';
import "./incompleteCallScreenStyle.scss"

const WaitingFinalizeScreen: React.FC<{}> = () => {
   
    return <span className="waitingFinal container ">
        <div>
        <p>Aguarde um instante</p>
        <img src={"/assets/images/dot-looping-GEAP.gif"}></img>
        </div>
    </span>
}

export default WaitingFinalizeScreen;