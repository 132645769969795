import IronFit from '@irontrainers/live-assist'
export * from '@irontrainers/live-assist/react'

const accountEmail = 'iron-live-support@users.iron.fit'
const accountPassword = process.env.REACT_APP_IRON_FIT_PATIENT_API_KEY || undefined

if (!accountEmail || !accountPassword) throw new Error('Cannot initialize Iron Fit connection!')

const ironFit = IronFit.initialize(accountEmail, accountPassword, {})

export default ironFit
