import { IonLabel, IonRow } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { isDesktop } from "../../helper/Common";
import "./pdfview.scss";

interface Props {
  isLoading?: boolean;
  pdfURL?: string;
  pdfURLExt?: string;
  numPages?: number;
  pdfString?: string;
  className?: string;
  from?: string;
}

const PDFViwer: React.FC<Props> = ({
  pdfURL = "",
  pdfURLExt = "",
  isLoading = false,
  pdfString = "",
  from = ""
}) => {
  const [numPages, setNumPages] = useState<any>(null);
  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };
  const iframeRef = useRef(null);
  useEffect(() => {
    if (iframeRef.current) {
      const iframeContainer = iframeRef.current.parentNode;
      iframeContainer.removeChild(iframeRef.current);
    }
    const container = document.getElementById("pdf-container");
    if (container) {
      const newIframe = document.createElement("iframe");
      newIframe.src = `${pdfURL}#toolbar=0&navpanes=0&scrollbar=0`;
      newIframe.title = "nutritional-plan";
      newIframe.width = "100%";
      newIframe.height = from === "setting" ? "450px" : "550px";
      newIframe.className = "fixedheight";
      container.appendChild(newIframe);
      iframeRef.current = newIframe;
    }
  }, [pdfURL]);
  return (
    <IonRow className="pdf-view">
      {pdfString ?
        <IonLabel className="w-100 d-block text-center top-heading">{pdfString}</IonLabel>
        : ""}
      <IonLabel className="d-block w-100 text-center">
      </IonLabel>
      {pdfURL &&
        (isDesktop ? (
          <div className="nutritional-plan-layout-view">
            <div id="pdf-container" />
          </div>
        ) : pdfURLExt === "pdf" ? (
          <Document
            file={{ url: pdfURL }}
            onLoadSuccess={onDocumentLoadSuccess}
            loading="PDF Carregando..."
            key={pdfURL}
          >
            {numPages !== null &&
              Array.from(new Array(numPages), (_element: any, index: any) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
          </Document>
        ) : (
          <div className="nutritional-plan-layout-view">
            <div id="pdf-container" />
          </div>
        ))}
    </IonRow>
  );
};

export default PDFViwer;
