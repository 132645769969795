import React from 'react';
import store from "../helper/storage";
import { CryptoService } from "./crypto.service";

class StoreService extends CryptoService {
  private storeProvider: typeof store;

  constructor() {
    super();
    this.storeProvider = store;
  }

  public async get(key: string) {
    return this.storeProvider.get(key);
  }

  public async set(key: string, value: unknown) {
    return this.storeProvider.set(key, value);
  }

  public async addDefaultSrc (ev) {
    ev.target.src = 'assets/images/profile-avtar-default.png'
  }

  public async removeKey(key: string) {
    return this.storeProvider.remove(key);
  }

  public async remove() {
    return this.storeProvider.clear();
  }

  public async clearStorage () {
    try {
      // Clear the entire database
      await store.clear();

       // Get all keys
      const keys = await store.keys();

    // Loop through keys and remove each item
    for (const key of keys) {
      await store.remove(key);
    }
    } catch (error) {
    }
  };

  public async logout(history) {
    await this.remove();
    await this.clearStorage()
    this.removeKey("user");
    this.removeKey("user_id");
    this.removeKey("user_cpf");
    this.removeKey("policyData")
    this.removeKey("terms")
    this.removeKey("privacy")
    this.removeKey("new_user")
    await localStorage.removeItem("persist:root");
    await localStorage.clear()
    window.location.href = "/login";
  }
}

export default new StoreService();
