import * as React from 'react'

export const FailedVideoPermissionScreen: React.FC<{ onTryAgainClick: () => void }> = ({ onTryAgainClick }) => {
  return (
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <h5 className="connection_text text-center mb-5" style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>Ops, não foi possível obter acesso à sua câmera e ao seu microfone.</h5>
        <button className="btn btn-success" onClick={onTryAgainClick} style={{ width: "260px" }}>Tentar novamente</button>
      </div>
    </div>
  )
}
