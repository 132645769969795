import styled from 'styled-components';

export const DoubleAuthContainer = styled.div`
 min-height: 90vh;

 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;

 h2{
   margin-bottom: 8px;

   @media(max-width: 425px){
     text-align: center;
     font-size: 22px;
   }
 }
`;

// ReviewScreen.tsx styles

export const Container = styled.div`
  min-height: calc(100vh - 150px);
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 100px;

  @media screen and (max-width: 1025px) {
    display: block;
  }

  .rateDiv
  {
    align-items: center;
  }
  .review-txt-area-width {
    @media screen and (max-width: 1025px) {
    width: 100%;
  }
  }
`;

export const Title = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;

  color: #004b93;

  margin-bottom: 10px;
`;

export const TextArea = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;

  margin-top: 60px;
  margin-bottom: 15px;

  textarea {
    width: 80%;

  }

  @media screen and (max-width: 1025px) {
    margin-left: 15px;
  }
  
`;

export const Image = styled.div`
  img {
    width: 100%;
    height: auto;
  }

  /* @media screen and (min-width: 1025px) {
    display: none !important
  } */
  @media screen and (max-width: 1025px) {
    margin-top: 30px;
  }
`;

// export const ImageFull = styled.div`
//   img {
//     width: 100%;
//     height: auto;
//   }

//   @media screen and (max-width: 1025px) {
//     display: none !important
//   }
//   @media screen and (min-width: 1025px) {
//     margin-top: 30px;
//   }
// `;

export const LeftColumn = styled.div`
  max-width: 50%;

  @media screen and (max-width: 1025px) {
      width: 50%;
  }
`;

export const RightColumn = styled.div`
  max-width: 100%;

  margin-left: 30px;

  @media screen and (max-width: 1025px) {
      margin-left: 0px;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const CancelButton = styled.button`
  border-radius: 10px 0px 0px 10px;
  border: solid 1px #0279FB;

  background-color: #fff;
  color: #0279FB;

  padding: 10px;

  text-align: center;
  font-size: 18px;
`;

export const SendButton = styled.button`
  border-radius: 0px 10px 10px 0px;
  border: solid 1px #0279FB;

  background-color: #0279FB;
  color: #fff;

  padding: 10px;

  text-align: center;
  font-size: 18px;
`;

export const BgCloud = styled.div`
  position: relative;

  margin-top: -80px;
  margin-left: -80px;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 1025px) {
    display: none !important;
  }
`;

export const Text = styled.div `
  margin-top: 50px;
  margin-bottom: 20px;


  h6 {
    color: #000;

    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;

    text-align: center;
  }

  h4 {
    margin: 20px 0;

    color: #000;

    font-size: 29,5px;
    font-weight: 700;
    font-family: Roboto;
  }

  b {
    color: #FF6500;

    font-size: 29,5px;
    font-weight: 700;
    font-family: Roboto;
  }

  p {
    color: #000;

    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
  }
`;