import {
    IonFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonLabel,
    IonSelect,
    IonImg,
    IonIcon,
    IonTabButton,
    getPlatforms,
    IonSegment,
    IonSegmentButton,
    useIonViewDidEnter,
    IonButton,
    IonModal,
    IonAvatar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { Zendesk } from '../../helper/Zendesk'
import "./mobileHeader.scss";
import StoreService from '../../services/store.service'
import storeService from "../../services/store.service";
const MobileHeader: React.FC<any> = ({ props, profileImg = null }) => {
    const [userData, setUserData] = useState(null);
    const [profileURL, setProfileURL] = useState<any>('assets/images/profile-icon.svg');
    const [isLgoutConfirmation, setIsLogoutConfirmation] = useState<any>(false)
    useIonViewDidEnter(() => {
        StoreService.get('user').then(res => {
            if (res) {

                setUserData(res);
                return
            } else {
                props.history.push("/");
            }
        })
    });
    const logout = () => {
        StoreService.set("user", null);
        StoreService.set("user_id", null);
        StoreService.set("policyData", null);
        StoreService.set("privacy", null);;
        StoreService.set("terms", null);;
        StoreService.set("new_user", "");
        setIsLogoutConfirmation(false);
        StoreService.set("user_cpf", null);
        props.history.replace("/login");
        window.history.pushState(null, null, props.history.location.href);
        window.onpopstate = function () {
            props.history.go(1);
        };
    };
    const navigateTo = () => {
        window.location.href = '/home'
    }
    useEffect(() => {
        storeService.get('user').then((res: any) => {
            setProfileURL(res.avatar.url);
        }).catch((err: any) => {
            console.warn("err", err);
            setProfileURL("assets/images/profile-icon.svg");
        })
    }, [profileImg, props.location])
    return (
        <>
            <div className="mobileheader">

                <div className="d-flex align-items-center px-3 pt-4 pb-1 justify-content-between" onClick={() => navigateTo()}>
                    <IonImg className="logo" src='assets/images/amplasauudelogo.svg' />
                    <IonLabel className="toptilte">Seu médico sem sair de casa</IonLabel>
                </div>
                <div className="seconddiv d-flex align-items-center justify-content-between px-3 py-4">
                    {userData?.privacy_policy && userData?.terms_of_use ?
                        <>
                        {props?.match?.path === "/settings" &&
                          <IonImg
                                src="/assets/images/fillBack.svg"
                                className="back-icon me-2"
                                onClick={() => props.history.replace("/home")}
                            />
                        }
                    <div> <IonLabel className="usrename">Olá, {userData?.name}</IonLabel></div>
                    <div className="righticons d-flex align-items-center"><IonAvatar style={{ "height": "45px", "width": "45px" }} onClick={() => { props.history.push("/profile") }}><img src={profileURL || "assets/images/profile-avtar-default.png"} /></IonAvatar>
                        <IonAvatar className="settingicon" style={{ "height": "40px", "width": "40px" }} onClick={() => { props.history.push("/settings") }}><img src="assets/images/settings.svg" /></IonAvatar>
                        <IonImg src="assets/images/close.svg" onClick={() => setIsLogoutConfirmation(true)} /></div>
                        </> :
                        <IonImg src="assets/images/close.svg" onClick={() => setIsLogoutConfirmation(true)} />
                    }
                </div>
            </div>
            <IonModal
                isOpen={isLgoutConfirmation}
                cssClass="logout-modal"
                onDidDismiss={() => setIsLogoutConfirmation(false)}
            >
                <span className="logout-title">
                    Tem certeza de que deseja sair?
                </span>

                <div>
                    <IonButton
                        shape="round"
                        color="primary"
                        expand="block"
                        className="custom-logout-btn"
                        onClick={logout}
                    >
                        Sim
                    </IonButton>
                    <IonLabel
                        className="custom-logout-cancel-btn"
                        onClick={() => setIsLogoutConfirmation(false)}
                    >
                        Cancelar
                    </IonLabel>
                </div>
            </IonModal>
        </>

    );
};
export default MobileHeader;

