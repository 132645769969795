import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonProgressBar,
  IonRow,
  IonText,
  getPlatforms,
  useIonToast,
  useIonViewDidEnter
} from "@ionic/react";
import axios from "axios";
import "moment-timezone";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../components/UI/Button";
import Content from "../components/UI/Content";
import AppFooter from "../components/appfooter/AppFooter";
import Header from "../components/header/Header";
import MobileFooter from "../components/mobileFooter/mobilefooter";
import MobileHeader from "../components/mobileHeader/mobileHeader";
import PDFViwer from "../components/pdfViewer";
import platform from "../helper/platform";
import PasswordDeleteModal from "../pages/privacy-policy/PasswordDeleteModal";
import { default as StoreService, default as storeService } from "../services/store.service";
import PasswordTermsModal from "../components/../pages/privacy-policy/PasswordTermsModal";
import "./settings.scss";
import Modal from "../components/Modal";
import Input from "../components/UI/Input";
import moment from "moment-timezone";

const forwardsettings: any = (
  <svg
    width="12"
    height="21"
    viewBox="0 0 12 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.911378 19.8894C0.494818 19.4555 0.494818 18.752 0.911378 18.3181L8.69046 10.2148L0.911376 2.11163C0.494816 1.67771 0.494816 0.974198 0.911376 0.54028C1.32793 0.106364 2.00331 0.106364 2.41987 0.54028L10.9532 9.42917C11.3698 9.86308 11.3698 10.5666 10.9532 11.0005L2.41987 19.8894C2.00331 20.3233 1.32794 20.3233 0.911378 19.8894Z"
      fill="#696B73"
    />
  </svg>
)

const SettingItem = ({ onClick, text, cssclass }) => (
  <IonItem onClick={onClick} className={cssclass}>
    <IonText>{text}</IonText>
    {forwardsettings}
  </IonItem>
);

const SettingsPage: React.FC<any> = (props) => {
  const [userData, setUserData] = useState(null);
  const [screen, setShowScreen] = useState<string>("");
  const [screentitle, setShowScreenTitle] = useState<string>("");
  const [isDeletePasswordModalOpen, setISDeletePasswordModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isPasswordModal, setIsPasswordModal] = useState<boolean>(false);
  const [pdfLink, setPdfLink] = useState(null);
  const [pdfURLExt, setpdfURLExt] = useState<any>(null);
  const [termData, setTermData] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [deleteAccount, setDeleteAccount] = useState<boolean>(false);
  const [birthdate ,setBirthdate] = useState<string>("");
  const [birthError,setBirthError]= useState<boolean>(false);
  const [loading, setLoading]= useState<boolean>(false);
  const history = useHistory();
  const [present] = useIonToast();

  useIonViewDidEnter(() => {
    StoreService.get("user").then((res) => {
      if (res) {
        if (!res?.terms_of_use) props.history.push("/terms-of-user");
        else if (!res?.privacy_policy) props.history.push("/privacy-policy-info");
        else setUserData(res);
        return;
      } else {
        props.history.push("/");
      }
    });
    setShowScreen("")
    setShowScreenTitle("")
    GetAllTermsPrivacyList((resp: any) => {
    });
  });

  const logout = () => storeService.logout(history);

  useEffect(()=>{
    if(!deleteAccount) setBirthdate("");
  },[deleteAccount])

  /**
   * Function for accecpt terms & policies list
   * @param callback : response!!!!!!
   */

  const GetAllTermsPrivacyList = async (callback: any) => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/terms-and-conditions/list`).then((listData: any) => {
      setTermData(listData)
      callback(listData);
    }).catch((err: any) => {
      console.log("List data error===", err);
    })
  }

  /**
* Function for accecpt/revoke terms & policies !!!!
* !!!!: response!!!!!!
*/


  const revokeTermsAndPolicy = () => {
    if (screen === "terms") {
      const tempdata = termData?.data?.terms.find(
        (d) => d.typeOfTerm === "terms_of_use"
      );
      revoke(tempdata);
    } else if (screen === "privacy") {
      const tempdata = termData?.data?.terms.find(
        (d) => d.typeOfTerm === "privacy_policy"
      );
      revoke(tempdata);
    }
  };


  /**
 * Function for accecpt/revoke terms & policies !!!!
 * !!!!: response!!!!!!
 */

  const revoke = (termsData: any) => {
    storeService.get("user").then((res) => {
      if (res) {
        const data: any = {
          patientId: res?.user_id,
          revokeTermIds: [termsData?._id],
          corporationId: res?.corporationId
        };
        axios
          .post(
            `${process.env.REACT_APP_API_PARSE_BASE_URL}/terms-and-conditions/accept-revoke`,
            data
          )
          .then(async (res: any) => {
            if (res?.data?.message === "Success!") {
              logout();
            }
          })
          .catch((error: any) => {
            console.log("errorInCalling", error);
          });
      }
    });
  }
  const deleteAccountModal = () => {
    setDeleteAccount(true)
  }

  const verifyUserAndRemove = async () => {
    try {
      setLoading(true);
      const data = {
        cpf: userData?.cpf,
        birthdate: moment.utc(birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      axios
        .post(
          `${process.env.REACT_APP_API_PARSE_BASE_URL}auth/plansaude/login`,
          data
        )
        .then(async (resp: any) => {
          if (resp) {
              let data = {
                id: userData?.user_id,
                isDependentUser: false,
                cemig: false
              };
              axios
                .post(
                  `${process.env.REACT_APP_API_PARSE_BASE_URL}scheduling/patient/delete_account`,
                  data
                )
                .then(async (res: any) => {
                  if (res) storeService.logout(props);
                  setLoading(false);
                })
                .catch((error: any) => {
                });
          }
        }).catch((error: any) => {
          setLoading(false);
          setBirthdate("");
          present({ message: "Detalhes do usuário inválidos", duration: 3000 });
        });
    } catch (error) {
      setLoading(false);
      console.error("logn error", error.response);
    }
  }

  
  return (
    <IonPage className="home-comp">
      <IonContent>
        {getPlatforms().includes("desktop") ? (
          <Header props={props} routeName="home" />
        ) : (
          <MobileHeader props={props} />
        )}
        <IonGrid className="settings-screen p-0">
          <IonRow className="container">
            <IonCol size="12" sizeLg="4" className="col-left pt-md-4 py-4">
              <IonLabel className="d-flex align-items-start arrow-leftdls">
              <IonImg src={"/assets/images/fillBack.svg"} className="backarrow me-4" onClick={() => props.history.replace("/home")} />
              <IonText className="d-block main-heading">Olá {userData?.name}</IonText></IonLabel>
              <IonList className="mb-4 ms-lg-5">
                <SettingItem
                  cssclass={screen === "privacy"?"firstitem selectedSetting": "firstitem"}
                  onClick={() => {
                    if (platform.isAndroid || platform.isMobile) {
                      props?.history?.push("/config-privacy-policy-info");
                    } else {
                      const data = termData?.data?.terms.find(
                        (d) => d.typeOfTerm === "privacy_policy"
                      );
                      const { termFilePDF } = data;
                      setpdfURLExt(
                        termFilePDF?.substr(termFilePDF.lastIndexOf(".") + 1)
                      );
                      setPdfLink(termFilePDF);
                      setShowScreen("privacy");
                      setShowScreenTitle("Política de privacidade");
                    }
                  }}
                  text={"Política de privacidade"}
                />
                <SettingItem
                  cssclass={screen === "terms"?"selectedSetting": ""}
                  onClick={() => {
                    if (platform.isAndroid || platform.isMobile) {
                      props?.history?.push("/config-terms-use");
                    } else {
                      const data = termData?.data?.terms.find(
                        (d) => d.typeOfTerm === "terms_of_use"
                      );
                      const { termFilePDF } = data;
                      setpdfURLExt(
                        termFilePDF?.substr(termFilePDF.lastIndexOf(".") + 1)
                      );
                      setPdfLink(termFilePDF);
                      setShowScreen("terms");
                      setShowScreenTitle("Termos de uso");
                    }
                  }}
                  text={"Termos de uso"}
                />
              </IonList>
              <IonRow className="mt-5 bottom-buttons">
                <Button
                  text="Confirmar exclusão"
                  expand="full"
                  // shape="round"
                  color="primary"
                  fill="outline"
                  className="greenoutline-btn w-100"
                  handleClick={() => deleteAccountModal()}
                />
                <Button
                  text={"Sair do APP"}
                  handleClick={logout}
                  // shape="round"
                  fill={"clear"}
                  className="without-border-btn greenoutline-btn w-100 mt-2 logout-text"
                />
              </IonRow>
            </IonCol>

            <IonCol size="12" sizeLg="7" className="col-right">
              <div className="top_aeras">
              <IonLabel className="mainText d-flex align-items-center mb-4">
                {screentitle &&
                  <>
                    <IonImg className="iconleft me-2" src={"/assets/images/fileicon.svg"} /> {screentitle}
                  </>

                }
              </IonLabel>
              {screen === "terms" ? (
                <PDFViwer
                  isLoading={isLoading}
                  pdfURL={pdfLink}
                  pdfURLExt={pdfURLExt}
                  from="setting"
                />
              ) : screen === "privacy" ? (
                <PDFViwer
                  isLoading={isLoading}
                  pdfURL={pdfLink}
                  pdfURLExt={pdfURLExt}
                  from="setting"
                />
              ) : ""} </div>
              {screen && (
                <div className="bottom-btn">
                  <IonRow className="inner-sec">
                    <IonCol className="d-flex align-items-center justify-content-center">
                      <Button
                        expand="full"
                        shape="round"
                        className="orange-btn w-50 me-4"
                        text="Revogar termos"
                        fill={"outline"}
                        color="primary"
                        handleClick={() => {
                          setIsOpen(true);
                        }}
                      />
                      <Button
                        handleClick={() => {
                          setShowScreen("");
                          setShowScreenTitle("");
                        }}
                        expand="full"
                        shape="round"
                        color="primary"
                        className="greenoutline-btn w-50"
                        text="Fechar"
                      />
                    </IonCol>
                  </IonRow>
                </div>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <PasswordDeleteModal
          {...props}
          isOpen={isDeletePasswordModalOpen}
          setIsOpen={setISDeletePasswordModalOpen}
        />
        <PasswordTermsModal
          isPasswordModal={isPasswordModal}
          setIsPasswordModal={setIsPasswordModal}
          revokeTermsAndPolicy={revokeTermsAndPolicy}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
        <Modal isOpen={deleteAccount} setIsOpen={setDeleteAccount} backdropDismiss={true}>
          <IonLabel className="password-delete-title text-align-center">
            {"Insira sua CPF e data de nascimento para revogar os termos"}
          </IonLabel>
          <div className="passWelContent">
            <IonRow className="mb-2">
              <Input
                name="cpf"
                type="tel"
                className="custom-mask maskedInput-border"
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  ".",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                ]}
                placeholder="CPF"
                value={userData?.cpf}
                isReadOnly={true}
                disabled={true}
              />
            </IonRow>
            <IonRow>
              <Input
                className="custom-mask maskedInput-border"
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                placeholder="Data de nascimento"
                value={birthdate}
                type="tel"
                name="birthdate"
                errors={birthError}
                errorText={"Digite sua data de nascimento"}
                handleChange={(event: any) => {
                  let temp = event.target.value.replaceAll("_", "");
                  setBirthdate(temp);
                  setBirthError(false);
                }}
              />
            </IonRow>
          </div>
          {loading && <IonProgressBar type="indeterminate" className="mt-1" />}
          <div className="btn-logout-Bottom">
            <Button
              expand="full"

              className="greenoutline-btn-setting w-100"
              text="Confirmar"
              fill={"solid"}
              disabled={
                !userData?.cpf || userData?.cpf?.length < 11 || !birthdate || birthdate.length < 10
              }
              color={"primary"}
              handleClick={() => verifyUserAndRemove()}
            />
            <Button
              handleClick={() => {
                setIsPasswordModal(false);
                setBirthdate("");
                setBirthError(false);
                setDeleteAccount(false)
              }}
              expand="full"
              color="danger"
              className="orange-btn w-100 mt-3"
              text="Cancelar"
              fill="clear"
            />
          </div>
        </Modal>

        {getPlatforms().includes("desktop") ? (
          <AppFooter />
        ) : (
          <MobileFooter props={props} />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SettingsPage;

