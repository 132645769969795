import React from 'react';
import { LivePatientSupportFailureReport } from '@irontrainers/live-assist/rtdb'
// import incompleteCall from '../patient-dashboard/assets/images/incompleteCall.png';
import "./incompleteCallScreenStyle.scss"
const IncompleteCallScreen: React.FC<{
    supportId: string,
    onReport:
    (
        supportId: string,
        payload: { patientFailureReport: LivePatientSupportFailureReport, patientReviewNotes: string },
        tryAgain?: boolean
    ) => Promise<void>,
}> = ({ supportId, onReport }) => {
    const [reporting, setReporting] = React.useState(false)
    const [cachedSupportId] = React.useState(supportId)

    const report = React.useCallback(async (tryAgain?: boolean) => {
        if (reporting) return
        setReporting(true)
        try {
            const patientReviewNotes = ' '
            const patientFailureReport: LivePatientSupportFailureReport = {}
            if (window.navigator.appName) patientFailureReport.browser = window.navigator.appName
            if (window.navigator.appVersion) patientFailureReport.version = window.navigator.appVersion
            patientFailureReport.platform = 'web'
            await onReport(cachedSupportId, { patientReviewNotes, patientFailureReport }, tryAgain)
        } catch (error) {
            console.error('Error retrieved when reporting support', error)
        }
        setReporting(false)
    }, [reporting, setReporting, cachedSupportId, onReport])

    const reportWithoutTryAgain = React.useCallback(() => report(), [report])
    const reportAndTryAgain = React.useCallback(() => report(true), [report])

    return <div className="container">
        <div className='incomplete-call-main'>
            <div className='heading-text'>
                Ops, algo não saiu como o esperado.
            </div>
            <div className='small-text'>Vamos analisar o que aconteceu <br /> com seu atendimento.</div>
            <div className='img-div'>
                <div className='box-img'>

                <img src={"assets/images/incompleteCall.svg"}></img>
                </div>
                <div >
                    <div className='rejoin-text'>Volte para seu atendimento sem se preocupar com a fila.</div>
                    <button className="voltar-btn" onClick={reportAndTryAgain}>Voltar com prioridade</button>
                    <div className='cancle-btn' onClick={reportWithoutTryAgain}>Cancelar</div>
                </div>
            </div>

        </div>
    </div>
}

export default IncompleteCallScreen;