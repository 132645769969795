import * as React from "react";
import {
  IonContent,
  IonPage,
  useIonToast,
  getPlatforms,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonButton,
  useIonViewDidEnter,
  IonImg,
} from "@ionic/react";
import { liveSupport } from "../../clients/iron-fit-patient";
import "bootstrap/dist/css/bootstrap.min.css";
import CountDownTimer from "./CountDownTimer";
import "./AwaitingScreen.scss";
import Header from "../../components/header/Header";
import AppFooter from "../../components/appfooter/AppFooter";
import platform from "../../helper/platform";
import HeaderNav from "../../components/headernav/HeaderNav";
import SideBar from "../../components/sidebar/Sidebar";
import storeService from "../../services/store.service";

export const AwaitingScreen: React.FC<{
  onCancel: () => Promise<void> | void;
  patientId: string;
}> = ({ onCancel = () => { }, patientId }) => {
  const { supportQueuePosition } = liveSupport.useLivePatientSync();
  const { supportStartForecast } = liveSupport.useLivePatientSync();
  const [patient] = liveSupport.useLivePatient(patientId);
  const supportStartForecastMinutes = Math.round(
    (supportStartForecast || 0) / 60 / 1000
  );
  const countDownSeconds = supportStartForecastMinutes * 60 || 6 * 60;
  const [userData, setUserData] = React.useState<any>(null);
  React.useEffect(() => {
    storeService
      .get("user")
      .then((res) => {
        setUserData(res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  return (
    <div className="support-container">
      <div className="w-100">
        {platform.isMobileweb && (
          <div className="back-header position-relative">
            <IonImg
              src="/assets/images/back-icon.svg"
              className="back-icon"
              onClick={() => onCancel()}
            />
            <div className="consultas-text-mobile">Pronto Atendimento</div>
          </div>
        )}
        <div className="container auto-h-container">
          <div className="width-90">
            <div className="symptoms-patient-name d-none d-xl-block page-title">
              Olá, {userData?.name}
            </div>
            <div className="row mt-lg-5">
              {!platform.isMobileweb && (
                <div className="col-lg-6 align-items-center justify-content-center d-none d-md-flex">
                  <IonImg
                    src="/assets/images/symptoms-img.svg"
                    className="symptoms-screen-img"
                  />
                </div>
              )}
              <div className="col-xl-6 col-sm-12 d-flex align-items-center">
                <div className="awaiting-container md-width w-100">

                  <div className="row">
                    <div className="col-12 col-md-7">
                      <div className="small-title-div">
                        <div className="content-box-sm mb-4">
                          {!platform.isMobileweb && (
                            <div className="symptoms-sub-title">
                              Pronto Atendimento
                            </div>
                          )}
                          <div className="symptoms-sub-text symptoms-sub-text-lg">
                            Seu atendimento começará em breve
                          </div>
                        </div>
                      </div>


                      <div className="sub-text sub-text-green">

                        {(supportQueuePosition != null)

                          ? `Você é o ${supportQueuePosition}º da fila.`
                          : 'Waiting for number'
                        }
                      </div>
                      <div className="sub-text">
                        Em instantes, você será atendido por um
                        {patient?.awaitingOperator
                          ? " enfermeiro"
                          : patient?.awaitingDoctor ||
                            patient?.transferredFromSupportId
                            ? " médico"
                            : " enfermeiro"}.
                      </div>
                      <div
                        className="call-cancel-btn cancel-border-btn m-block-btn mt-5 d-none d-md-block"
                        onClick={() => onCancel()}
                      >
                        Cancelar atendimento
                      </div>
                    </div>
                    <div className="col-12 col-md-5 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div className="content-progressbar progressbar-margin">
                        <CountDownTimer duration={countDownSeconds} />
                      </div>
                    </div>
                  </div>
                  <div
                    className="call-cancel-btn cancel-border-btn m-block-btn mt-4 d-md-none"
                    onClick={() => onCancel()}
                  >
                    Cancelar atendimento
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
