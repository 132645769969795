import { IonCol, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenu, IonPage, IonRouterOutlet, IonRow, IonSegment, IonSegmentButton, IonSplitPane, IonTitle, IonToolbar } from '@ionic/react'
import React from 'react';
import './NoDataComponent.scss'
const NoDataComponent: React.FC<any> = ({text}) => {
    return (
        <div className="nodata-container">
            <IonLabel>{text?text:"Não há profissionais disponíveis no momento."}</IonLabel>
            {/* <IonLabel>Tente novamente mais tarde.</IonLabel> */}
        </div>
    )
}

export default NoDataComponent