import {
  getPlatforms,
  IonButton,
  IonContent,
  IonImg,
  IonPage,
  useIonToast,
  useIonViewDidEnter,
  useIonViewDidLeave,
} from "@ionic/react";
import { liveSupport } from "@irontrainers/live-assist/react";
import axios from "axios";
import { isEmpty } from "lodash";
import moment from "moment";
import "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import ironFit from "../../clients/iron-fit-patient";
import AppFooter from "../../components/appfooter/AppFooter";
import Header from "../../components/header/Header";
import MobileFooter from "../../components/mobileFooter/mobilefooter";
import MobileHeader from "../../components/mobileHeader/mobileHeader";
import Modal from "../../components/Modal";
import { usePatientAuth } from "../../components/patient-support/auth";
import { SpecialityButton } from "../../components/patient-support/SpecialityButton";
import { checkconnection, PresupportError } from "../../components/PresupportError";
import platform from "../../helper/platform";
import storeService from "../../services/store.service";
import PasswordTermsModal from "../privacy-policy/PasswordTermsModal";
import AddUserDetails from "./AddUserDetails";
import "./HomeScreen.scss";

const HomeScreen: React.FC<any> = (props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [present, dismiss] = useIonToast();
  const [screen, setScreen] = useState("patientSupport");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [doctors, setDoctors] = React.useState<any>([]);
  const [userData, setUserData] = React.useState<any>(null);
  const [isPresupportError, setIsPresupportError] = useState<boolean>(false);
  const [termsPrivacylistData, setTermsPrivacylistData] = useState<any>();
  const [isPrivacyTermsUpdate, setIsPrivacyTermsUpdate] = useState<boolean>(false);
  const [isDeniedPrivacyTerms, setIsDeniedPrivacyTerms] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("");
  const [isDeletePasswordModalOpen, setISDeletePasswordModalOpen] = useState<any>(false);
  const [patientId, patient, authenticating, signInWithCpf] =
    usePatientAuth();
  const { startOnline, clearPatient } = liveSupport.useLivePatientSync();

  const { supportStartForecast } = liveSupport.useLivePatientSync();

  useIonViewDidEnter(() => {
    storeService.get("user").then((res) => {
      if (res) {
        if (res?.terms_of_use && res?.privacy_policy) {
          let { cpf, birthdate } = res;
          const tempCPF = cpf?.replace(/-|_|\./g, "").toString();
          signInWithCpf(tempCPF, moment(birthdate).format("YYYY-MM-DD"))
            .then((response: any) => {

            })
            .catch((err: any) => {
              console.error("err", err);
            });
        }
        if (!res?.terms_of_use && !res?.privacy_policy) {
          props.history.push("/privacy-policy");
        }else if (!res?.privacy_policy) props?.history.push("/privacy-policy-info")
        else if (!res?.terms_of_use) props?.history.push("/terms-of-user");
        else{
          getVersion(res?.user_id, res)
          getSpecialities();
          getAutoSpeciality();
          setUserData(res);
        }
      } else {
        props.history.push("/login");
      }

    });
  });

  /**
  * Function for get version details
  */
  const getVersion = async (userId, users) => {
    try {
      const details = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/get-versions?id=${userId}`)
      const { data: { data: userTerms } } = details;
      await getAllTermsPrivacyList();
      storeService.set("user", { ...users, serviceAccess: userTerms.serviceAccess })
      if (!isEmpty(userTerms)) {
        const data = userTerms?.userTerms.reduce((p, n) => n.isNewVersionAvailable ? [...p, n.typeOfTerm] : [...p], [])
        if (data?.includes("privacy_policy") && data?.includes("terms_of_use")) { setIsPrivacyTermsUpdate(true); setModalType("both") }
        else if (data?.includes("privacy_policy")) { setModalType("privacy_policy"); setIsPrivacyTermsUpdate(true); }
        else if (data?.includes("terms_of_use")) { setModalType("terms_of_use"); setIsPrivacyTermsUpdate(true); }
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
* Function for get all terms and privacy list
*/
  const getAllTermsPrivacyList = async () => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}terms-and-conditions/list`).then((listData: any) => {
      setTermsPrivacylistData(listData?.data?.terms)
    }).catch((err: any) => {
      console.log("List data error===", err);
    }
    )
  };

  /**
     * Function for accept new terms
     */
  const acceptTermsPrivacy = (termData: any) => {
    let acceptTermIds;
    if (modalType === "both")
      acceptTermIds = termsPrivacylistData?.reduce((p, n) => [...p, n._id], []);
    else
      acceptTermIds = termsPrivacylistData?.reduce(
        (p, n) => (n.typeOfTerm === modalType ? [...p, n._id] : [...p]),
        []
      );
    storeService.get("user").then((res) => {
      if (res) {
        const data: any = {
          patientId: userData?.user_id,
          acceptTermIds,
          corporationId: userData?.corporationId,
        };
        axios
          .post(
            `${process.env.REACT_APP_API_PARSE_BASE_URL}terms-and-conditions/accept-revoke`,
            data
          )
          .then(async (res: any) => {
            if (res?.data?.message === "Success!") {
              setIsDeniedPrivacyTerms(false);
              setIsPrivacyTermsUpdate(false);
              setModalType("")
            }
          })
          .catch((error: any) => {
            setIsDeniedPrivacyTerms(false);
            setIsPrivacyTermsUpdate(false);
            setModalType("")
          });
      }
    })
  };

  const getAutoSpeciality = async () => {
    const isHistory = await storeService.get("isHistory");
    if (isHistory) {
      await storeService.set("isHistory", false);
      const appointment = await storeService.get("appointment");
      setScreen("appointment");
      setSelectedDepartment(appointment?.staffSpeciality);
      await storeService.set("appointment", null);
    }
  };
  useEffect(() => {
    getSpecialities();
  }, []);
  useEffect(() => {
    if (selectedDepartment.length > 0) {
      setScreen("appointment");
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (!userData) return;
    if (userData?.email && !isEmpty(userData?.telePhone[0]?.number)) setIsModalOpen(false);
    else setIsModalOpen(true);
  }, [userData]);

  useIonViewDidLeave(() => {
    setIsModalOpen(false);
  })

  const getSpecialities = async () => {
    const user = await storeService.get("user");
    axios
      .get(
        process.env.REACT_APP_MIX_PARSE_API +
        `corporation/new_agenda/${user?.corporationId}/get_specialities/patient`
      )
      .then((resp: any) => {
        if (resp.status == 200) {
          let specialities = [];
          resp.data.data.map((speciality: any) => {
            specialities.push(speciality.name);
          });

          setDoctors(specialities);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const isContentVisible = () => {
    if (!doctors || doctors?.length === 0) {
      return false;
    } else if (
      doctors &&
      doctors?.length === 1 &&
      (doctors[0] === "DOCTOR" || doctors[0] === "NURSE")
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleStartClick = useCallback(async () => {
    try {
      await checkconnection(setIsPresupportError);
    } catch (err) {
      setIsPresupportError(true);
      return
    }
    try {
      await ironFit
        .liveSupport()
        .startPatientAwaitingSupport(patientId, "video");
      props.history.push("/support");
    } catch (error) {
      console.log("error", error.response);
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.errorMessage ===
        "Patient with already running support"
      ) {
        window.location.href = "/support";
      } else if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        present({ message: error.response.data.message, duration: 3000 });
      } else {
        present({ message: "Algo deu errado", duration: 3000 });
      }
    }
  }, [userData, patient, patientId]);

  React.useEffect(() => {
    if (!patientId) return;
    if (!patient) return;
    clearPatient();
    if (Object.keys(patient).length === 0) return;
    const patients = { ...patient, corporationId: "ampla-saude" };
    startOnline(patientId, patients);
  }, [patientId, patient, userData]);

  const forecastMinutes = (time: any) => {
    return Math.round((time || 0) / 60 / 1000);
  };

  return (
    <IonPage className="home-comp">
      <IonContent>
        {getPlatforms().includes("desktop") ? (
          <Header props={props} routeName="home" />
        ) : (
          <MobileHeader props={props} />
        )}
        <div
          className={
            selectedDepartment == ""
              ? "homewrapper mainScreenImage"
              : "homewrapper"
          }
        >
          <div
            className={
              getPlatforms().includes("desktop") && isContentVisible()
                ? "sub-content-multi-speciality"
                : "sub-content sub-top-remove increse-width half-top"
            }
          >
            {screen === "patientSupport" && (
              <>
                <div className="user-support-page wl-ampla-saude">
                  <div className="row">
                    {!platform.isMobileweb && (
                      <div className="col-md-5 position-relative">
                        <IonImg
                          src="/assets/images/home-amplairon.png"
                          className="symptoms-img"
                        />
                      </div>
                    )}
                    <div className="col-md-12 col-xl-7 rightpart">
                      {!platform.isMobileweb && (
                        <div className="patient-name">
                          Olá, {userData?.name}
                        </div>
                      )}
                      <div className="row">
                        {userData?.serviceAccess && (
                          <div className="col-md-6 mb-4 mb-md-0">
                            <div className="innermainbox">
                              <SpecialityButton
                                props={props}
                                doctors={doctors}
                                selectedDepartment={selectedDepartment}
                                setSelectedDepartment={setSelectedDepartment}
                              />
                            </div>
                            <div className="bottomtextdiv text-center w-100 mt-3 d-none d-md-block">
                              Agende uma consulta com o especialista médico que
                              você desejar
                            </div>
                          </div>
                        )}
                        <div className="col-md-6">
                          <div className="innermainbox">
                            <div className="boxes">
                              <div className="pronto-atend-view">
                                <div className="pronto-atend-header-txt">
                                  Pronto Atendimento Digital
                                </div>
                                <div className="pronto-atend-sub-txt">
                                  Seja atendido o quanto antes por um de nossos
                                  profissionais de saúde.
                                </div>
                              </div>

                              <div className="pronto-btn-view">
                                <IonButton
                                  expand="block"
                                  className="pronto-atend-btn btn-block"
                                  onClick={() => {
                                    handleStartClick();
                                  }}
                                >
                                  <IonImg
                                    src="/assets/images/video_corner.svg"
                                    className="video-corner-icon"
                                  />
                                  Pronto Atendimento
                                </IonButton>
                              </div>
                            </div>
                          </div>
                          <div className="bottomtextdiv text-center mt-3 d-none d-md-block">
                            Seja atendido pelo médico de plantão. <br />
                            Tempo estimado de espera{" "}
                            <b>
                              {" "}
                              {forecastMinutes(supportStartForecast)} minutos.
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <Modal
          isOpen={isPrivacyTermsUpdate}
          setIsOpen={setIsPrivacyTermsUpdate}
          modalType={modalType}
          data={termsPrivacylistData}
          btnSim="Discordar"
          handleClick={() => {
            setIsPrivacyTermsUpdate(false);
            setIsDeniedPrivacyTerms(true);
          }}
          btnCancelText="Concordar"
          btnCancelFill="clear"
          btnCancelColor="danger"
          btnCancelClick={() => {
            acceptTermsPrivacy(modalType);
          }}
          termsclass={"termsclass"}
          backdropDismiss={false}
          modalAccecptDeny={"accept"}
        />


        <Modal
          modalAccecptDeny={"deny"}
          isOpen={isDeniedPrivacyTerms}
          setIsOpen={setIsDeniedPrivacyTerms}
          modalType={modalType}
          data={termsPrivacylistData}
          btnSim="Continuar e desativar conta"
          handleClick={() => {
            setIsDeniedPrivacyTerms(false);
            setISDeletePasswordModalOpen(true);
          }}
          btnCancelText="Voltar"
          btnCancelFill="clear"
          btnCancelColor="danger"
          btnCancelClick={() => {
            setIsDeniedPrivacyTerms(false);
            setIsPrivacyTermsUpdate(true);
          }}
          termsclass={"termsclass"}
          backdropDismiss={false}
        />
        <PasswordTermsModal
          isPasswordModal={isDeletePasswordModalOpen}
          setIsPasswordModal={setISDeletePasswordModalOpen}
          setIsPrivacyTermsUpdate={setIsPrivacyTermsUpdate}
          isDelete={true}
          props={props}
          isHome={true}
          backdropDismiss={false}
        />
        <PresupportError setIsPresupportError={setIsPresupportError} isPresupportError={isPresupportError} />
        {isModalOpen && (
          <AddUserDetails
            user={userData}
            setUser={setUserData}
            setIsModalOpen={setIsModalOpen}
          />
        )}

        {getPlatforms().includes("desktop") ? (
          <AppFooter />
        ) : (
          <MobileFooter props={props} />
        )}
      </IonContent>
    </IonPage>
  );
};
export default HomeScreen;
