import * as React from 'react'

export const LoadingScreen: React.FC<{ withAuthLabel?: boolean }> = ({ withAuthLabel }) => {
  return(
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <img src="/assets/images/btn_loader.gif" alt="" style={{height:"60px", width:"80px"}} />
        {withAuthLabel && <h5 className="connection_text mt-4 mb-5 text-center">Autenticando...</h5>}
        {!withAuthLabel && <h5 className="connection_text mt-4 mb-5">{' '}</h5>}
      </div>
    </div>
  )
}
