import { IonImg, IonLabel, IonText } from "@ionic/react";
import React from "react";
import { PatientName, PatientTitle } from "./styles";

interface Props {
  name: string;
  title: string;
  backIcon?: boolean;
  history?: any;
}

const Contents: React.FC<Props> = ({ name, title, backIcon, history }) => (
  <>
    <IonLabel className="d-flex align-items-start arrow-leftdls">
      {backIcon && <IonImg src={"/assets/images/fillBack.svg"} className="backarrow me-4" onClick={() => history && history.back()} />}
      <IonText className="d-block main-heading"> {name && <PatientName>Olá, {name}!</PatientName>}
        <PatientTitle>{title}</PatientTitle></IonText></IonLabel>
  </>
);

export default Contents;
