import * as React from 'react'
import { RemoteAudioTrack } from 'twilio-video'
import { twilio } from '../../clients/iron-fit-patient'
import { AudioTrack } from './components'

export const StaffAudio: React.FC<{ staffId: string }> = ({ staffId }) => {
  const { room } = twilio.useVideoContext()
  const participants = room.participants ? Array.from(room.participants.values()) : []
  const staffAudioPublication = React.useMemo(() => {
    if (!participants) return
    for (const participant of participants) {
      if (participant.identity === staffId) {
        if (!participant.audioTracks) return
        const audioTracks = Array.from(participant.audioTracks.values())
        console.log('Staff detected audio tracks publication', audioTracks)
        for (const audioPublication of audioTracks) return audioPublication
      }
    }
  }, [participants, staffId])
  const staffAudioTrack = twilio.useTrack(staffAudioPublication)

  const [, setTick] = React.useState(Date.now())
  React.useEffect(() => {
    if (staffAudioTrack) return
    const intervalId = setInterval(() => setTick(Date.now()), 5 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [staffAudioTrack, setTick])

  if (!staffAudioTrack) return null
  return <AudioTrack id="remote-audio-element" track={staffAudioTrack as RemoteAudioTrack} />
}

