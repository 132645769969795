import * as React from 'react'
import { Track, LocalVideoTrack, RemoteVideoTrack } from 'twilio-video'

export interface VideoTrackProps {
  track: LocalVideoTrack | RemoteVideoTrack
  isLocal?: boolean
  priority?: Track.Priority | null
  id?: string
  className?: string
  style?: React.CSSProperties
  userType?: 'staff' | 'patient'
}

export const VideoTrack: React.FC<VideoTrackProps> = ({ track, isLocal, priority, id, className, style: extStyle, userType }) => {
  const ref = React.useRef<HTMLVideoElement>(null!)

  React.useEffect(() => {
    const el = ref.current
    el.muted = true
    if (track.setPriority && priority) {
      track.setPriority(priority)
    }
    track.attach(el)
    return () => {
      track.detach(el)
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null)
      }
    };
  }, [track, priority])

  // The local video track is mirrored.
  const isFrontFacing = track.mediaStreamTrack.getSettings().facingMode !== 'environment'
  const style: React.CSSProperties = React.useMemo(() => {
    const videoStyle: React.CSSProperties = { width: '100%', maxHeight: '100%', objectFit: 'cover', ...extStyle }
    if (isLocal && isFrontFacing) {
      if (userType === 'staff') videoStyle.transform = 'rotateY(180deg) translate(50%, -50%)'
      else videoStyle.transform = 'rotateY(180deg)'
    }
    return videoStyle
  }, [isLocal, isFrontFacing, extStyle, userType])

  return <video id={id} className={className} ref={ref} style={style} />
}
