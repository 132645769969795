import { IonCol, IonImg, IonLabel } from "@ionic/react";
import React from "react";
import { Button } from "../Button";
import Contents from "../Content";

interface Props {
  name?: string;
  title?: string;
  src?: string;
  history?: any;
  backIcon?: boolean;
  isBottomContent?: boolean;
  bottomContent?: any;
  bottomimage?: any;
  handleClick?;
  displaycancel?: boolean;
  backClick?: any;
}

const LeftGrid: React.FC<Props> = ({ name, title, src, history, backIcon = true, isBottomContent = false, bottomContent, bottomimage, handleClick, displaycancel = false, backClick }) => {
  return (
    <IonCol className="left-dls">
      <IonLabel className="d-flex align-items-start common-topleft">
      {backIcon && <IonImg src={"/assets/images/fillBack.svg"} className="backarrow me-3" onClick={() => backClick ? backClick() : (history && history.back())} />}
        <Contents name={name} title={title} />
      </IonLabel>
      <IonImg src={src} className="img-leftdls" />
      {displaycancel &&
        <IonLabel className="px-0 d-block text-center">
          <Button
            className="greenoutline-btn  mt-4 w-75 d-xl-none"
            // shape="round"
            color="danger"
            text="Sair da fila"
            fill="outline"
            handleClick={handleClick}
          />
        </IonLabel>
      }
      {bottomimage && <IonImg src={bottomimage} className="img-awating-gif" />}
      {bottomContent && <IonLabel className="bottom-texts">{bottomContent}</IonLabel>}
    </IonCol>
  );
};

export default LeftGrid;
