import * as React from 'react'

export const FailedAuthScreen: React.FC = () => {
  return (
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <h5 className="connection_text mb-5">Ops, tivemos um problema de autenticação. Por favor tente novamente ou entre em contato com o suporte.</h5>
      </div>
    </div>
  )
}
