import axios from 'axios'

const parseSessionTokenEl = document.head.querySelector<HTMLTemplateElement>('meta[name="token"]')
const parseSessionToken = parseSessionTokenEl && parseSessionTokenEl.content

const api = axios.create({
  baseURL: process.env.REACT_APP_MIX_PARSE_API,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Parse-Session-Token': parseSessionToken,
  },
})

export default api