// export * from './PatientSupportPage'

import React, { useState } from "react";
import {
  IonModal,
  IonText,
  IonProgressBar,
  IonButton,
  useIonViewDidEnter,
  IonPage,
  IonContent,
  getPlatforms,
} from "@ionic/react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import StoreService from "../../services/store.service";
import { PatientSupportPage } from "./PatientSupportPage";
import { liveSupport } from "../../clients/iron-fit-patient";
import Header from "../header/Header";
import MobileHeader from "../mobileHeader/mobileHeader";
import AppFooter from "../appfooter/AppFooter";
import MobileFooter from "../mobileFooter/mobilefooter";
const { LivePatientSyncProvider } = liveSupport;

const PatientSupport: React.FC<any> = (props) => {
  useIonViewDidEnter(async() => {
      StoreService.get("user").then((res) => {
        if (!res) {
          return props.history.push("/login");
        } 
      });
   
  });
  
  return (
    <>
         <IonPage>
      <IonContent>
        <LivePatientSyncProvider>
          {getPlatforms().includes("desktop") ? (
            <Header props={props} routeName="home" />
          ) : (
            <MobileHeader props={props} />
          )}
          <PatientSupportPage props={props} />
          {getPlatforms().includes("desktop") ? (
          <AppFooter />
        ) : (
          <MobileFooter props={props} />
        )}
          <ToastContainer
            position="top-right"
            autoClose={10000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          
        </LivePatientSyncProvider>
      </IonContent>
    </IonPage>
    </>
  );
};

export default PatientSupport;
