import { IonCol, IonContent, IonGrid, IonLabel, IonPage, IonProgressBar, IonRow, getPlatforms, useIonViewDidEnter } from "@ionic/react";
import axios from 'axios';
import React, { useState } from "react";
import { useHistory } from "react-router";
import { Button } from "../../components/UI/Button";
import Header from "../../components/header/Header";
import MobileHeader from "../../components/mobileHeader/mobileHeader";
import PDFViwer from "../../components/pdfViewer";
import storeService from "../../services/store.service";
import PasswordTermsModal from "./PasswordTermsModal";
import "./settings-privacy.scss";
import AppFooter from "../../components/appfooter/AppFooter";
import MobileFooter from "../../components/mobileFooter/mobilefooter";

const SettingsPrivacyPolicyInfo: React.FC<any> = (props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isPasswordModal, setIsPasswordModal] = useState<boolean>(false);
    const [termsData, setTermsData] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [pdfLink, setPdfLink] = useState(null);
    
    const [pdfURLExt, setpdfURLExt] = useState<any>(null);
    const history = useHistory();

    useIonViewDidEnter(() => {
        getAllTermsPrivacyList();
    });

    const logout = async () => {
        storeService.logout(history)
    };


    /**
       * Function for accecpt terms & policies list
       * @param callback : response!!!!!
       */
    const getAllTermsPrivacyList = async () => {
        await axios.get(`${process.env.REACT_APP_API_BASE_URL}terms-and-conditions/list`).then((listData: any) => {
            const data = listData?.data?.terms.find(
              (d) => d.typeOfTerm === "privacy_policy"
            );
            setTermsData(data)
            const { termFilePDF } = data;
            setpdfURLExt(termFilePDF?.substr(termFilePDF.lastIndexOf(".") + 1));
            setPdfLink(termFilePDF);
            }).catch((err: any) => {
              console.log("List data error===", err);
          }
    )}
    /**
     * Function for revoke, accept terms & policies
     * @param revokedata : Data to pass into api request
     */
    const revokeTerms = () => {
        storeService.get("user").then((res) => {
            if (res) {
                const data: any = {
                    patientId: res?.user_id,
                    revokeTermIds: [termsData?._id],
                    corporationId: termsData?.corporationId
                };
                axios
                    .post(
                    `${process.env.REACT_APP_API_PARSE_BASE_URL}/terms-and-conditions/accept-revoke`,
                    data
                    )
                    .then(async (res: any) => {
                    if (res?.data?.message === "Success!") {
                        logout();
                    }
                    })
                    .catch((error: any) => {
                    console.log("errorInCalling", error);
                    });
            }
        })
    };
    return <IonPage>
    <IonContent className="config-privacy-policy-info">
    {getPlatforms().includes("desktop") ? (
          <Header props={props} routeName="home" />
        ) : (
          <MobileHeader props={props} />
        )}
            {loading && <IonProgressBar type="indeterminate" className="mt-1" />}
            <IonGrid className="settingsTerms none-margin">
                <IonRow className="container">
                    <IonCol size="12" sizeLg="8">
                        <IonLabel className="head-dls">Política de Privacidade:</IonLabel>
                        {/* <PrivacyPolicyText /> */}
                        <PDFViwer isLoading={loading} pdfURL={pdfLink} pdfURLExt={pdfURLExt} />
                    </IonCol>
                    <IonCol size="12" sizeLg="4" className="bottom-btns">
                        <div className="bottom-button">
                            <Button
                                className="policy-revoke-button orange-btn w-100 revok-text"
                                // shape="round"
                                fill="outline"
                                text="Revogar termos"
                                handleClick={() => {
                                    setIsOpen(true);
                                }}
                            />
                            <Button
                                className="policy-cancel-button orange-btn w-100 mt-3"
                                // shape="round"
                                color="danger"
                                text="Fechar"
                                fill="solid"
                                handleClick={() => {
                                    props?.history?.replace("/settings");
                                }}
                            />
                        </div>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <PasswordTermsModal
                isPasswordModal={isPasswordModal}
                setIsPasswordModal={setIsPasswordModal}
                revokeTermsAndPolicy={revokeTerms}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
        />
         </IonContent>
         {getPlatforms().includes("desktop") ? (
          <AppFooter />
        ) : (
          <MobileFooter props={props} />
        )}
    </IonPage>
};

export default SettingsPrivacyPolicyInfo;