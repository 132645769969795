import { LivePatientSupportFailureReport } from '@irontrainers/live-assist/rtdb'
import { liveLogger } from '@irontrainers/live-assist/utils'
import React from 'react'
import { TwilioError } from 'twilio-video'

import ironFit, { auth, liveSupport, twilio } from '../../clients/iron-fit-patient'
import { whitelabelCustomer } from '../../utils'
import { FailedAuthScreen } from './FailedAuthScreen'
import { FailedDuplicatedVideoAccessScreen } from './FailedDuplicatedVideoAccessScreen'
import { FailedVideoPermissionScreen } from './FailedVideoPermissionScreen'
import { FailedVideoRoomExpiredScreen } from './FailedVideoRoomExpiredScreen'
import { LiveVideo } from './LiveVideo'
import { LoadingScreen } from './LoadingScreen'
import { PreSupportCheck } from './PreSupportCheck'
import { RedirectingApsScreen } from './RedirectingApsScreen'
import { ReviewScreen } from './ReviewScreen'
import { TwilioVideoCallScreen } from './TwilioVideoCallScreen'
import { WelcomeBanners } from './WelcomeBanners'
import { usePatientAuth } from './auth'

import Logger from './logger'

import { getPlatforms, useIonToast } from '@ionic/react'
import moment from 'moment'
import storeService from '../../services/store.service'
import { AwaitingScreen } from './AwaitingScreen'
import IncompleteCallScreen from './IncompleteCallScreen'
import WaitingFinalizeScreen from './WaitingFinalizeScreen'
const { VideoProvider } = twilio
declare const twiliovideo: any;

export const PatientSupportPage: React.FC<any> = (props) => {
  const [present, dismiss] = useIonToast();
  const [authUserId, authUserIdLoading] = auth.useAuthUserId()
  const [patientId, patient, authenticating, signInWithCpf, signOut] = usePatientAuth()
  const { initializing: initializingSync, startOnline,clearPatient, awaitingSince, startAwaitingSupport, cancelAwaitingSupport, livePatient } = liveSupport.useLivePatientSync()
  const runningSupportId = livePatient && livePatient.runningSupportId
  const pageRefreshRequestTick = livePatient && livePatient.pageRefreshRequestTick
  const transferredFromSupportId = livePatient && livePatient.transferredFromSupportId
  const [support] = liveSupport.useLiveSupport(runningSupportId)
  const parseId = support && support.parseId
  const [videoToken] = liveSupport.usePatientVideoAccessToken(runningSupportId, patientId)

  React.useEffect(() => {
    if (runningSupportId) liveLogger.setSupportId(runningSupportId)
    else liveLogger.clearSupportId()
    return () => {
      liveLogger.clearSupportId()
    }
  }, [runningSupportId])

  React.useEffect(() => {
    storeService.get("user")
      .then((res: any) => {
        if (res) {
          let { cpf, birthdate } = res;
          const tempCPF = cpf?.replace(/-|_|\./g, "").toString();
          signInWithCpf(tempCPF, moment(birthdate).format("YYYY-MM-DD"))
            .then((response: any) => {
              console.log(response)
            })
            .catch((err: any) => {
              console.log("err", err)
            });
        }
      })
  }, [])

  React.useEffect(() => {
    if (!authUserId) return
    if (!patientId) return
    if (!patient) return
    clearPatient();
    if (Object.keys(patient).length === 0) return
    console.log('will start synchronizing online state')
    const patients = { ...patient, corporationId: "ampla-saude" }
    Logger.setUser(patientId, 'patient');
    startOnline(patientId, patients);
    Logger.log('PatientSupportPage', `patient started online state hook with corporation id "${patient.corporationId}"`);
    // webWidget().auth(patient.name || 'Unknown', patient.email || 'Unknown', patient.app_name || 'Unknown', 'patient')
  }, [authUserId, patientId, patient])

  if (getPlatforms().includes('mobileweb') || !(getPlatforms().includes('android') || getPlatforms().includes('ios'))) {
    liveSupport.usePatientRefreshExecutor(patientId)
  }

  const [videoPermissionDenied, setVideoPermissionDenied] = React.useState(false)
  const handleVideoPermissionDeny = React.useCallback(() => {
    setVideoPermissionDenied(true)
  }, [setVideoPermissionDenied])
  const handleTryAgainVideoPermissionClick = React.useCallback(() => {
    setVideoPermissionDenied(true)
  }, [setVideoPermissionDenied])

  const [preLiveVideoChecked, setPreLiveVideoChecked] = React.useState(false)
  const markAsPreLiveVideoChecked = React.useCallback(() => {
    setPreLiveVideoChecked(true)
  }, [setPreLiveVideoChecked])
  React.useEffect(() => {
    if (!transferredFromSupportId) return
    if (!getPlatforms().includes("mobileweb") && (getPlatforms().includes("android") || getPlatforms().includes("ios"))) {
      setPreLiveVideoChecked(false)
    } else {
      setPreLiveVideoChecked(true)
    }
  }, [transferredFromSupportId, setPreLiveVideoChecked])

  const [preSupportChecking, setPreSupportChecking] = React.useState(false)
  const startPreSupportChecks = React.useCallback(() => {
    setPreSupportChecking(true)
  }, [setPreSupportChecking])
  const cancelPreSupportChecks = React.useCallback(() => {
    setPreSupportChecking(false)
  }, [setPreSupportChecking])

  const startAwaitingVideoSupport = React.useCallback(async () => {
    if (awaitingSince) return;
    storeService.get("user")
      .then(async (res: any) => {
        if (res) {
          let { birthdate } = res;
          birthdate = moment(new Date(birthdate)).format("YYYY-MM-DD");
          const age = moment().diff(birthdate, "years", false);
          if (age < 18) {
            try {
              await ironFit
                .liveSupport()
                .startPatientAwaitingSupport(patientId, "video", {
                  doctor: true,
                  preferredStaffDepartment: "Pediatrician"
                })
            } catch (e) {
              if (e.response && e.response.data && e.response.data.message) {
                present({ message: e.response.data.message, duration: 3000 })
              } else {
                present({ message: " Algo deu errado", duration: 3000 })
              }
              console.error("error when staring support", e);
            }
          } else {
            try {
              await startAwaitingSupport('video')
            } catch (e) {
              if (e.response && e.response.data && e.response.data.message) {
                present({ message: e.response.data.message, duration: 3000 })
              } else {
                present({ message: " Algo deu errado", duration: 3000 })
              }
              console.error("error when staring support", e);
            }
          }
        }
      })

    setPreSupportChecking(false)
    setPreLiveVideoChecked(true)
  }, [startAwaitingSupport, awaitingSince, setPreSupportChecking, setPreLiveVideoChecked])

  const cancelAwaitingVideoSupport = React.useCallback(async () => {
    try {
      await cancelAwaitingSupport('video')
      window.location.href = "/home"
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        present({ message: e.response.data.message, duration: 3000 })
      } else {
        present({ message: " Algo deu errado", duration: 3000 })
      }
      console.error("error when canceling support", e);
    }
    // const base64Param = window.location.search.split('&')[0]
    // window.location.href = `/support${base64Param}`
  }, [cancelAwaitingSupport])

  const initializing = authUserIdLoading || authenticating || initializingSync
  const badAuth = !initializing && !patientId

  const [duplicatedVideoAccess, setDuplicatedVideoAccess] = React.useState(false)
  const [expiredVideoRoom, setExpiredVideoRoom] = React.useState(false)
  const handleFinishExpiredRoomClick = React.useCallback(async () => {
    if (!runningSupportId) return;
    try {
      await ironFit.liveSupport().finishSupport(runningSupportId)
      setExpiredVideoRoom(false)
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        present({ message: e.response.data.message, duration: 3000 })
      } else {
        present({ message: " Algo deu errado", duration: 3000 })
      }
      console.error("error when finish expired room from support", e);
    }
  }, [runningSupportId, setExpiredVideoRoom])
  const handleTwilioError = React.useCallback((error: TwilioError) => {
    if (error && error.message) {
      if (error.message.indexOf('duplicate') !== -1) {
        setDuplicatedVideoAccess(true)
        return
      }
      if (error.message.indexOf('Access Token expired') !== -1) {
        setExpiredVideoRoom(true)
        return
      }
    }
    console.error('UNHANDLED Twilio error', error)
  }, [setDuplicatedVideoAccess, setExpiredVideoRoom])
  const handleUseHereClick = React.useCallback(() => {
    setDuplicatedVideoAccess(false)
  }, [setDuplicatedVideoAccess])

  const isSupportReviewing = runningSupportId && support && support.status === 'FINISHED' && !support.patientReviewTime
  const isSupportFailureFeedback = runningSupportId && support && support.status === 'FAILED' && !support.patientReviewTime

  const review = React.useCallback(async (supportId: string, { patientReviewNotes, patientReviewRating }: { patientReviewNotes: string, patientReviewRating: number }) => {
    try {
      await ironFit.liveSupport().reviewSupportAsPatient(supportId, { patientReviewNotes, patientReviewRating })
      window.location.href = "/home"
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        present({ message: e.response.data.message, duration: 3000 })
      } else {
        present({ message: " Algo deu errado", duration: 3000 })
      }
      console.error("error when reviewing of support", e);
    }
  }, [])

  const [reportingFailure, setReportingFailure] = React.useState(false)

  const reportFailureFeedback = React.useCallback(async (supportId: string, { patientReviewNotes, patientFailureReport }: { patientReviewNotes: string, patientFailureReport: LivePatientSupportFailureReport }, tryAgain?: boolean) => {
    if (reportingFailure) return
    setReportingFailure(true)
    try {
      await ironFit.liveSupport().reportSupportFailureFeedbackAsPatient(supportId, { patientReviewNotes, patientReviewRating: 0, patientFailureReport })
      if (patientId && tryAgain){
        await ironFit
          .liveSupport()
          .startPatientAwaitingSupport(patientId, "video");
      }else{
          window.location.href = "/home";
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.message) {
        present({ message: e.response.data.message, duration: 3000 })
      } else {
        present({ message: " Algo deu errado", duration: 3000 })
      }
      console.error("error when report support failure feedback as patient", e)
    }
    setReportingFailure(false)
  }, [patientId, reportingFailure, setReportingFailure])

  const [screen, setScreen] = React.useState({ current: 'authenticating', transitioning: false })
  const changeScreen = React.useCallback((nextScreen: string) => {
    setScreen((previous) => {
      if (nextScreen === previous.current) {
        console.log('ignore change of screen', nextScreen)
        return previous
      }
      console.log('will change screen', nextScreen)

      if (!getPlatforms().includes("mobileweb") && (getPlatforms().includes("android") || getPlatforms().includes("ios")) && nextScreen == 'review') {
        twiliovideo.closeRoom()
      }

      return { current: nextScreen, transitioning: true }
    })
  }, [setScreen])
  const currentScreen = screen.current
  const transitioning = screen.transitioning
  const showingScreen = transitioning ? 'loading' : currentScreen
  React.useEffect(() => {
    if (!transitioning) return
    const timeoutId = setTimeout(() => {
      setScreen(({ current }) => {
        console.log('finish screen change', current)
        return { current, transitioning: false }
      })
    }, 800)
    return () => clearTimeout(timeoutId)
  }, [transitioning, currentScreen])

  const isReviewing = isSupportReviewing || isSupportFailureFeedback
  const onSupportFlow = awaitingSince || runningSupportId || isReviewing

  const withError = (!initializing && !onSupportFlow && badAuth) || duplicatedVideoAccess || expiredVideoRoom || videoPermissionDenied

  const patientCorporationId = livePatient?.corporationId;
  const waitingFinalize = support?.waiting_finalize
  React.useEffect(() => {
    if (!getPlatforms().includes("mobileweb") && (getPlatforms().includes("android") || getPlatforms().includes("ios"))) {
      if (withError && badAuth) return changeScreen('failed_auth')
      if (withError && expiredVideoRoom) return changeScreen('failed_expired_video_room')
      if (withError && duplicatedVideoAccess) return changeScreen('failed_duplicated_video_access')
      // if (withError && videoPermissionDenied) return changeScreen('failed_video_permission')
      // if (!withError && initializing && !authenticating) return changeScreen('cpfauth')
      if (!withError && !onSupportFlow && !preSupportChecking) return changeScreen('welcome')
      if (!withError && !initializing && !onSupportFlow && preSupportChecking) return changeScreen('pre_support_checks')
      if (!withError && onSupportFlow && !runningSupportId && !isReviewing) return changeScreen('awaiting_staff')
      if (!withError && onSupportFlow && runningSupportId && !isReviewing && true) return changeScreen('live_video')
      if (!withError && onSupportFlow && runningSupportId && waitingFinalize) return changeScreen('waitingFinalize')
      if (!withError && onSupportFlow && isSupportReviewing) return changeScreen('review')
      if (!withError && onSupportFlow && isSupportFailureFeedback) return changeScreen('failure_feedback')
    } else {
      if (withError && badAuth) return changeScreen('failed_auth')
      if (withError && expiredVideoRoom) return changeScreen('failed_expired_video_room')
      if (withError && duplicatedVideoAccess) return changeScreen('failed_duplicated_video_access')
      if (withError && videoPermissionDenied) return changeScreen('failed_video_permission')
      // if (!withError && initializing && !authenticating && (isPlansaude || isNori || isSesiSp || isSesiDf || isSirioLibanes || isIbrasa || isPac19)) return changeScreen('cpfauth')
      if (!withError && (initializing || reportingFailure)) return changeScreen('authenticating')
      if (!withError && !initializing && !onSupportFlow && !preSupportChecking) return changeScreen('welcome')
      if (!withError && !initializing && !onSupportFlow && preSupportChecking) return changeScreen('pre_support_checks')
      if (!withError && onSupportFlow && !runningSupportId && !isReviewing) return changeScreen('awaiting_staff')
      if (!withError && onSupportFlow && runningSupportId && !isReviewing && !preLiveVideoChecked) return changeScreen('pre_live_video_checks')
      if (!withError && onSupportFlow && runningSupportId && !isReviewing && preLiveVideoChecked) return changeScreen('live_video')
      if (!withError && onSupportFlow && runningSupportId && waitingFinalize) return changeScreen('waitingFinalize')
      if (!withError && onSupportFlow && isSupportReviewing) return changeScreen('review')
      if (!withError && onSupportFlow && isSupportFailureFeedback) return changeScreen('failure_feedback')
    }
  }, [changeScreen, withError, badAuth, expiredVideoRoom, duplicatedVideoAccess, initializing, onSupportFlow, preSupportChecking, runningSupportId, isReviewing, isSupportReviewing, isSupportFailureFeedback, videoPermissionDenied, preLiveVideoChecked, authenticating, reportingFailure, pageRefreshRequestTick,waitingFinalize])

  const hideFooter = onSupportFlow && runningSupportId && !isReviewing

  React.useEffect(() => {
    Logger.log('PatientSupportPage', `Current screen: ${showingScreen}`)
  }, [showingScreen])

  if (!getPlatforms().includes("mobileweb") && (getPlatforms().includes("android") || getPlatforms().includes("ios"))) {
    return (
      <div className={`user-support-page wl-${whitelabelCustomer} platform-call mobile`}>
        {showingScreen === 'loading' && <LoadingScreen />}
        {showingScreen === 'failed_auth' && <FailedAuthScreen />}
        {showingScreen === 'failed_expired_video_room' && <FailedVideoRoomExpiredScreen onFinishClick={handleFinishExpiredRoomClick} />}
        {showingScreen === 'failed_duplicated_video_access' && <FailedDuplicatedVideoAccessScreen onUseHereClick={handleUseHereClick} />}
        {/* {showingScreen === 'failed_video_permission' && <FailedVideoPermissionScreen onTryAgainClick={handleTryAgainVideoPermissionClick} />} */}
        {showingScreen === 'authenticating' && <LoadingScreen withAuthLabel />}
        {/* {showingScreen === 'cpfauth' && <CPFAuthScreen signInWithCpf={signInWithCpf} authenticated={!!patientId} />} */}
        {showingScreen === 'redirecting_aps' && <RedirectingApsScreen />}
        {showingScreen === 'welcome' && <WelcomeBanners onStartClick={startPreSupportChecks} onSignoutClick={signOut} patientId={patientId} doctors={props?.props?.doctors} setSelectedDepartment={props?.props?.setSelectedDepartment} selectedDepartment={props?.props?.selectedDepartment} />}
        {showingScreen === 'pre_support_checks' && <PreSupportCheck onFinish={startAwaitingVideoSupport} onCancel={cancelPreSupportChecks} />}
        {showingScreen === 'awaiting_staff' && <AwaitingScreen onCancel={cancelAwaitingVideoSupport} patientId={patientId} />}
        {showingScreen === 'pre_live_video_checks' && <PreSupportCheck onFinish={markAsPreLiveVideoChecked} onCancel={handleVideoPermissionDeny} hideCancel />}
        {showingScreen === 'live_video' && videoToken && runningSupportId && parseId &&
          <TwilioVideoCallScreen videoToken={videoToken} roomName={parseId} supportId={runningSupportId} patientId={patientId} pageRefreshRequestTick={pageRefreshRequestTick} />}
        {showingScreen === 'review' && <ReviewScreen supportId={runningSupportId || ''} onReview={review} />}
        {showingScreen === 'waitingFinalize' && <WaitingFinalizeScreen />}
        {showingScreen === 'failure_feedback' && <IncompleteCallScreen supportId={runningSupportId || ''} onReport={reportFailureFeedback} />}
      </div>
    )
  } else {
    return (
      <div className={`user-support-page wl-${whitelabelCustomer}`}>
        {showingScreen === 'loading' && <LoadingScreen />}
        {showingScreen === 'failed_auth' && <FailedAuthScreen />}
        {showingScreen === 'failed_expired_video_room' && <FailedVideoRoomExpiredScreen onFinishClick={handleFinishExpiredRoomClick} />}
        {showingScreen === 'failed_duplicated_video_access' && <FailedDuplicatedVideoAccessScreen onUseHereClick={handleUseHereClick} />}
        {showingScreen === 'failed_video_permission' && <FailedVideoPermissionScreen onTryAgainClick={handleTryAgainVideoPermissionClick} />}
        {showingScreen === 'authenticating' && <LoadingScreen withAuthLabel />}
        {/* {showingScreen === 'cpfauth' && <CPFAuthScreen signInWithCpf={signInWithCpf} authenticated={!!patientId} />} */}
        {showingScreen === 'redirecting_aps' && <RedirectingApsScreen />}
        {showingScreen === 'welcome' && <WelcomeBanners onStartClick={startPreSupportChecks} onSignoutClick={signOut} patientId={patientId} doctors={props?.props?.doctors} setSelectedDepartment={props?.props?.setSelectedDepartment} selectedDepartment={props?.props?.selectedDepartment} />}
        {showingScreen === 'pre_support_checks' && <PreSupportCheck onFinish={startAwaitingVideoSupport} onCancel={cancelPreSupportChecks} />}
        {showingScreen === 'awaiting_staff' && <AwaitingScreen onCancel={cancelAwaitingVideoSupport} patientId={patientId} />}
        {showingScreen === 'pre_live_video_checks' && <PreSupportCheck onFinish={markAsPreLiveVideoChecked} onCancel={handleVideoPermissionDeny} hideCancel />}
        {showingScreen === 'live_video' && (
          <VideoProvider onError={handleTwilioError}>
            {runningSupportId && <LiveVideo supportId={runningSupportId} history={props?.props?.history} />}
          </VideoProvider>
        )}
        {showingScreen === 'review' && <ReviewScreen supportId={runningSupportId || ''} onReview={review} />}
        {showingScreen === 'waitingFinalize' && <WaitingFinalizeScreen />}
        {showingScreen === 'failure_feedback' && <IncompleteCallScreen supportId={runningSupportId || ''} onReport={reportFailureFeedback} />}
      </div>
    )
  }
}
