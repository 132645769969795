import * as React from 'react'
import { RemoteVideoTrack } from 'twilio-video'
import { twilio } from '../../clients/iron-fit-patient'
import { VideoTrack } from './components'
import { IonImg } from '@ionic/react'
export const StaffVideo: React.FC<{ staffId: string }> = ({ staffId }) => {
  const { room } = twilio.useVideoContext()
  const participants = room.participants ? Array.from(room.participants.values()) : []
  const staffVideoPublication = (() => {
    if (!participants) return
    for (const participant of participants) {
      if (participant.identity === staffId) {
        if (!participant.videoTracks) return
        const videoTracks = Array.from(participant.videoTracks.values())
        console.log('Staff detected video tracks publication', videoTracks)
        for (const videoPublication of videoTracks) {
          if (videoPublication.isTrackEnabled) return videoPublication
        }
      }
    }
  })()
  const staffVideoTrack = twilio.useTrack(staffVideoPublication)

  const [, setTick] = React.useState(Date.now())
  React.useEffect(() => {
    if (staffVideoTrack) return
    const intervalId = setInterval(() => setTick(Date.now()), 5 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [staffVideoTrack, setTick])

  if (!staffVideoTrack) return <div className='staff-video-off'> <IonImg src="assets/images/staffVideoOff.png" alt="staff video off"></IonImg> </div>
  return <VideoTrack id="remote-video-element" className="remote-video" track={staffVideoTrack as RemoteVideoTrack} />
}

