import { IonLabel, IonProgressBar, IonRow, useIonToast } from "@ionic/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Modal from "../../components/Modal";
import storeService from "../../services/store.service";
import "./settings-term.scss";
import axios from "axios";

const PasswordTermsModal: React.FC<any> = ({
  isPasswordModal,
  isOpen,
  setIsOpen,
  setIsPasswordModal,
  text,
  revokeTermsAndPolicy,
  isDelete = false,
  props,
  setIsPrivacyTermsUpdate,
  isHome = false,
  backdropDismiss = true
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [birthdate, setBirthdate] = useState<string>("");
  const [birthError, setBirthError] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  const [present] = useIonToast();
  useEffect(() => {
    storeService.get("user").then((res) => {
      setUserData(res)
    });
  }, []);

  useEffect(() => {
    if(!isOpen) setBirthdate("");
  }, [isOpen]);

  const verifyUserAndRemove = async () => {
    try {
      setLoading(true);
      const data = {
        cpf: userData?.cpf,
        birthdate: moment.utc(birthdate, "DD/MM/YYYY").format("YYYY-MM-DD"),
      };
      axios
        .post(
          `${process.env.REACT_APP_API_PARSE_BASE_URL}auth/plansaude/login`,
          data
        )
        .then(async (resp: any) => {
          if (resp) {
            if (isDelete) {
              let data = {
                id: userData?.user_id,
                isDependentUser: false,
                cemig: false
              };
              axios
                .post(
                  `${process.env.REACT_APP_API_PARSE_BASE_URL}scheduling/patient/delete_account`,
                  data
                )
                .then(async (res: any) => {
                  setIsPrivacyTermsUpdate(false);
                  if (res) storeService.logout(props);
                  setLoading(false);
                })
                .catch((error: any) => {
                });
            } else await revokeTermsAndPolicy();
          }
        }).catch((error: any) => {
          setLoading(false);
          present({ message: "Detalhes do usuário inválidos", duration: 3000 });
        });
    } catch (error) {
      setLoading(false);
      console.error("logn error", error.response);
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        text="Tem certeza que deseja revogar os termos?"
        subText={
          "Você irá perder o acesso à plataforma e as informações de saúde aqui registradas serão apagadas. Porém, você continua sendo uma vida ativa no programa de saúde da Iron label."
        }
        btnSim="Sim"
        btnSimColor="danger"
        btnSimFill="solid"
        btnCancelColor="danger"
        btnCancelFill="clear"
        handleClick={() => {
          setIsOpen(false);
          setIsPasswordModal(true);
        }}
        btnCancelText="Cancelar"
        btnCancelClick={() => setIsOpen(false)}
        termsclass="termsclass"
      />

      <Modal isOpen={isPasswordModal} setIsOpen={setIsPasswordModal} backdropDismiss={backdropDismiss}>
        <IonLabel className="password-delete-title text-align-center">
          {text
            ? text
            : "Insira sua CPF e data de nascimento para revogar os termos"}
        </IonLabel>
        <div className="passWelContent">
          <IonRow className="mb-2">
            <Input
              name="cpf"
              type="tel"
              className="custom-mask maskedInput-border"
              mask={[
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                ".",
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
              ]}
              placeholder="CPF"
              value={userData?.cpf}
              isReadOnly={true}
              disabled={true}
            />
          </IonRow>
          <IonRow>
            <Input
              className="custom-mask maskedInput-border"
              mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              placeholder="Data de nascimento"
              value={birthdate}
              type="tel"
              name="birthdate"
              errors={birthError}
              errorText={"Digite sua data de nascimento"}
              handleChange={(event: any) => {
                let temp = event.target.value.replaceAll("_", "");
                setBirthdate(temp);
                setBirthError(false);
              }}
            />
          </IonRow>
        </div>
        {loading && <IonProgressBar type="indeterminate" className="mt-1" />}
        <div className="btn-logout-Bottom">
          <Button
            expand="full"

            className="greenoutline-btn w-100"
            text="Confirmar"
            fill={"solid"}
            disabled={
              !userData?.cpf || userData?.cpf?.length < 11 || !birthdate || birthdate.length < 10
            }
            color={"primary"}
            handleClick={() => verifyUserAndRemove()}
          />
          <Button
            handleClick={() => {
              if (isHome) setIsPrivacyTermsUpdate(true);
              setIsPasswordModal(false);
              setBirthdate("");
              setBirthError(false);
            }}
            expand="full"
            color="danger"
            className="orange-btn w-100 mt-3"
            text="Cancelar"
            fill="clear"
          />
        </div>
      </Modal>
    </>
  );
};
export default PasswordTermsModal;
