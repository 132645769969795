import * as React from 'react'
import { LocalVideoTrack } from 'twilio-video'
import { twilio } from '../../clients/iron-fit-patient'
import {  VideoTrack } from './components'

export const PatientVideo: React.FC = () => {
  const { localTracks: publishedLocalTracks } = twilio.useVideoContext()
  // const [localVideoTrack, getLocalVideoTrack] = twilio.useLocalVideoTrack()
  const publishedLocalVideoTrack = React.useMemo(() => {
    for (const track of publishedLocalTracks) {
      if (track.kind.includes('video')) return track as LocalVideoTrack
    }
  }, [publishedLocalTracks])

  const [, setTick] = React.useState(Date.now())
  React.useEffect(() => {
    if (publishedLocalVideoTrack) return
    const intervalId = setInterval(() => setTick(Date.now()), 5 * 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [publishedLocalVideoTrack, setTick])

  // const [lastRequest, setLastRequest] = React.useState(0)
  // const [requestingNewTrack, setRequestingNewTrack] = React.useState(false)
  // React.useEffect(() => {
  //   if (publishedLocalVideoTrack) return
  //   if (requestingNewTrack) return
  //   if (localVideoTrack) return
  //   if (lastRequest >= (Date.now() - (7 * 1000))) return
  //   setRequestingNewTrack(true)
  //   console.log('requesting new video track')
  //   getLocalVideoTrack().then(() => {
  //     setLastRequest(Date.now())
  //     setRequestingNewTrack(false)
  //   }).catch((error) => {
  //     console.error('Error requesting new local video track', error)
  //     setLastRequest(Date.now())
  //     setRequestingNewTrack(false)
  //   })
  // }, [publishedLocalVideoTrack, requestingNewTrack, setRequestingNewTrack, localVideoTrack, getLocalVideoTrack, lastRequest, setLastRequest, tick])

  // const localParticipant = room.localParticipant
  // React.useEffect(() => {
  //   if (publishedLocalVideoTrack) return
  //   if (!localVideoTrack) return
  //   if (!localParticipant || !localParticipant.publishTrack) return
  //   console.log('publishing new video track', localVideoTrack)
  //   localParticipant.publishTrack(localVideoTrack)
  // }, [localVideoTrack, publishedLocalVideoTrack, localParticipant])

  if (!publishedLocalVideoTrack) return <div id="my-video-element" className="self-preview" />
  // if (!publishedLocalVideoTrack) return <img className="no_video_show" src="/assets/image/avatar/client-default.png" />
  return <VideoTrack id="my-video-element" className="remote-video" track={publishedLocalVideoTrack} isLocal userType="patient" />
}

