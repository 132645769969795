import * as React from 'react'
import { AudioTrack as IAudioTrack } from 'twilio-video'
// import { useActiveAudioSinkId } from '../state'

interface AudioTrackProps {
  id?: string
  track: IAudioTrack;
}

export const AudioTrack: React.FC<AudioTrackProps> = ({ id, track }) => {
  const audioEl = React.useRef<HTMLAudioElement>(null!)
  // const [activeSinkId] = useActiveAudioSinkId()

  React.useEffect(() => {
    const el = audioEl.current
    track.attach(el)
    el.setAttribute('data-cy-audio-track-name', track.name)
    if (el.setSinkId) el.setSinkId('default')
    return () => {
      track.detach()
    }
  }, [track])

  // React.useEffect(() => {
  //   if (!activeSinkId) return
  //   if (!audioEl.current.setSinkId) return
  //   audioEl.current.setSinkId(activeSinkId)
  // }, [activeSinkId]);

  return <audio ref={audioEl} id={id}></audio>
}
