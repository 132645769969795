import { toast, ToastOptions } from 'react-toastify'

export function notifyPatient(type: 'warning' | 'error' | 'info', text: string, options: { hideAfter?: number } = {}) {
  const toastOptions: ToastOptions = {}
  if (options.hideAfter) toastOptions.autoClose = options.hideAfter
  if (type === 'warning') {
    toast.warning(text, toastOptions)
    return
  }
  if (type === 'error') {
    if(text == "Não foi possível iniciar seu atendimento por problemas de acesso ou compatibilidade com sua câmera/microfone! Tente em outro dispositivo ou navegador!") {
      toast.warning("Não foi possível iniciar se atendimento por problemas de acesso ou compatibilidade com sua câmera/microfone! Tente em outro navegador ou acesse o chat na página ou o manual.", toastOptions)
    } else {
      toast.warning(text, toastOptions)
    }
    return
  }
  toast.info(text, toastOptions)
}
