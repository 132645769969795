import * as React from 'react'
import { twilio } from '../../clients/iron-fit-patient'
import { notifyPatient } from './notify-patient'

const { PreStreamChecks } = twilio

export const PreSupportCheck: React.FC<{ onFinish: () => Promise<void> | void, hideCancel?: boolean, onCancel: () => Promise<void> | void }> = ({ onFinish, onCancel, hideCancel }) => {
  const [canceling, setCanceling] = React.useState(false)
  const handleCancel = React.useCallback(async () => {
    if (canceling) return
    setCanceling(true)
    try {
      await onCancel()
    } catch (error) {
      console.error('Error canceling support', error)
    }
    setCanceling(false)
  }, [canceling, setCanceling, onCancel])

  return (
    <PreStreamChecks smooth onFinish={onFinish} onNotify={notifyPatient} onCancel={handleCancel}>
      <div className="container">
        <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
          <h5 className="connection_text mb-5" style={{ textAlign: 'center', paddingLeft: 20, paddingRight: 20 }}>Configurando sua câmera e microfone, aguarde...</h5>
          {!hideCancel && <button className="btn text-white" onClick={handleCancel} disabled={canceling} style={{width:"100px", backgroundColor: "#FF9500"}}>Cancelar</button>}
        </div>
      </div>
    </PreStreamChecks>
  )
}
