import {
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonImg,
  IonIcon,
  IonTabButton,
  getPlatforms,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CNES } from "../../helper/Common";
import { Zendesk } from "../../helper/Zendesk";
import "./mobileFooter.scss";
const MobileFooter: React.FC<any> = ({ props }) => {
  const [showFooterSection, setShowFooterSection] = useState(
    getPlatforms().includes("desktop") ||
    getPlatforms().includes("ipad") ||
    getPlatforms().includes("tablet")
  );

  return (
    <>
      <div className="footermobile py-3">
        <div className="container">
          <div className="row align-items-center">
            {props?.match?.path === "/privacy-policy" || props?.match?.path === "/terms-of-user" || props?.match?.path === "/privacy-policy-info" || props?.match?.path === "/config-privacy-policy-info" || props?.match?.path === "/config-terms-use" ? null :
              <div className="col-12 mb-4 topsegment py-4">
                <IonSegment>
                  <IonSegmentButton
                    className="mainicon"
                    value="history"
                    layout="icon-start"
                    onClick={() => {
                      props.history.push("/history");
                    }}
                  >
                    <IonImg
                      className="icons"
                      src="/assets/images/mobile-footer-cosultas.svg"
                    />
                    <IonLabel className="labellink">Minhas Consultas</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    className="mainicon"
                    value="medicine"
                    layout="icon-start"
                    onClick={() => {
                      props.history.push("/medicine");
                    }}
                  >
                    {" "}
                    <IonImg
                      className="icons"
                      src="/assets/images/mobile-footer-medicine.svg"
                    />
                    <IonLabel className="labellink">Meus Medicamentos</IonLabel>
                  </IonSegmentButton>
                  <IonSegmentButton
                    className="mainicon"
                    value="exam"
                    layout="icon-start"
                    onClick={() => {
                      props.history.push("/exams");
                    }}
                  >
                    <IonImg
                      className="icons"
                      src="/assets/images/mobile-footer-exams.svg"
                    />
                    <IonLabel className="labellink">Meus Exames</IonLabel>
                  </IonSegmentButton>
                </IonSegment>
              </div>}
            <div className="col-12 d-flex align-items-center">
              {" "}
              <IonImg
                src="assets/images/chat-icon.svg"
                onClick={() => Zendesk()}
              />
              <div className="socialicons d-flex align-items-center justify-content-center">
                {/* <IonImg src="assets/images/facebook.svg" onClick={() => window.open("https://www.facebook.com/planosaudesim")} />
                                <IonImg src="assets/images/instagram.svg" onClick={() => window.open("https://www.instagram.com/saudesimplano/")} />
                                <IonImg src="assets/images/linkein.svg" onClick={() => window.open("https://www.linkedin.com/company/saudesim/")} />
                                <a href="mailto:saudesim@iron.fit"><IonImg src="assets/images/mail.svg" /></a> */}

                <IonImg src="assets/images/facebook-mobile.svg" onClick={() => window.open("https://www.facebook.com/amplasaudeoficial")} />
                <IonImg src="assets/images/instagram-mobile.svg" onClick={() => window.open("https://www.instagram.com/vemserampla")} />
                {/* <IonImg src="assets/images/linkein-mobile.svg" /> */}
                <a href="mailto:Contato@amplasaude.com">
                  <IonImg src="assets/images/mail-mobile.svg" />
                </a>
              </div>
            </div>

            <div className="col-12 mt-4">
              <div className="row">
                <div className="col-7">
                  {" "}
                  <IonLabel className="logotext">
                    PLATAFORMA IRON TELEMEDICINA
                  </IonLabel>
                </div>
                <div className="col-5 text-right">
                  <IonImg
                    className="logoans"
                    src="assets/images/logofooter-ANS.svg"
                  />
                  <IonLabel className="textright">CNES: {CNES}</IonLabel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileFooter;
