import {
  IonFooter,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonSelect,
  IonImg,
  IonIcon,
  IonTabButton,
  getPlatforms,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { CNES } from "../../helper/Common";
import { Zendesk } from '../../helper/Zendesk'
import "./AppFooter.scss";
const AppFooter: React.FC<any> = () => {
  const [showFooterSection, setShowFooterSection] = useState((getPlatforms().includes("desktop") || getPlatforms().includes("ipad") || getPlatforms().includes("tablet")))
  return (
    <>

      <div className="footerdiv py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 d-flex align-items-center"> <IonImg src='assets/images/chat-icon.svg' onClick={()=>Zendesk()}/>
              <IonLabel className="logotext">Plataforma Iron Telemedicina</IonLabel></div>
            <div className="col-md-4 text-center"> <div className="socialicons d-flex align-items-center justify-content-center"> 
              {/* <IonImg src="assets/images/facebook.svg" onClick={()=>window.open("https://www.facebook.com/planosaudesim")}/>
              <IonImg src="assets/images/instagram.svg" onClick={()=>window.open("https://www.instagram.com/saudesimplano/")}/>
              <IonImg src="assets/images/linkein.svg" onClick={()=>window.open("https://www.linkedin.com/company/saudesim/")}/>
              <a href="mailto:saudesim@iron.fit"><IonImg src="assets/images/mail.svg" /></a>  */}
              <IonImg src="assets/images/facebook.svg"  onClick={()=>window.open("https://www.facebook.com/amplasaudeoficial")}  />
              <IonImg src="assets/images/instagram.svg"  onClick={()=>window.open("https://www.instagram.com/vemserampla")}  />
              {/* <IonImg src="assets/images/linkein.svg"/> */}
              <a href="mailto:Contato@amplasaude.com"><IonImg src="assets/images/mail.svg" /></a> 
            </div>
            </div>
            <div className="col-md-4 d-flex align-items-end justify-content-end">  
              <IonImg className="logoans" src="assets/images/logo-ANS.svg" />
              <IonLabel className="textright">CNES: {CNES}</IonLabel></div>

          </div>

        </div>



      </div>
    </>
  );
};
export default AppFooter;

