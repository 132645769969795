import styled from "styled-components";

export const UserEmail = styled.div` 
  background: #F5F5F5;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 5px;
  display: block;
  padding: 12px 12px;
  margin-bottom: 35px;
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiFormHelperText-filled {
    color: #CB4848;
  }
  img {
    margin-right: 10px;
  }
`;