import { IonAvatar, IonButton, IonProgressBar, IonCard, IonContent, IonImg, IonItem, IonLabel, IonList, IonPage, useIonToast, getPlatforms, IonGrid, IonRow, IonCol, IonInput, IonSegment, IonSegmentButton, useIonViewDidEnter } from '@ionic/react'
import React, { useEffect, useState, MouseEvent } from 'react'
import './Medicine.scss'
import moment from "moment";
import Header from '../../components/header/Header'
import MobileHeader from '../../components/mobileHeader/mobileHeader'
import AppFooter from '../../components/appfooter/AppFooter'
import MobileFooter from '../../components/mobileFooter/mobilefooter'
import axios from 'axios'
import 'moment-timezone';
import SideBar from '../../components/sidebar/Sidebar'
import { format, DownloadFile } from "../../helper/Common";
import { Share } from "@capacitor/share";
import StoreService from '../../services/store.service'
import platform from '../../helper/platform';
import HeaderNav from '../../components/headernav/HeaderNav';
import NoDataComponent from '../../components/noDataComp/NoDataComponent'
import { RWebShare } from "react-web-share";
const Medicine: React.FC<any> = (props) => {
    const [medicine, setMedicine] = useState<any>(null);
    const [priority, setPriority] = useState<any>('validas')
    const [isDetails, setIsDetails] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<any>(false);
    const [present, dismiss] = useIonToast();
    // const { Share } = Plugins;
    const [userData, setUserData] = useState(null)
    useEffect(() => { getMedicine() }, [props.location.key])
    useIonViewDidEnter(() => {

        StoreService.get('user').then(res => {
            if (res) {
                if (!res?.terms_of_use || !res?.privacy_policy) {
                    props.history.push("/privacy-policy");
                  }
                getMedicine();
                setUserData(res);
                return
            } else {
                props.history.push("/login");
            }
        })
    });
    const getMedicine = async () => {
        setIsLoading(true)
        // const user: any = { user_id: 'HGxaNdsslU' }
        const user = await StoreService.get("user");
        const URL = format(`${process.env.REACT_APP_API_BASE_URL}/doctor/patient/health_plan/{0}/prescriptions`, [user?.id]);
        axios.get(`${URL}?slug=ampla-saude`).then((resp: any) => {
            setIsLoading(false)
            setMedicine(resp.data.data)
        }).catch((error) => {
            setIsLoading(false)
            console.log("error medicine", error);
        })
    }
    const dateFormat = (date: any) => moment(date).format("DD/MM/YYYY");


    const Download = (entity: any, event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        present({ message: "Download iniciado", duration: 1000 });
        DownloadFile(
            entity.file,
            "ok",
            () => present({ message: "Arquivo baixado", duration: 2000 }),
            () => present({ message: "Erro ao baixar o arquivo", duration: 2000 })
        );
    };


    const shareOption = async (entity: any, event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        await navigator.share({
            title: entity?.name,
            text: dosage(entity),
            url: entity?.file
        })
        // await Share.share({
        //     title: entity?.name,
        //     text: dosage(entity),
        //     url: entity?.file,
        //     dialogTitle: "Share with",
        // });
    };


    const dosage = (entity: any) =>
        entity?.frequencyTime === "hours"
            ? `${entity?.frequency}x ao dia durante ${entity?.frequency} horas`
            : `1x ao dia durante ${entity?.frequency} dias`;

    const goBack = () => {
        props.history.back()
    }
    return (
        <IonPage>
            <IonContent>
                {/* <Header props={props} routeName="medicine" /> */}
                {getPlatforms().includes("desktop") ?
                    <Header props={props} routeName="medicine" />
                    :
                    <MobileHeader props={props} />
                }

                <div className="medicine-mainwrapper">

                    <div className="rightcontent">

                        <div className="container">
                            {getPlatforms().includes("desktop") ?
                                <div className="header-content">
                                    <IonLabel className="medicinetopsubtitle" >Olá, {userData?.name}</IonLabel>
                                    {/*  <IonImg slot="start" className="title-img-view hide-on-desktop" src="assets/images/side-mobile-medicamentos.svg" /> */}
                                    <h2 className="medicine-title">Meus Medicamentos</h2>
                                </div>
                                :
                                <div className="header-content">
                                    <IonImg className="medicine-main-title-arrow-left" src='assets/images/arrow-left.svg' onClick={() => goBack()} />

                                    <IonLabel className="medicine-topsubtitle-mobile">Meus medicamentos</IonLabel>
                                </div>
                            }

                            <div className="row">

                                {getPlatforms().includes("desktop") ?
                                    <div className="col-md-4 d-flex align-items-center">

                                        <IonImg src="assets/images/medicine-frame.svg" />
                                    </div>
                                    :
                                    ""
                                }

                                <div className="medicine-layoutview">

                                    <div>

                                    </div>
                                    {isLoading &&
                                        (
                                            <IonProgressBar type="indeterminate" className="ion-margin-bottom" />
                                        )}
                                    <IonSegment className="header-tab-view" value={priority} onIonChange={e => setPriority(e.detail.value)}>
                                        <IonSegmentButton value="validas" defaultChecked={true}>
                                            <IonLabel>Válidas</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="orientacoes">
                                            <IonLabel>Orientações</IonLabel>
                                        </IonSegmentButton>
                                        <IonSegmentButton value="antigas">
                                            <IonLabel>Antigas</IonLabel>
                                        </IonSegmentButton>
                                    </IonSegment>


                                    {priority == "antigas" && (
                                        medicine?.antigas?.length > 0 ? (
                                            <div className="medicine-container">

                                                <IonList className="medicne-list-content">
                                                    {medicine?.antigas.length > 0 ? (
                                                        medicine?.antigas.map((entity: any, entityKey: any) => {
                                                            return (
                                                                <IonCard className="medicine-card-antigas-content" key={entityKey}>
                                                                    <div className="medicine-content-box-antigas">
                                                                        <div>
                                                                            <IonImg src='assets/images/antigas-pill.svg' />
                                                                        </div>
                                                                        <div className="medicine-box-antigas">
                                                                            <div className="medicine-detail-content-antigas">
                                                                                <div className="date-label-antigas">
                                                                                    <IonLabel className="detail-validas-date-txt">{dateFormat(entity?.start)}</IonLabel>
                                                                                </div>
                                                                                <div className="medicine-visuals" onClick={() => window.open(entity?.file)}>
                                                                                    <IonImg src='assets/images/antigas-visuals.svg' />
                                                                                    <IonLabel className="detail-label-antigas">Visualizar</IonLabel>
                                                                                </div>
                                                                            </div>
                                                                            <div className="middle-antigas">
                                                                                <IonLabel>{entity?.name} {entity?.dosage}{" "} {entity?.dosageMeasure} </IonLabel>
                                                                            </div>
                                                                            <div className="lastsize-antigas">
                                                                                <IonLabel>{dosage(entity)} </IonLabel>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </IonCard>
                                                            )
                                                        })
                                                    ) : (<IonLabel></IonLabel>)}
                                                </IonList>
                                            </div>

                                        ) : (
                                            <NoDataComponent text="Não há medicamentos disponíveis no momento" />
                                        )

                                    )}

                                    {priority == "orientacoes" && (
                                        medicine?.guidelines?.length > 0 ? (
                                            <div className="medicine-container">

                                                <IonList className="medicne-list-content">
                                                    {medicine?.guidelines.length > 0 ? (
                                                        medicine?.guidelines?.map((entity: any, entityKey: any) => {
                                                            return (
                                                                <IonCard className="medicine-card-content" key={entityKey} >
                                                                    <div className="medicine-content-box">
                                                                        <div>
                                                                            <IonImg src='assets/images/medicine-orientico.svg' />
                                                                        </div>
                                                                        <div className="medicine-box">
                                                                            <div className="medicine-detail-content">
                                                                                <div>
                                                                                    <IonLabel className="detail-validas-date-txt">{dateFormat(entity?.start)}</IonLabel>
                                                                                </div>
                                                                                <div className="medicine-visuals" onClick={() => window.open(entity?.file)}>
                                                                                    <IonImg src='assets/images/medicine-visuals.svg' />
                                                                                    <IonLabel className="detail-label">Visualizar</IonLabel>
                                                                                </div>
                                                                            </div>
                                                                            <div className="middle">
                                                                                <IonLabel>{entity?.name} {entity?.dosage}{" "} {entity?.dosageMeasure} </IonLabel>
                                                                            </div>
                                                                            <div className="lastsize">
                                                                                <IonLabel> {dosage(entity)}</IonLabel>
                                                                            </div>
                                                                            <div className="footerbott">
                                                                                <hr className='hr-content' />
                                                                                <div className="action-content">
                                                                                    <a className="action-label" onClick={(e) => Download(entity, e)}>
                                                                                        Download
                                                                                        <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                                                    </a>
                                                                                    {getPlatforms().includes('desktop') ?
                                                                                        <RWebShare
                                                                                            data={{
                                                                                                title: entity?.name,
                                                                                                text: dosage(entity),
                                                                                                url: entity?.file
                                                                                            }}
                                                                                            onClick={() => console.log("shared successfully!")}
                                                                                        >
                                                                                            <a className="action-label">
                                                                                                Enviar receita
                                                                                                <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                                                            </a>
                                                                                        </RWebShare>
                                                                                        :
                                                                                        <a className="action-label" onClick={(e) => shareOption(entity, e)}>
                                                                                            Enviar receita
                                                                                            <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                                                        </a>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </IonCard>

                                                            )

                                                        })
                                                    ) : (<IonLabel></IonLabel>)}
                                                </IonList>
                                            </div>
                                        ) : (
                                            <NoDataComponent text="Não há medicamentos disponíveis no momento" />
                                        )
                                    )}


                                    {priority == "validas" && (
                                        medicine?.novas?.length > 0 ? (
                                            <div className="medicine-container">
                                                <IonList className='medicne-list-content'>
                                                    {medicine?.novas?.length > 0 ? (
                                                        medicine?.novas?.map((entity: any, entityKey: any) => {
                                                            return (
                                                                <IonCard className="medicine-card-content" key={entityKey}>
                                                                    <div className="medicine-content-box">
                                                                        <div>
                                                                            <IonImg src='assets/images/medicine.svg' />
                                                                        </div>
                                                                        <div className="medicine-box">
                                                                            <div className="medicine-detail-content">
                                                                                <div>
                                                                                    <IonLabel className="detail-validas-date-txt">{dateFormat(entity?.start)}</IonLabel>
                                                                                </div>
                                                                                <div className="medicine-visuals" onClick={() => window.open(entity?.file)}>
                                                                                    <IonImg src='assets/images/medicine-visuals.svg' />
                                                                                    <IonLabel className="detail-label">Visualizar</IonLabel>
                                                                                </div>
                                                                            </div>
                                                                            <div className="middle">
                                                                                <IonLabel>{entity?.name} {entity?.dosage}{" "} {entity?.dosageMeasure}</IonLabel>
                                                                            </div>
                                                                            <div className="lastsize">
                                                                                <IonLabel>{dosage(entity)}</IonLabel>
                                                                            </div>
                                                                            <div className="footerbott">
                                                                                <hr className='hr-content' />
                                                                                <div className="action-content">
                                                                                    <a className="action-label" onClick={(e) => Download(entity, e)}>
                                                                                        Download
                                                                                        <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                                                    </a>
                                                                                    {getPlatforms().includes('desktop') ?
                                                                                        <RWebShare
                                                                                            data={{
                                                                                                title: entity?.name,
                                                                                                text: dosage(entity),
                                                                                                url: entity?.file
                                                                                            }}
                                                                                            onClick={() => console.log("shared successfully!")}
                                                                                        >
                                                                                            <a className="action-label">
                                                                                                Enviar receita
                                                                                                <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                                                            </a>
                                                                                        </RWebShare>
                                                                                        :
                                                                                        <a className="action-label" onClick={(e) => shareOption(entity, e)}>
                                                                                            Enviar receita
                                                                                            <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                                                        </a>
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </IonCard>
                                                            )
                                                        })
                                                    ) : (<IonLabel></IonLabel>)}
                                                </IonList>
                                            </div>
                                        ) : (
                                            <NoDataComponent text="Não há medicamentos disponíveis no momento" />
                                        )
                                    )}
                                </div>


                            </div>

                        </div>



                    </div>
                </div>

                {/* <AppFooter /> */}
                {getPlatforms().includes("desktop") ?
                    <AppFooter />
                    :
                    <MobileFooter props={props} />
                }
            </IonContent>
        </IonPage>
    )
}
export default Medicine




{/* {priority == "antigas" && (
                                <div className="medicine-container">
                                    <IonList className="medicne-list-content">
                                        <IonCard className="medicine-card-antigas-content">
                                            <div className="medicine-content-box-antigas">
                                                <div>
                                                    <IonImg src='assets/images/antigas-pill.svg' />
                                                </div>
                                                <div className="medicine-box-antigas">
                                                    <div className="medicine-detail-content-antigas">
                                                        <div className="date-label-antigas">
                                                            <IonLabel className="detail-validas-date-txt">20/12/2021</IonLabel>
                                                        </div>
                                                        <div className="medicine-visuals">
                                                            <IonImg src='assets/images/antigas-visuals.svg' />
                                                            <IonLabel className="detail-label-antigas">Visualizar</IonLabel>
                                                        </div>
                                                    </div>
                                                    <div className="middle-antigas">
                                                        <IonLabel>Parecetamol 750mg</IonLabel>
                                                    </div>
                                                    <div className="lastsize-antigas">
                                                        <IonLabel>1x ao dia durante 10 dias</IonLabel>
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCard>
                                        <IonCard className="medicine-card-antigas-content">
                                            <div className="medicine-content-box-antigas">
                                                <div>
                                                    <IonImg src='assets/images/antigas-advice.svg' />
                                                </div>
                                                <div className="medicine-box-antigas">
                                                    <div className="medicine-detail-content-antigas">
                                                        <div className="date-label-antigas">
                                                            <IonLabel className="detail-validas-date-txt">20/12/2021</IonLabel>
                                                        </div>
                                                        <div className="medicine-visuals">
                                                            <IonImg src='assets/images/antigas-visuals.svg' />
                                                            <IonLabel className="detail-label-antigas">Visualizar</IonLabel>
                                                        </div>
                                                    </div>
                                                    <div className="middle-antigas">
                                                        <IonLabel>Parecetamol 750mg</IonLabel>
                                                    </div>
                                                    <div className="lastsize-antigas">
                                                        <IonLabel>1x ao dia durante 10 dias</IonLabel>
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCard>
                                    </IonList>
                                </div>
                            )} */}
{/* {priority == "orientacoes" && (
                                <div className="medicine-container">
                                    <IonList className="medicne-list-content">
                                        <IonCard className="medicine-card-content" >
                                            <div className="medicine-content-box">
                                                <div>
                                                    <IonImg src='assets/images/medicine-orientico.svg' />
                                                </div>
                                                <div className="medicine-box">
                                                    <div className="medicine-detail-content">
                                                        <div>
                                                            <IonLabel className="detail-validas-date-txt">20/12/2021</IonLabel>
                                                        </div>
                                                        <div className="medicine-visuals">
                                                            <IonImg src='assets/images/medicine-visuals.svg' />
                                                            <IonLabel className="detail-label">Visualizar</IonLabel>
                                                        </div>
                                                    </div>
                                                    <div className="middle">
                                                        <IonLabel>Parecetamol 750mg</IonLabel>
                                                    </div>
                                                    <div className="lastsize">
                                                        <IonLabel> 1x ao dia durante 10 dias</IonLabel>
                                                    </div>
                                                    <div className="footerbott">
                                                        <hr className='hr-content' />
                                                        <div className="action-content">
                                                            <a className="action-label" >
                                                                Download
                                                                <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                            </a>
                                                            <a className="action-label" >
                                                                Enviar receita
                                                                <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCard>
                                    </IonList>
                                </div>
                            )} */}
{/* {priority == "validas" && (

                                <div className="medicine-container">
                                    <IonList className='medicne-list-content'>

                                        <IonCard className="medicine-card-content"  >
                                            <div className="medicine-content-box">
                                                <div>
                                                    <IonImg src='assets/images/medicine.svg' />
                                                </div>
                                                <div className="medicine-box">
                                                    <div className="medicine-detail-content">
                                                        <div>
                                                            <IonLabel className="detail-validas-date-txt">20/12/2021</IonLabel>
                                                        </div>
                                                        <div className="medicine-visuals">
                                                            <IonImg src='assets/images/medicine-visuals.svg' />
                                                            <IonLabel className="detail-label">Visualizar</IonLabel>
                                                        </div>
                                                    </div>
                                                    <div className="middle">
                                                        <IonLabel>Parecetamol 750mg</IonLabel>
                                                    </div>
                                                    <div className="lastsize">
                                                        <IonLabel>1x ao dia durante 10 dias </IonLabel>
                                                    </div>
                                                    <div className="footerbott">
                                                        <hr className='hr-content' />
                                                        <div className="action-content">
                                                            <a className="action-label" >
                                                                Download
                                                                <IonImg className="sub-icon" src='assets/images/medicine-download.svg' />
                                                            </a>
                                                            <a className="action-label" >
                                                                Enviar receita
                                                                <IonImg className="sub-icon" src='assets/images/medicine-share.svg' />
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </IonCard>
                                    </IonList>
                                </div>

                            )} */}