import { getPlatforms } from "@ionic/react";
import _ from "lodash";

export const range = (n: number, start = 0) => {
  return Array.from({ length: n }, (_, index) => start + index);
};

export const format = (text: string, values: Array<string>) => {
  var args = values;
  return text.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != "undefined" ? args[number] : match;
  });
};

export const DownloadFile = (
  link: string,
  filename = "",
  successCB?: Function,
  errorCB?: Function
) => {
  const fileChunk = _.split(link, "/");
  filename = _.last(fileChunk) || "";

  fetch(link).then(function (t) {
    return t.blob().then(
      (b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
        successCB && successCB();
      },
      (error) => {
        errorCB && errorCB();
      }
    );
  });
};

export const CNES = "0969052";

export const formatWeekDays = (weekNum: any) => {
  var weekday = new Array(7);
  weekday[0] = "domingo";
  weekday[1] = "segunda";
  weekday[2] = "terça";
  weekday[3] = "quarta";
  weekday[4] = "quinta";
  weekday[5] = "sexta";
  weekday[6] = "sábado";
  return weekday[weekNum];
};

export const isDesktop = getPlatforms().includes("desktop");