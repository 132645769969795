import { IonProgressBar } from "@ionic/react";
import React from "react";

interface Props {
    loading?: boolean
}

const Loader: React.FC<Props> = ({ loading }) => {
  return (
    <>
      {loading && (
        <IonProgressBar type="indeterminate" className="ion-margin-bottom fixed-loader" />
      )}
    </>
  );
};

export default Loader;
