import { IonImg, IonLabel, IonModal } from "@ionic/react";
import React from "react";
import "../../assets/styles/base.scss"
import { Button } from "../UI/Button";
import Loader from "../UI/Loader";
import { ModalWrapper, SubText, SubTexts, Text } from "./styles";
interface Props {
  isOpen?: boolean;
  setIsOpen?: any;
  text?: string;
  subTexts?: string;
  subText?: any;
  btnSim?: string;
  handleClick?: any;
  btnCancelText?: string;
  btnCancelClick?: any;
  isSignIn?: boolean;
  loading?: boolean;
  children?: any;
  btnSimColor?: string;
  btnCancelColor?: string;
  btnSimFill?: string;
  btnCancelFill?: string;
  btnImg?: string;
  termsclass?: any;
  subtext1?: any;
  subtext2?: any;
  setPassword?
  ImgSrc?: any,
  btnCol?: boolean,
  isOnDidmiss?: boolean,
  backdropDismiss?: any,
  onDidmiss?: any,
  redirect?: any;
  modalType?: any;
  data?: any;
  modalAccecptDeny?: string;
}

const updateKey = {
  terms_of_use: "termos",
  privacy_policy: "privacidade",
  both: "termos e privacidade.",
};

const LinkText = ({ data, text, type }) => {
  data = data?.find((d) => d.typeOfTerm === type);
  return (
    <span
      onClick={() => window.open(data?.termFilePDF, "_blank")}
      className="link-text"
    >
      {text}
    </span>
  );
};

const Modal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  text = "",
  subText = "",
  subTexts = "",
  subtext1 = "",
  subtext2 = "",
  btnSim = "",
  handleClick,
  btnCancelText = "",
  btnCancelClick,
  isSignIn = false,
  loading = false,
  children,
  btnSimColor = "danger",
  btnSimFill = "solid",
  btnCancelColor = "danger",
  btnCancelFill = "solid",
  btnImg = "",
  termsclass = "",
  setPassword,
  ImgSrc = "",
  btnCol = false,
  isOnDidmiss = false,
  onDidmiss,
  backdropDismiss = true,
  redirect,
  modalType,
  data,
  modalAccecptDeny=""
}) => {
  return (
    <ModalWrapper>
      <IonModal
        isOpen={isOpen}
        cssClass={termsclass ? "termsclass custom-modal" : "custom-modal"}
        onDidDismiss={() => {
          if (isOnDidmiss) {
            onDidmiss();
          } else {
            setIsOpen && setIsOpen(false);
            setPassword && setPassword("");
          }
        }}
        backdropDismiss={backdropDismiss}
      >
        {children ? (
          children
        ) : (
          <div className="terms-content-box">
            {!isSignIn && (
              <div>
                <Text className="text-center d-block">{text}</Text>
                {btnImg && (
                  <IonImg className="true-img" src={btnImg} />
                )}
                {(modalAccecptDeny === "deny") && (
                <span className="logout-title">
                  Atenção!
                </span>)}
                {(modalAccecptDeny === "accept") && (
                <span className="logout-title">
                  Atualização de {updateKey[modalType]}
                {/* </span>)} <br/> */}
                </span>)} <br/>
                <SubText className="font-bold">{subText} {subtext1 && <span className="subtext-iron">{subtext1}</span>}{subtext2 && <span className="subtext-health">{subtext2}</span>}</SubText>
                
                <span className="font-bold">
                      {modalAccecptDeny  === "accept" &&
                      <span>Caro usuário, nós atualizamos os nossos{" "}</span>}
                      {modalAccecptDeny  === "deny" &&
                      <span>Os{" "}</span>}
                      {(modalType === "terms_of_use" || modalType === "both") && (
                        <LinkText
                          data={data}
                          type="terms_of_use"
                          text={"Termos de adesão"}
                        />
                      )}
                      {modalType === "both" && " e "}
                      {(modalType === "privacy_policy" || modalType === "both") && (
                        <LinkText
                          data={data}
                          type="privacy_policy"
                          text={"Política de Privacidade"}
                        />
                      )}
                      {modalType && <> {"."} <br /></>}
                      {(modalAccecptDeny === "accept") &&
                     <span> Para continuar usando nossa plataforma, leia os novos{" "}
                      {updateKey[modalType]} e, se concordar, clique em "concordo".
                      </span>
                      }
                      {(modalAccecptDeny === "deny") &&
                     <span> são obrigatórios para continuar usando nossa plataforma. Se você
                     discordar dos novos {updateKey[modalType]}, sua conta será desativada.
                     Você tem certeza que quer continuar?
                      </span>
                      }
            </span>
                {subTexts && <SubTexts>{subTexts}</SubTexts>}
                <IonLabel className={`${btnCol ? "text-center btnsdls" : "d-flex align-items-center justify-content-center btnsdls"}`}>
                  {btnSim && (
                    <Button
                      className="orange-btn w-100"
                      color={btnSimColor}
                      fill={btnCancelFill}
                      text={btnSim}
                      handleClick={handleClick}
                      ImgSrc={ImgSrc}
                      imgSlot="end"
                      imgClassName="ms-2"
                    />
                  )}
                  {btnCancelText && (
                    <Button
                      className="orange-btn w-100"
                      fill={btnSimFill}
                      color={btnCancelColor}
                      text={btnCancelText}
                      handleClick={btnCancelClick}

                    />
                  )}
                </IonLabel>
              </div>
            )}
            {isSignIn && (
              <>
                <Text className="text-center d-block">{text}</Text>
                <SubText>{subText}</SubText>
                <Loader loading={loading} />
              </>
            )}
          </div>
        )}
      </IonModal>
    </ModalWrapper>
  );
};

export default Modal;
