import {
  IonPage,
  IonContent,
  IonLabel,
  IonImg,
  IonProgressBar,
  IonList,
  IonItem,
  IonDatetime,
  IonButton,
  useIonViewDidEnter,
  IonInput,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import StoreService from "../../services/store.service";
import "./Login.scss";
import { Formik } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";
import moment from "moment";
import { Zendesk } from "../../helper/Zendesk";

const Login: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState<any>(false);
  const [loginError, setLoginError] = useState<any>("");
  const [signupData, setSignupData] = useState<any>();

  useIonViewDidEnter(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    StoreService.get("user").then((res) => {
      if (res) {
        return props.history.push("/home");
      } else {
        return props.history.push("/login");
      }
    });
    StoreService.get("signup")
      .then(res => setSignupData(res))
      .catch(e => {})
  });

  useEffect(() => {
    return () => {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener("popstate", function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    };
  }, [props.history]);

  const logIn = (values: any, setSubmittng: any, resetForm: any) => {
    setIsLoading(true);
    const cpf = values?.cpf?.replace(/-|_|\./g, "");
    const birthdate: any = moment(new Date(values.dob))
      .utc()
      .format("YYYY-MM-DD");
    const data: any = {
      birthdate,
      cpf,
      //   corporation: "ampla-saude",
    };
    axios
      .post(
        `${process.env.REACT_APP_API_PARSE_BASE_URL}auth/plansaude/login`,
        data
      )
      .then(async (resp: any) => {
        if (resp.status == 200) {
          setIsLoading(false);
          resetForm();
          const {
            data: {
              result: { user },
            },
          } = resp;
          const isPolicyAccepted: boolean = await acceptPrivacyTerms(user)
          let users = { ...user};
          if(isPolicyAccepted) users = {...user, terms_of_use: true, privacy_policy: true}
          await StoreService.set("user_cpf", cpf);
          await StoreService.set("user", users);
          await StoreService.set("user_id", users.user_id);
          if (!users?.terms_of_use && !users?.privacy_policy) {
            // for routing in terms and policies page
            await StoreService.set("new_user", "true");
            props?.history.push("/privacy-policy")
          } else if (!users?.privacy_policy) props.history.push("/privacy-policy-info")
          else if (!users?.terms_of_use) props.history.push("/terms-of-user");
          else {
            props.history.push("/home");
          }
        }
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.error("logn error", error.response);
        setLoginError("Detalhes do usuário inválidos");
      });
  };

  const acceptPrivacyTerms = async (user) => {
    try {
      const policyData = await StoreService.get("policyData");
      if (policyData) {
        const data: any = {
          patientId: user?.user_id,
          acceptTermIds: JSON.parse(policyData),
          corporationId: user?.corporationId
        };
        axios
          .post(
            `${process.env.REACT_APP_API_PARSE_BASE_URL}terms-and-conditions/accept-revoke`,
            data
          )
          .then(async (res: any) => {
            if (res?.data?.message === "Success!") {
              await StoreService.set("policyData",null);
            }
          })
          .catch(async(error: any) => {
            await StoreService.set("policyData",null);
          });
        return true
      }
      return false
    } catch(e) { return false}
  }

  return (
    <IonPage>
      <IonContent className="login-content">
        <div className="mobiletransparat d-md-none"></div>
        <div className="login-header py-1 d-none d-md-block">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center">
                <IonImg
                  className="logo"
                  src="assets/images/amplasauudelogo.svg"
                />
                <IonLabel className="">Seu médico sem sair de casa</IonLabel>
              </div>
            </div>
          </div>
        </div>
        <div className="middlecontent">
          <div className="container">
            <div className="row">
              <div className="col-md-6 d-flex align-items-center">
                <div className="centerdiv">
                  <div className="leftcontent">
                    <div className="d-md-none mobilelogo text-center">
                      <IonImg
                        className="header-logo-login-mobile"
                        src="/assets/images/amplasauudelogo.svg"
                      />
                    </div>
                    <IonLabel className="headingotop">
                      e aí, tudo certo?
                    </IonLabel>
                    <IonLabel className="subtitle">
                      Essa é a nossa telemedicina, por aqui você faz as suas
                      consultas e não precisa se preocupar com filas e nem mesmo
                      sair do conforto da sua casa
                    </IonLabel>
                    <IonLabel className="description">
                      Pra acessar a plataforma é só preencher os campos a seguir
                      com as credenciais que nós te enviamos.
                    </IonLabel>
                  </div>
                  <div className="rightcontent">
                    <Formik
                      initialValues={{
                        cpf: signupData?.cpf || "",
                          dob: moment.utc(signupData?.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
                      }}
                      enableReinitialize={true}
                      validationSchema={Yup.object().shape({
                        cpf: Yup.string().required("cpf é obrigatória"),
                        dob: Yup.date()
                          .required("A data de nascimento é obrigatória")
                          .max(
                            new Date(),
                            "A data de nascimento não pode ser uma data futura."
                          ),
                      })}
                      onSubmit={(values, { setSubmitting, resetForm }) => {
                        logIn(values, setSubmitting, resetForm);
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit} noValidate>
                          <div className="middleform">
                            <div className="form-group listinput">
                              <IonLabel
                                className="login-label"
                                position="stacked"
                              >
                                CPF
                              </IonLabel>
                              <MaskedInput
                                className="login-input"
                                mask={[
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ".",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  ".",
                                  /\d/,
                                  /\d/,
                                  /\d/,
                                  "-",
                                  /\d/,
                                  /\d/,
                                ]}
                                value={values.cpf}
                                placeholder="000.000.000-00"
                                type="tel"
                                name="cpf"
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />

                              {/* <IonInput className="login-input" placeholder="000.000.000-00" type="text" name="cpf" value={values.cpf} onIonChange={handleChange} onIonBlur={handleBlur}> </IonInput> */}
                              <span className="custom-error-label">
                                {errors.cpf && touched.cpf && errors.cpf}
                              </span>
                            </div>
                            <div className="form-group listinput">
                              <IonLabel
                                className="login-label"
                                position="stacked"
                              >
                                Data de nascimento
                              </IonLabel>
                              {/* <IonDatetime
                                className="login-input"
                                displayFormat="DD MMMM YYYY"
                                value={values.dob}
                                onBlur={handleBlur}
                                name="dob"
                                placeholder="00 / 00 / 0000"
                                onIonChange={handleChange}
                                monthNames="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"
                                monthShortNames="Jan, Fev, Março, Abril, Maio, Junho, Julho, Agosto, Set, Out, Nov, Dez"
                                doneText="SALVAR"
                                cancelText="CANCELAR"
                              /> */}
                              <IonInput
                                max="9999-12-31"
                                pattern="DD/MM/YYYY"
                                className="login-input unstyled"
                                type="date"
                                name="dob"
                                value={values.dob}
                                onKeyPress={() => setLoginError("")}
                                onIonChange={handleChange}
                                onBlur={handleBlur}
                              >
                                {" "}
                              </IonInput>
                              <span className="custom-error-label">
                                {errors.dob && touched.dob && errors.dob}
                              </span>
                              <span className="custom-error-label">
                                {!errors.dob && loginError}
                              </span>
                            </div>
                            {isLoading && (
                              <IonProgressBar type="indeterminate" />
                            )}
                            <div className="form-group">
                              <IonButton
                                expand="full"
                                shape="round"
                                size="large"
                                className="login-btn"
                                type="submit"
                              >
                                Entrar
                              </IonButton>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <IonImg
                  src="/assets/images/login-rightimg.png"
                  className="loginimg d-none d-md-block"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="login-footer py-2">
          <div className="container">
            <div className="row">
              <div className="d-flex align-items-center innerfooter">
                <IonImg
                  src="assets/images/chat-icon.svg"
                  onClick={() => Zendesk()}
                />{" "}
                <IonLabel className="ml-4 footer-label">
                  Plataforma Iron Telemedicina
                </IonLabel>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
export default Login;
