import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonLabel, IonPage, IonRow, IonText, getPlatforms, useIonViewDidEnter
} from "@ionic/react";
import axios from 'axios';
import React, { useState } from "react";
import { Button } from "../../components/UI/Button";
import LeftGrid from "../../components/UI/LeftGrid";
import AppFooter from "../../components/appfooter/AppFooter";
import Header from "../../components/header/Header";
import MobileFooter from "../../components/mobileFooter/mobilefooter";
import MobileHeader from "../../components/mobileHeader/mobileHeader";
import StoreService from "../../services/store.service";
import "./privacypolicy.scss";
import storeService from "../../services/store.service";

const PrivacyPolicy: React.FC<any> = (props) => {

  const [user, setUser] = useState<any>(null);
  const [userTerms, setUserTerms] = useState<any>(null);
  let idData = []
  useIonViewDidEnter(async () => {
    storeService.get("user").then((res) => {
      if (res) {
        if(res?.terms_of_use && res?.privacy_policy){
          props.history.replace("/home");
        }
        setUser(res);
      }
    })
    await StoreService.set("terms", false);
    await StoreService.set("privacy", false);
    getAllTermsPrivacyList();
  });

  const navigateToTermsOfUse = () => {
    props?.history?.push("/terms-of-user")
  };

  /**
  * Function for accecpt Terms & condition List
  */
  const getAllTermsPrivacyList = async () => {
    await axios.get(`${process.env.REACT_APP_API_BASE_URL}/terms-and-conditions/list`).then((resp: any) => {
      idData = resp?.data?.terms?.map((index: any) => index?._id)
      setUserTerms(idData);
      StoreService.set("policyData", JSON.stringify(idData));

    }).catch((err: any) => {
      console.log("List data error===", err);
    })
  }

  const navigateToHome = async () => {
    const data: any = {
      patientId: user?.user_id,
      acceptTermIds: userTerms,
      corporationId: user?.corporationId
    };
    axios
      .post(
        `${process.env.REACT_APP_API_PARSE_BASE_URL}/terms-and-conditions/accept-revoke`,
        data
      )
      .then(async (res: any) => {
        if (res?.data?.message === "Success!") {
          await storeService.set("terms", true);
          await storeService.set("privacy", true);
          let user = await storeService.get("user");
          user = { ...user, terms_of_use: true, privacy_policy: true };
          await storeService.set("user", user);
          props.history.replace("/home");
        }
      })
      .catch((error: any) => {
        console.log("errorInCalling", error);
      });
  };

  return (
    <IonPage>
      <IonContent className="login-content">
        {getPlatforms().includes("desktop") ? (
          <Header props={props} routeName="home" />
        ) : (
          <MobileHeader props={props} />
        )}
        <IonGrid className="privacy-page">
          <IonRow className="container align-items-center">
            <IonCol size="12" sizeLg="6" className="privacy-img">
              <LeftGrid src={"/assets/images/privacyImg.svg"} backIcon={false} />
            </IonCol>
            <IonCol size="12" sizeLg="6">
              <div className="privacy-content">
                <IonLabel className="sc-ion-label-md-h sc-ion-label-md-s md hydrated">Política de Privacidade</IonLabel>
                <IonText className="d-block">
                  Ao continuar você concorda com os{" "}
                  <a href="/terms-of-user">Termos de Uso </a> e a{" "}
                  <a href="/privacy-policy-info">Política de Privacidade</a> da
                  <strong> Ampla Saude.</strong>
                </IonText>

                <IonRow className="">
                  <IonCol sizeMd="12" size="12" className="mb-2">
                    <Button
                      imgSlot="end"
                      ImgSrc={"/assets/images/checkicon.svg"}
                      className="orange-btn w-100"
                      color="primary"
                      expand="block"
                      fill="solid"
                      text="Concordar e continuar"
                      handleClick={navigateToHome}
                    />
                  </IonCol>
                  <IonCol sizeMd="12" size="12">
                    <Button
                      className="button-color"
                      color="danger"
                      fill="outline"
                      text="Saiba mais"
                      handleClick={navigateToTermsOfUse}
                    />
                  </IonCol>

                </IonRow>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      {getPlatforms().includes("desktop") ? (
        <AppFooter />
      ) : (
        <MobileFooter props={props} />
      )}
    </IonPage>
  );
};

export default PrivacyPolicy;
