import * as React from 'react'
import moment from 'moment'
import { padStart } from 'lodash'

export function formatMinutes(ms: number) {
  const duration = moment.duration(ms, 'milliseconds')
  const signal = ms < 0 ? '-' : ''
  const minutes = Math.floor(duration.asMinutes())
  const seconds = Math.floor((duration.asMinutes() - minutes) * 60)
  return signal + [padStart(minutes.toString(), 2, '0'), padStart(seconds.toString(), 2, '0')].join(':')
}

export const MinutesTimer: React.FC<{ startTime: number }> = ({ startTime }) => {
  const [nowTicker, setNowTicker] = React.useState(Date.now())
  React.useEffect(() => {
    const nowTickerIntervalId = setInterval(() => {
      setNowTicker(Date.now())
    }, 1000)
    return () => clearInterval(nowTickerIntervalId)
  }, [setNowTicker])

  const duration = React.useMemo(() => {
    const diff = moment().diff(startTime)
    const duration = moment.duration(diff < 0 ? diff * -1 : diff)
    const signal = diff < 0 ? '-' : ''
    const minutes = Math.floor(duration.asMinutes())
    const seconds = Math.floor((duration.asMinutes() - minutes) * 60)
    return signal + [padStart(minutes.toString(), 2, '0'), padStart(seconds.toString(), 2, '0')].join(':')
  }, [startTime, nowTicker])

  return (<>{duration}</>)
}
