import { IonButton, IonIcon } from "@ionic/react";
import React from "react";

type ButtonProps = {
  handleClick?: any;
  text?: string;
  ImgSrc?: string;
  className?: string;
  imgClassName?: string;
  shape?: any;
  fill?: any;
  expand?: any;
  color?: any;
  disabled?: boolean;
  imgSlot?: string;
  id?: any
};

export const Button: React.FC<ButtonProps> = ({
  handleClick,
  text,
  ImgSrc,
  className,
  imgClassName,
  shape,
  fill,
  expand,
  color,
  disabled = false,
  imgSlot = "start",
  id
}) => {

  return (
    <IonButton
      id={id}
      shape={shape}
      color={color}
      fill={fill}
      className={`${fill === "outline" ? "outline" : "solid"} ${className}`}
      disabled={disabled}
      onClick={() => {
        handleClick && handleClick()
      }}
      mode="md"
    >
      {ImgSrc && imgSlot === "start" ? <IonIcon src={ImgSrc} className={imgClassName} /> : null}
      {text}
      {ImgSrc && imgSlot === "end" ? <IonIcon src={ImgSrc} className={imgClassName} /> : null}
    </IonButton>
  );
};
