import styled from "styled-components";





export const PatientName = styled.div`
font-weight: 700;
font-size: 20px;
line-height: 135.5%;
color: var(--darkblue);
`;

export const PatientTitle = styled.div`
font-weight: 400;
font-size: 26px;
line-height: 30px;
color: var(--darkgray2);
margin-top:0.4rem;
`;
