import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCol,
  IonContent,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonProgressBar,
  IonRow,
  IonSegment,
  IonSegmentButton,
  getPlatforms,
  useIonToast,
  useIonViewDidEnter,
} from '@ionic/react';
import axios from 'axios';
import moment from 'moment';
import 'moment-timezone';
import React, { useEffect, useState } from 'react';
import AppFooter from '../../components/appfooter/AppFooter';
import Header from '../../components/header/Header';
import MobileFooter from '../../components/mobileFooter/mobilefooter';
import MobileHeader from '../../components/mobileHeader/mobileHeader';
import NoDataComponent from '../../components/noDataComp/NoDataComponent';
import { TwilioVideoCallScreen } from '../../components/patient-support/TwilioVideoCallScreen';
import { default as StoreService, default as storeService } from '../../services/store.service';
import './HistoryConsulta.scss';
declare const twiliovideo: any;

const History: React.FC<any> = (props) => {
  const [userData, setUserData] = useState(null);
  const [historyData, setHistoryData] = useState<any>(null);
  const [futureData, setFutureData] = useState<any>(null);
  const [priority, setPriority] = useState<any>('future');
  const [isLoading, setIsLoading] = useState<any>(false);
  const [videoAccessToken, setVideoAccessToken] = useState<any>();
  const [roomName, setRoomName] = useState<any>();
  const [present, dismiss] = useIonToast();
  const [isCancelAppointment, setIsCancelAppointment] = useState<any>(false);
  const [appointmentId, setAppointmentId] = useState<any>();

  useIonViewDidEnter(() => {
    StoreService.get('user').then((res) => {
      if (res) {
        if (!res?.terms_of_use || !res?.privacy_policy) {
          props.history.push("/privacy-policy");
        }
        getHistoryAppointments();
        setUserData(res);
        return;
      } else {
        props.history.push('/login');
      }
    });
  });
  useEffect(() => {
    getHistoryAppointments();
  }, [props.location.key]);

  const CancelAppointment = (appointmentId: any) => {
    axios
      .delete(
        `${process.env.REACT_APP_API_BASE_URL}/scheduling/schedule/delete/` +
          appointmentId?.id +
          '/' +
          userData?.user_id
      )
      .then((res: any) => {
        setIsCancelAppointment(false);
        getHistoryAppointments();
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  const getHistoryAppointments = async () => {
    const timezone: any = moment.tz.guess();
    setIsLoading(true);
    let currentDateAPI: any = new Date();
    currentDateAPI.setHours(23, 59, 59, 999);
    currentDateAPI = currentDateAPI.toUTCString();
    const userId = await StoreService.get('user_id');


    axios
      .get(`${process.env.REACT_APP_MIX_PARSE_API}scheduling/patient/history_appointments/${userId}?date=${currentDateAPI}&timezone=${timezone}&page=1&limit=1000`)
      .then((res: any) => {
        setIsLoading(false);
        const { data : { futureAppointments, pastAppointments }} = res;
        setFutureData(futureAppointments);
        setHistoryData(pastAppointments);
      })
      .catch((error: any) => {
        setIsLoading(false);
        console.log('error', error);
      });
  };
  const getAccessToken = async (appointment: any) => {
    const user = await StoreService.get('user');
    
    if (moment(new Date(appointment.UTCdatetime), "DD/MM/YYYY HH:mm:ss").diff(moment(new Date(), "DD/MM/YYYY HH:mm:ss"), "minutes") < appointment.ETA) { 
      const roomName = appointment.id;
      setRoomName(roomName);
      const userToken = btoa(
        `4011045|${user?.cpf}|1111|${user?.name}|${user?.email}||1111|1111|||1111|1111|APS`
      );
      window.location.href = `${process.env.REACT_APP_APS_URL}/patient-appointments?name=${userToken}&&appointment_id=${appointment?.id}&corporation=ampla-saude&state=pt&redirect_url=${window.location.origin}`;
    } else {
      present({
        message:
          `As consultas só podem ser iniciada ${appointment.ETA || 30} minutos antes da hora marcada. Por gentileza aguarde o momento certo para iniciar o atendimento.`,
        duration: 3000,
      });
    }
  };

  const roomEventCallback = (event: any) => {
    if (event === 'CLOSED') {
      setVideoAccessToken(null);
      setRoomName(null);
    }
  };
  const rescheduleAgenda = async (appointment: any) => {
    // alert("hello")
    await storeService.set('appointment', appointment);
    await storeService.set('isHistory', true);
    props.history.push({ pathname: '/home' });
  };
  const goBack = () => {
    props.history.back();
  };
  return (
    <IonPage>
      <IonContent>
        {/* <Header props={props} routeName="history" /> */}
        {getPlatforms().includes('desktop') ? (
          <Header props={props} routeName="history" />
        ) : (
          <MobileHeader props={props} />
        )}

        <div className="history-mainwrapper">
          <div className="rightcontent">
            <div className="container">
              {getPlatforms().includes('desktop') ? (
                <div className="header-content">
                  <IonLabel className="topsubtitle">
                    Olá, {userData?.name}
                  </IonLabel>
                  {/*  <IonImg slot="start" className="title-img-view hide-on-desktop" src="assets/images/side-mobile-clock-calendar.svg" /> */}
                  <IonLabel className="history-title">
                    Minhas Consultas
                  </IonLabel>
                </div>
              ) : (
                <div className="header-content">
                  <IonImg
                    className="history-main-title-arrow-left"
                    src="assets/images/arrow-left.svg"
                    onClick={() => goBack()}
                  />

                  <IonLabel className="history-topsubtitle-mobile">
                    Minhas consultas
                  </IonLabel>
                </div>
              )}

              <div className="row">
                {getPlatforms().includes('desktop') ? (
                  <div className="col-md-4 d-flex align-items-center">
                    <IonImg
                      className="lefthistoryimg"
                      src="assets/images/history-frame.svg"
                    />
                  </div>
                ) : (
                  ''
                )}
                <div className="history-layout-view">
                  {isLoading && <IonProgressBar type="indeterminate" />}

                  <div>
                    <div>
                      <IonSegment
                        className="header-tab-view"
                        value={priority}
                        onIonChange={(e) => setPriority(e.detail.value)}
                      >
                        <IonSegmentButton value="future" defaultChecked={true}>
                          <IonLabel>Agendadas</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="past">
                          <IonLabel>Histórico</IonLabel>
                        </IonSegmentButton>
                      </IonSegment>
                    </div>

                    {priority == 'past' ? (
                      historyData?.length > 0 ? (
                        historyData && (
                          <IonList
                            className={
                              historyData?.length > 0
                                ? 'txtPolicyContext history-list-view'
                                : 'txtPolicyContext history-nodata-list-view'
                            }
                          >
                            <div className="box-section">
                              {historyData?.map((item: any, index: any) =>
                                index === 0 &&
                                moment(new Date(), "DD/MM/YYYY").diff(
                                  moment(item.appointment_date, "DD/MM/YYYY"),
                                  "days"
                                ) <= 15 &&
                                item?.staffSpeciality ? (
                                  <IonCard
                                    className="tab-content-box tab-content-box-two-recent"
                                    key={index}
                                  >
                                    <IonItem
                                      lines="none"
                                      className="tab-content-box-item"
                                    >
                                      <IonAvatar className="profile-img">
                                        <img
                                          src={
                                            item?.staffProfile?.url
                                              ? item?.staffProfile?.url
                                              : 'assets/images/profile-avtar-default.png'
                                          }
                                          alt="profile pic"
                                        />
                                      </IonAvatar>
                                      <IonLabel>
                                        <h3>{item.staffName}</h3>
                                        <p className="future-speciality">
                                          {item?.staffSpeciality}
                                        </p>
                                      </IonLabel>
                                    </IonItem>
                                    <div className="tab-content-bottom-box">
                                      <div>
                                        <IonImg
                                          className="Icon-img"
                                          src="assets/images/history-booked-icon.svg"
                                        />
                                        <IonLabel className="sub-label">
                                          {moment
                                            .utc(item.UTCdatetime)
                                            .local()
                                            .format('DD/MM/YYYY')}
                                        </IonLabel>
                                      </div>
                                      <div>
                                        <IonImg
                                          className="Icon-img"
                                          src="assets/images/history-booked-clock.svg"
                                        />
                                        <IonLabel className="sub-label">
                                          {moment
                                            .utc(item.UTCdatetime)
                                            .local()
                                            .format('HH:mm')}{' '}
                                          -{' '}
                                          {moment
                                            .utc(
                                              moment(item.UTCdatetime)
                                                .add(30, 'm')
                                                .toDate()
                                            )
                                            .local()
                                            .format('HH:mm')}{' '}
                                        </IonLabel>
                                      </div>
                                    </div>
                                    <IonButton
                                      expand="full"
                                      shape="round"
                                      size="large"
                                      className="reschedule-btn"
                                      type="submit"
                                      onClick={() =>
                                        props?.history?.push({
                                          pathname: `/scheduling/calendar-screen?selectedSpeciality=${item?.staffSpeciality}`,
                                        })
                                      }
                                    >
                                      Agendar retorno
                                    </IonButton>
                                  </IonCard>
                                ) : (
                                  <IonCard
                                    className="tab-content-box tab-content-box-two-old"
                                    key={index}
                                  >
                                    <IonItem
                                      lines="none"
                                      className="tab-content-box-item"
                                    >
                                      <IonAvatar className="profile-img">
                                        <img
                                          src={
                                            item?.staffProfile?.url
                                              ? item?.staffProfile?.url
                                              : 'assets/images/profile-icon.svg'
                                          }
                                          alt="profile pic"
                                        />
                                      </IonAvatar>
                                      <IonLabel>
                                        <h3>{item.staffName}</h3>
                                        <p className="past-speciality">
                                          {item?.staffSpeciality}
                                        </p>
                                      </IonLabel>
                                    </IonItem>
                                    <div className="tab-content-bottom-box">
                                      <div>
                                        <IonImg
                                          className="Icon-img"
                                          src="assets/images/history-grey-booked.svg"
                                        />
                                        <IonLabel className="sub-label">
                                          {moment
                                            .utc(item.UTCdatetime)
                                            .local()
                                            .format('DD/MM/YYYY')}
                                        </IonLabel>
                                      </div>
                                      <div>
                                        <IonImg
                                          className="Icon-img"
                                          src="assets/images/history-grey-bookedclock.svg"
                                        />
                                        <IonLabel className="sub-label">
                                          {item.call_type === 'support' ? (
                                            <>
                                              {item.startTime}-{' '}
                                              {item.endTime
                                                ? item.endTime
                                                : moment()
                                                    .add(15, 'minutes')
                                                    .format('HH:mm')}
                                            </>
                                          ) : (
                                            <>
                                              {moment
                                                .utc(item.UTCdatetime)
                                                .local()
                                                .format('HH:mm')}{' '}
                                              -{' '}
                                              {moment
                                                .utc(
                                                  moment(item.UTCdatetime)
                                                    .add(30, 'm')
                                                    .toDate()
                                                )
                                                .local()
                                                .format('HH:mm')}{' '}
                                            </>
                                          )}{' '}
                                        </IonLabel>
                                      </div>
                                    </div>
                                  </IonCard>
                                )
                              )}
                            </div>
                          </IonList>
                        )
                      ) : (
                        <NoDataComponent text="Não há consultas agendadas no momento" />
                      )
                    ) : futureData?.length > 0 ? (
                      futureData ? (
                        <IonList
                          className={
                            futureData.length > 0
                              ? 'txtPolicyContext history-list-view'
                              : 'txtPolicyContext history-nodata-list-view'
                          }
                        >
                          <div className="box-section">
                            {futureData?.map((item: any, index: any) => (
                              <IonCard
                                className="tab-content-box tab-content-box-one"
                                key={index}
                              >
                                <IonItem
                                  lines="none"
                                  className="tab-content-box-item"
                                >
                                  <IonAvatar className="profile-img">
                                    <img
                                      src={
                                        item.staffProfile.url
                                          ? item.staffProfile.url
                                          : 'assets/images/profile-avtar-default.png'
                                      }
                                      alt="profile pic"
                                    />
                                  </IonAvatar>
                                  <IonLabel>
                                    <h3>{item.staffName}</h3>
                                    <p className="future-speciality">
                                      {item?.staffSpeciality}
                                    </p>
                                  </IonLabel>
                                </IonItem>
                                <div className="tab-content-bottom-box">
                                  <div>
                                    <IonImg
                                      className="Icon-img"
                                      src="assets/images/history-booked-icon.svg"
                                    />
                                    <IonLabel className="sub-label">
                                      {moment
                                        .utc(item.UTCdatetime)
                                        .local()
                                        .format('DD/MM/YYYY')}
                                    </IonLabel>
                                  </div>
                                  <div>
                                    <IonImg
                                      className="Icon-img"
                                      src="assets/images/history-booked-clock.svg"
                                    />
                                    <IonLabel className="sub-label">
                                      {moment
                                        .utc(item.UTCdatetime)
                                        .local()
                                        .format('HH:mm')}{' '}
                                      -{' '}
                                      {moment
                                        .utc(
                                          moment(item.UTCdatetime)
                                            .add(30, 'm')
                                            .toDate()
                                        )
                                        .local()
                                        .format('HH:mm')}{' '}
                                    </IonLabel>
                                  </div>
                                </div>
                                {/* <div className="agendadas-btn-view">
                                                   <IonButton className="iniciar-agendadas-btn" shape="round" expand="block" fill="outline" onClick={()=>getAccessToken(item)}>Iniciar consulta</IonButton>
                                               </div> */}

                                <IonRow>
                                  <IonCol size-lg="6" size-sm="12">
                                    <IonButton
                                      className="iniciar-agendadas-btn"
                                      shape="round"
                                      expand="block"
                                      fill="outline"
                                      onClick={() => getAccessToken(item)}
                                    >
                                      Iniciar consulta
                                    </IonButton>
                                  </IonCol>
                                  <IonCol size-lg="6" size-sm="12">
                                    <IonButton
                                      className="iniciar-agenda-cancel"
                                      shape="round"
                                      expand="block"
                                      fill="outline"
                                      onClick={() => {
                                        setAppointmentId(item);
                                        setIsCancelAppointment(true);
                                      }}
                                    >
                                      CANCELAR
                                    </IonButton>
                                  </IonCol>
                                </IonRow>

                                <IonLabel>
                                  Você poderá iniciar sua consulta com 30
                                  minutos de antecedência.
                                </IonLabel>
                              </IonCard>
                            ))}
                          </div>
                        </IonList>
                      ) : (
                        <IonLabel></IonLabel>
                      )
                    ) : (
                      <NoDataComponent text="Não há consultas agendadas no momento" />
                    )}
                  </div>

                  {/* <IonList className="txtPolicyContext history-list-view" >
                                        <IonCard className="tab-content-box tab-content-box-one">
                                            <IonItem lines="none" className="tab-content-box-item">
                                                <IonAvatar className="profile-img">
                                                    <img src='assets/images/profile-avtar-default.png' alt="profile pic" />
                                                </IonAvatar>
                                                <IonLabel>
                                                    <h3>Doctor 2</h3>
                                                    <p className="future-speciality">Psicólogo</p>
                                                </IonLabel>
                                            </IonItem>
                                            <div className="tab-content-bottom-box">
                                                <div>
                                                    <IonImg className="Icon-img" src="assets/images/history-booked-icon.svg" />
                                                    <IonLabel className="sub-label">20/02/2021</IonLabel>
                                                </div>
                                                <div>
                                                    <IonImg className="Icon-img" src="assets/images/history-booked-clock.svg" />
                                                    <IonLabel className="sub-label">00:00- 00:00    </IonLabel>
                                                </div>
                                            </div>
                                            

                                            <IonRow>
                                                <IonCol size-lg="6" size-sm="12">
                                                    <IonButton className="iniciar-agendadas-btn" shape="round" expand="block" fill="outline">Iniciar consulta</IonButton>
                                                </IonCol>
                                                <IonCol size-lg="6" size-sm="12">
                                                    <IonButton className="iniciar-agenda-cancel" shape="round" expand="block" fill="outline" onClick={() => {
                                                        // setAppointmentId(item)
                                                        // setIsCancelAppointment(true)
                                                    }}>CANCELAR</IonButton>
                                                </IonCol>
                                            </IonRow>

                                            <IonLabel>Você poderá iniciar sua consulta com 30 minutos de antecedência.</IonLabel>

                                        </IonCard>

                                    </IonList> */}

                  {/* {priority == 'past' ? (
                               historyData && <IonList className={historyData?.length > 0 ? "txtPolicyContext history-list-view" : "txtPolicyContext history-nodata-list-view"}>
                                   <div className="box-section">
                                       {historyData?.map((item: any, index: any) => (<IonCard className="tab-content-box tab-content-box-two">
                                           <IonItem lines="none" className="tab-content-box-item">
                                               <IonAvatar className="profile-img">
                                                   <img src={item.staffProfile.url} alt="profile pic" />
                                               </IonAvatar>
                                               <IonLabel>
                                                   <h3>{item.staffName}</h3>
                                                   <p>Cliníca Geral</p>
                                               </IonLabel>
                                           </IonItem>
                                           <div className="tab-content-bottom-box">
                                               <div>
                                                   <IonImg className="Icon-img" src="assets/images/history-booked-icons.svg" />
                                                   <IonLabel className="sub-label">{moment.utc(item.UTCdatetime).local().format('DD/MM/YYYY')}</IonLabel>
                                               </div>
                                               <div>
                                                   <IonImg className="Icon-img" src="assets/images/historybooked.svg" />
                                                   <IonLabel className="sub-label">{moment.utc(item.UTCdatetime).local().format('HH:mm')
                                                   } - {
                                                           moment.utc(moment(item.UTCdatetime).add(30, 'm').toDate()).local().format('HH:mm')}	</IonLabel>
                                               </div>
                                           </div>
                                       </IonCard>
                                       ))}
                                   </div>

                               </IonList>
                           ) : (
                               futureData ? <IonList className={futureData.lenght > 0 ? "txtPolicyContext history-list-view" : "txtPolicyContext history-nodata-list-view"}>
                                   <div className="box-section">
                                       {futureData?.map((item: any, index: any) => (<IonCard className="tab-content-box tab-content-box-one">
                                           <IonItem lines="none" className="tab-content-box-item">
                                               <IonAvatar className="profile-img">
                                                   <img src={item.staffProfile.url} alt="profile pic" />
                                               </IonAvatar>
                                               <IonLabel>
                                                   <h3>{item.staffName}</h3>
                                                   <p>Cliníca Geral</p>
                                               </IonLabel>
                                           </IonItem>
                                           <div className="tab-content-bottom-box">
                                               <div>
                                                   <IonImg className="Icon-img logo-wrapper" src="assets/images/history-booked-icons.svg" />
                                                   <IonLabel className="sub-label">{moment.utc(item.UTCdatetime).local().format('DD/MM/YYYY')}</IonLabel>
                                               </div>
                                               <div>
                                                   <IonImg className="Icon-img logo-wrapper" src="assets/images/historybooked.svg" />
                                                   <IonLabel className="sub-label">{moment.utc(item.UTCdatetime).local().format('HH:mm')
                                                   } - {
                                                           moment.utc(moment(item.UTCdatetime).add(30, 'm').toDate()).local().format('HH:mm')}	</IonLabel>
                                               </div>
                                           </div>
                                           <div className="agendadas-btn-view">
                                               <IonButton className="iniciar-agendadas-btn" shape="round" expand="block" fill="outline" onClick={() => getAccessToken(item)}>Iniciar consulta</IonButton>
                                           </div>
                                       </IonCard>
                                       ))}
                                       <div className="hide-on-desktop" aria-labelledby="hide-on-desktop">
                                           <IonButton className="history-agendadas-btn" shape="round" expand="block" fill="outline">Agendar consulta</IonButton>
                                       </div>

                                   </div>

                               </IonList> : <IonLabel></IonLabel>

                           )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer /> */}
        {getPlatforms().includes('desktop') ? (
          <AppFooter />
        ) : (
          <MobileFooter props={props} />
        )}
        {(getPlatforms().includes('android') ||
          getPlatforms().includes('ios')) &&
          videoAccessToken &&
          userData && (
            <IonModal
              isOpen={videoAccessToken}
              onDidDismiss={() => {
                setVideoAccessToken(null);
              }}
            >
              <TwilioVideoCallScreen
                videoToken={videoAccessToken}
                roomName={roomName}
                supportId={userData?.uid}
                patientId={userData?.uid}
                isAppointmentCall={true}
              />
            </IonModal>
          )}

        <IonModal
          isOpen={isCancelAppointment}
          cssClass="logout-modal"
          onDidDismiss={() => setIsCancelAppointment(false)}
        >
          <span className="logout-title">Você quer deletar o compromisso?</span>
          <div>
            <IonButton
              shape="round"
              color="primary"
              expand="block"
              className="custom-logout-btn"
              onClick={() => {
                CancelAppointment(appointmentId);
              }}
            >
              Sim
            </IonButton>
            <IonLabel
              className="custom-logout-cancel-btn"
              onClick={() => setIsCancelAppointment(false)}
            >
              Cancelar
            </IonLabel>
          </div>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};
export default History;
