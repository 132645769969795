import * as CryptoJS from 'crypto-js'
import * as SecureStorage from 'secure-web-storage'

const SECRET_KEY: any = 'iron-health'

export interface SecureStorageClient {
  // stores in localStorage like:
  // key => value
  // "ad36d572..." => "w1svi6n..."
  setItem: <T = any>(key: string, data: T) => void

  // returns stored and parsed data
  getItem: <T = any>(key: string) => T | undefined | null

  // removes the entry in key
  removeItem: (key: string) => void

  // returns the hashed version of the key you passed into setItem with the given id.
  key: (key: string) => string

  // clears all data in the underlining sessionStorage/localStorage.
  clear: () => void

  // the number of entries in the underlining sessionStorage/localStorage.
  length: number
}

export const secureWebStorage: SecureStorageClient = new SecureStorage(localStorage, {
  hash: (key: string) => CryptoJS.SHA256(key, SECRET_KEY).toString(),
  encrypt: (data: string) => CryptoJS.AES.encrypt(data, SECRET_KEY).toString(),
  decrypt: (data: string) => CryptoJS.AES.decrypt(data, SECRET_KEY).toString(CryptoJS.enc.Utf8),
})
