import { liveLogger } from '@irontrainers/live-assist/utils'

const now = () => {
  return new Date().toISOString()
}

const User:{
  userId?: string
  type?: 'patient' | 'staff'
} = {}

const logger = {
  log: (component: string, description:string, options:any = '') => {
    console.log(`[debug ${now()}${User.userId ? ` ${User.userId}` : ''}] ${component}: ${description}`, JSON.stringify(options))
    const details = options ? typeof options === 'string' ? options : JSON.stringify(options) : undefined
    const supportId = typeof options === 'object' ? options.supportId : undefined
    if (User.type === 'patient') liveLogger.patientLog(`${description} (${component})`, { details, supportId })
    if (User.type === 'staff') liveLogger.staffLog(`${description} (${component})`, { details, supportId })
  },
  setUser: (userId: string, type: 'patient' | 'staff') => {
    logger.log('Logger', `Set user as ${userId}`)
    User.userId = userId
    User.type = type
    if (type === 'patient') liveLogger.setPatientId(userId)
    if (type === 'staff') liveLogger.setStaffId(userId)
  }
}


export default logger
