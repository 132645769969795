import { Camera, CameraResultType } from "@capacitor/camera";
import { IonAvatar, IonButton, IonCol, IonContent, IonGrid, IonImg, IonInput, IonItem, IonLabel, IonModal, IonPage, IonProgressBar, IonRow, getPlatforms, useIonToast, useIonViewDidEnter } from '@ionic/react';
import axios from 'axios';
import { Formik } from "formik";
import moment from "moment";
import 'moment-timezone';
import React, { useRef, useState } from 'react';
import * as Yup from "yup";
import AppFooter from '../../components/appfooter/AppFooter';
import Header from '../../components/header/Header';
import MobileFooter from '../../components/mobileFooter/mobilefooter';
import MobileHeader from '../../components/mobileHeader/mobileHeader';
import StoreService from '../../services/store.service';
import './UpdateProfile.scss';
// import Camera from "react-html5-camera-photo";
// import "react-html5-camera-photo/build/css/index.css";
import storeService from '../../services/store.service';
const UpdateProfile: React.FC<any> = (props) => {
    const hiddenProfileButton = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<any>(false)
    const [profileData, setProfileData] = useState<any>(null)
    const [isEditProfile, setIsEditProfle] = useState<any>(true)
    const [isLgoutConfirmation, setIsLogoutConfirmation] = useState<any>(false)
    const [userData, setUserData] = useState(null)
    const [present, dismiss] = useIonToast();
    const [isShowModal, setIsShowModal] = useState(false);
    const [isOpenCamera, setIsOpenCamera] = useState(false);
    // const { Camera } = Plugins;
    const [profileImg, setProfileImg] = useState<any>(
        ""
    );
    const [handleCameraError, setHandleCameraError] =
        React.useState<boolean>(false);

    useIonViewDidEnter(() => {
        StoreService.get('user').then(res => {
            if (res) {
                console.warn("user", res);
                if (!res?.terms_of_use || !res?.privacy_policy) {
                    props.history.push("/privacy-policy");
                  }
                setProfileData(null)
                getProfileData();
                setUserData(res);
                return
            } else {
                props.history.push("/login");
            }
        })
    });

    async function handleTakePhoto(dataUri) {
        // handleUploadButton(null)
        const data = {
            profile: dataUri,
        };
        setIsOpenCamera(false);
        UploadFile(dataUri);
    }

    const getProfileData = () => {
        setIsLoading(true)
        StoreService.get("user_id").then((userID: any) => {
            // const id = "BgsuhxaPSs"
            axios.get(`${process.env.REACT_APP_MIX_PARSE_API}scheduling/patient/get_profile_details/${userID}`).then(async (response: any) => {
                setIsLoading(false)
                setProfileData(response.data.date)
                await storeService.set("profile_image", response.data.date.avatar)
                const img = await storeService.get("profile_image");

                const userDetails = await storeService.get("user");
                const newUser = {
                    ...userDetails,
                    telePhone: response.data.date.phones,
                    email: response.data.date.email
                }
                storeService.set("user", newUser);

            }).catch((err: any) => {
                setIsLoading(false)
                console.log("err", err);
            })
        });


    }
    const submitProfileData = async (values: any, setSubmitting: any) => {
        const userDetails = await storeService.get("user");
        const data = {
            email: values.email,
            phone: values.celular,
            countryCode: userDetails?.telePhone[0]?.country_code,
            name: values.name,
            measurements: {
                weight: values.weight.toString().replace(/\s/g, ''),
                height: values.height.toString().replace(/\s/g, ''),
                hipCircumference: values.hip.toString().replace(/\s/g, ''),
                abdominalCircumference: values.abdominal.toString().replace(/\s/g, '')
            }
        };
        StoreService.get("user_id").then((userID: any) => {
            // const id: any = "BgsuhxaPSs"
            setIsLoading(true)
            axios.post(`${process.env.REACT_APP_MIX_PARSE_API}doctor/update_profile_details/${userID}`, data)
                .then(async (response: any) => {
                    setSubmitting(false)
                    // setIsEditProfle(false)
                    getProfileData();
                    present({ message: "sucesso", duration: 2000 })
                })
                .catch((err: any) => {
                    setSubmitting(false)
                    console.log("err", err);
                });
        });
    }
    const handleProfileButton = () => {
        if (hiddenProfileButton.current) {
            hiddenProfileButton.current.click();
        }
    };
    const handleProfileInput = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files) {
            const fileUploaded = event.target.files[0];
            const reader = new FileReader();
            reader.readAsDataURL(fileUploaded);
            reader.onloadend = async () => {
                const data = {
                    profile: reader.result,
                };
                UploadFile(data.profile)
                setIsLoading(true);

            };


            // console.log("fileUploaded",fileUploaded)


        }
    };
    const logout = () => {
        StoreService.set("user", null);
        StoreService.set("user_id", null);
        StoreService.set("user_cpf", null);
        storeService.set("policyData", null);
        storeService.set("privacy", null);;
        storeService.set("terms", null);;
        setIsLogoutConfirmation(false);
        props.history.push("/login");
    };
    const takePicture = async () => {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Base64,
            // source: CameraSource.Camera
        });
        UploadFile(image.base64String);
    };
    const UploadFile = async (file: string | ArrayBuffer | null | undefined) => {
        setIsLoading(true);
        const data = {
            profile: file,
        };
        const userId = await StoreService.get("user_id")
        axios.post(`${process.env.REACT_APP_MIX_PARSE_API}/scheduling/update_user_profie_pic/${userId}`, data).then(async (res: any) => {
            setIsLoading(false);
            getProfileData();
            await storeService.set("profile_image", res.data.profile.url)
            let temp = await storeService.get("user");
            await storeService.set("user", {
                ...temp, avatar: {
                    url: res.data.profile.url
                }
            })
            setProfileImg(res.data.profile.url);
        }).catch((error: any) => {
            setIsLoading(false);
            console.log("error", error)
        })

    };
    const redirectToHome = () => {
        props.history.push('/home');
    }
    return (
        <IonPage>
            <IonContent forceOverscroll scrollY>
                {/* {platform.isMobile && <Header />} */}
                {/* <HeaderNav props={props} routeName="edit-profile" /> */}
                {getPlatforms().includes("desktop") ?
                    <Header props={props} routeName="profile" profileImg={profileImg} />
                    :
                    <MobileHeader props={props} profileImg={profileImg} />
                }

                <div className="profile-mainwrapper">
                    <div className="rightcontent">
                        <div className="container">
                            <div className="row">
                                <div className="update-profile-view">

                                    {isEditProfile ? (
                                        <>
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    name: profileData?.name,
                                                    celular: profileData?.phones[0]?.number,
                                                    email: profileData?.email,
                                                    dob: profileData?.birthdate,
                                                    weight: profileData?.measurements?.weight ? profileData?.measurements?.weight : "",
                                                    height: profileData?.measurements?.height ? profileData?.measurements?.height : "",
                                                    hip: profileData?.measurements?.hipCircumference ? profileData?.measurements?.hipCircumference : "",
                                                    abdominal: profileData?.measurements?.abdominalCircumference ? profileData?.measurements?.abdominalCircumference : "",
                                                    date: profileData?.measurements?.date && moment(
                                                        profileData?.measurements?.date
                                                    ).format("YYYY-MM-DD")
                                                }}

                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string()
                                                        .email("Email inválido")
                                                        .required("E-mail é obrigatório"),
                                                    dob: Yup.date().required("A data de nascimento é obrigatória"),
                                                    height: Yup.number()
                                                        .integer("Somente números são permitidos neste campo")
                                                        .min(10, "Altura mínima deve ser 10")
                                                        .max(250, "Altura máxima deve ser 250")
                                                        .typeError("Somente números são permitidos neste campo"),
                                                    weight: Yup.number()
                                                        .integer("Somente números são permitidos neste campo")
                                                        .min(1, "Peso mínima deve ser 1")
                                                        .max(350, "Peso máxima deve ser 350")
                                                        .typeError("Somente números são permitidos neste campo"),
                                                    hip: Yup.number().integer("Somente números são permitidos neste campo")
                                                        .typeError("Somente números são permitidos neste campo"),
                                                    abdominal: Yup.number().integer("Somente números são permitidos neste campo")
                                                        .typeError("Somente números são permitidos neste campo"),
                                                })}
                                                onSubmit={(values, { setSubmitting }) =>
                                                    submitProfileData(values, setSubmitting)
                                                }
                                            >

                                                {({
                                                    values,
                                                    errors,
                                                    touched,
                                                    handleChange,
                                                    handleBlur,
                                                    handleSubmit,
                                                    isSubmitting,
                                                    handleReset
                                                    /* and other goodies */
                                                }) => (
                                                    <form onSubmit={handleSubmit}>
                                                        {isLoading &&
                                                            (
                                                                <IonProgressBar type="indeterminate" className="ion-margin-bottom" />
                                                            )}
                                                        <IonRow>
                                                            <IonCol sizeSm="12" sizeMd="5" size="12">

                                                                <div className="edit-personal-details">

                                                                    <IonLabel className="topsubtitle-profile-update d-none d-md-block">Olá, {userData?.name}</IonLabel>
                                                                    <div className="header-content">
                                                                        <IonLabel className="header-title-txt">Meu perfil</IonLabel>
                                                                    </div>
                                                                    <IonLabel className="mobile-title text-left">Confira seus dados pessoais</IonLabel>

                                                                    <div className="mobilephoto"></div>
                                                                    <div className="header-content-body d-flex d-md-none">
                                                                        <IonAvatar>
                                                                            <img src={profileData?.avatar ? profileData?.avatar : "assets/images/profile-avtar-default.png"} />
                                                                        </IonAvatar>
                                                                        <input
                                                                            type="file"
                                                                            ref={hiddenProfileButton}
                                                                            onChange={handleProfileInput}
                                                                            style={{ display: "none" }}
                                                                        />
                                                                        <span onClick={() => setIsShowModal(true)}>
                                                                            <IonImg src="assets/images/profile-input-icon.svg" className="" />
                                                                        </span>
                                                                    </div>
                                                                    <div className="title-sub-header-txt">
                                                                        <IonLabel className="title-sub-view d-none">Editar meus dados pessoais</IonLabel>
                                                                        <IonLabel className="title-sub-view d-block d-md-none text-center">Edite sua foto</IonLabel>
                                                                    </div>
                                                                    <div className="mobilecontent">

                                                                        <span><IonLabel className="nametit" > Nome completo</IonLabel>
                                                                            <IonLabel className="subname">{values.name}</IonLabel></span>
                                                                        <IonLabel className="dataname">Data de Nascimento {values.dob}</IonLabel>

                                                                    </div>
                                                                    <IonGrid className="edit-grid-view">
                                                                        {/* <IonRow> */}
                                                                        {/* <IonCol sizeLg="10" size="12">
                                                                                    <IonItem color="transparent" lines="none" class="ion-no-padding">
                                                                                        <IonLabel color="light" position="stacked" className="title-component">Nome completo</IonLabel>
                                                                                        <label className="input-border-view" > {values.name}</label>
                                                                                        <IonImg src="assets/images/profile-input-icon.svg" className="input-icon" />
                                                                                        <span className="custom-error-label">{errors.name && touched.name && errors.name}</span>
                                                                                    </IonItem>
                                                                                </IonCol> */}
                                                                        {/* <IonCol sizeLg="10" size="12">
                                                                                    <IonItem color="transparent" lines="none" class="ion-no-padding">
                                                                                        <IonLabel color="light" position="stacked" className="title-component">Data de nascimento</IonLabel> */}
                                                                        {/* <label>{values.dob}</label> */}

                                                                        {/* <IonDatetime className="input-border-view"
                                                                                            displayFormat="DD MMMM YYYY"
                                                                                            value={values.dob}
                                                                                            onBlur={handleBlur}
                                                                                            disabled
                                                                                            name="dob"
                                                                                            placeholder="00 / 00 / 0000"
                                                                                            onIonChange={handleChange}
                                                                                            monthNames="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"
                                                                                            monthShortNames="Jan, Fev, Março, Abril, Maio, Junho, Julho, Agosto, Set, Out, Nov, Dez"
                                                                                            doneText="SALVAR"
                                                                                            cancelText="CANCELAR"
                                                                                        /> */}
                                                                        {/* <IonInput className="input-border-view" type="date" name="dob" value={values.dob} onIonChange={handleChange} onBlur={handleBlur}> </IonInput> */}
                                                                        {/* <IonImg src="assets/images/profile-input-icon.svg" className="input-icon"/> */}
                                                                        {/* <span className="custom-error-label">{errors.dob && touched.dob && errors.dob}</span> */}
                                                                        {/* </IonItem>
                                                                                </IonCol>
                                                                            </IonRow> */}
                                                                        <IonRow>
                                                                            <IonCol sizeLg="10" size="12">
                                                                                <IonItem color="transparent" lines="none" class="ion-no-padding">
                                                                                    <IonLabel color="light" position="stacked" className="title-component">Email</IonLabel>
                                                                                    <IonInput className="input-border-view" type="text" placeholder="Digite seu email" name="email" value={values.email} onIonChange={handleChange} onBlur={handleBlur}> </IonInput>
                                                                                    <IonImg src="assets/images/profile-input-icon.svg" className="input-icon" />
                                                                                    <span className="custom-error-label">{errors.email && touched.email && errors.email}</span>
                                                                                </IonItem>
                                                                            </IonCol>
                                                                            <IonCol sizeLg="10" size="12">
                                                                                <IonItem color="transparent" lines="none" class="ion-no-padding">
                                                                                    <IonLabel color="light" position="stacked" className="title-component">Telefone (com DDD)</IonLabel>
                                                                                    <IonInput className="input-border-view" type="tel" name="celular" placeholder="(19)9 95556-5857" value={values.celular} onIonChange={handleChange} onBlur={handleBlur}> </IonInput>
                                                                                    <IonImg src="assets/images/profile-input-icon.svg" className="input-icon" />
                                                                                </IonItem>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                    </IonGrid>
                                                                </div>

                                                            </IonCol>

                                                            <IonCol sizeSm="12" sizeMd="7">

                                                                <div className="edit-body-measurements">
                                                                    <div className="header-content-body">
                                                                        <IonAvatar >
                                                                            <img src={profileData?.avatar ? profileData?.avatar : "assets/images/profile-avtar-default.png"} />
                                                                        </IonAvatar>
                                                                        <input
                                                                            type="file"
                                                                            ref={hiddenProfileButton}
                                                                            onChange={handleProfileInput}
                                                                            style={{ display: "none" }}
                                                                        />
                                                                        <span onClick={() => setIsShowModal(true)} className="profile-cursor">
                                                                            <IonImg src="assets/images/profile-input-icon.svg" className="" />
                                                                        </span>
                                                                    </div>

                                                                    <div className="title-sub-header-txt d-none d-md-block  ">
                                                                        <IonLabel className="title-sub-view">Edite sua foto</IonLabel>
                                                                    </div>
                                                                    <IonGrid className="edit-grid-view">
                                                                        <IonRow>
                                                                            <IonCol className="edit-body-col text-center text-md-start">
                                                                                <IonLabel className="edit-body-title-label">Confira ou atualize suas medidas</IonLabel>
                                                                            </IonCol>
                                                                        </IonRow>
                                                                        <IonRow>
                                                                            <IonCol className="mxautomobile" sizeLg="4" sizeMd="6" sizeSm="6" size="6">
                                                                                <IonItem lines="none" class="ion-no-padding edit-body-item">
                                                                                    <IonLabel color="light" position="stacked" className="title-component">Data</IonLabel>
                                                                                    {/* <IonDatetime className="input-border-view"
                                                                                            displayFormat="DD MMMM YYYY"
                                                                                            value={values.date}
                                                                                            onBlur={handleBlur}
                                                                                            name="date"
                                                                                            placeholder="00 / 00 / 0000"
                                                                                            onIonChange={handleChange}
                                                                                            monthNames="Janeiro, Fevereiro, Março, Abril, Maio, Junho, Julho, Agosto, Setembro, Outubro, Novembro, Dezembro"
                                                                                            monthShortNames="Jan, Fev, Março, Abril, Maio, Junho, Julho, Agosto, Set, Out, Nov, Dez"
                                                                                            doneText="SALVAR"
                                                                                            cancelText="CANCELAR"
                                                                                        /> */}
                                                                                    <IonInput type="date" className="input-border-view input-hide-calendar-icon" name="date" disabled value={values.date} onIonChange={handleChange} onBlur={handleBlur}> </IonInput>
                                                                                    {/* <IonImg src="assets/images/profile-input-icon.svg" className="input-icon-body" /> */}
                                                                                    <span className="custom-error-label">{errors.date && touched.date && errors.date}</span>
                                                                                </IonItem>
                                                                            </IonCol>

                                                                        </IonRow>
                                                                        <div className="row bottomform">
                                                                            <div className="col-md-12 col-lg-8">
                                                                                <IonRow>
                                                                                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" size="6">
                                                                                        <IonItem lines="none" class="ion-no-padding edit-body-item">
                                                                                            <IonLabel color="light" position="stacked" className="title-component">Peso</IonLabel>
                                                                                            <div className="position-relative w-100">
                                                                                                <IonInput className="input-border-view" placeholder="69 kg" type="tel" name="weight" value={values.weight} onIonChange={handleChange} onIonBlur={handleBlur}
                                                                                                > </IonInput>
                                                                                                <IonImg src="assets/images/profile-input-icon.svg" className="input-icon-body" />
                                                                                            </div>
                                                                                            <span className="custom-error-label">{errors.weight}</span>
                                                                                        </IonItem>
                                                                                    </IonCol>
                                                                                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" size="6">
                                                                                        <IonItem lines="none" class="ion-no-padding edit-body-item">
                                                                                            <IonLabel color="light" position="stacked" className="title-component">Altura</IonLabel>
                                                                                            <div className="position-relative w-100">
                                                                                                <IonInput className="input-border-view" placeholder="170 cm" type="tel" name="height"
                                                                                                    value={values.height} onIonChange={handleChange} onIonBlur={handleBlur}
                                                                                                > </IonInput>
                                                                                                <IonImg src="assets/images/profile-input-icon.svg" className="input-icon-body" />
                                                                                            </div>
                                                                                            <span className="custom-error-label">{errors.height}</span>
                                                                                        </IonItem>
                                                                                    </IonCol>

                                                                                </IonRow>
                                                                                <IonRow>
                                                                                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" size="6">
                                                                                        <IonItem lines="none" class="ion-no-padding edit-body-item">
                                                                                            <IonLabel color="light" position="stacked" className="title-component">Medida Abdominal</IonLabel>
                                                                                            <div className='position-relative w-100'>

                                                                                                <IonInput className="input-border-view" placeholder="72 cm" type="tel" name="abdominal" value={values.abdominal} onIonChange={handleChange} onIonBlur={handleBlur}> </IonInput>
                                                                                                <IonImg src="assets/images/profile-input-icon.svg" className="input-icon-body" />
                                                                                            </div>
                                                                                            <span className="custom-error-label">{errors.abdominal && touched.abdominal && errors.abdominal}</span>
                                                                                        </IonItem>
                                                                                    </IonCol>
                                                                                    <IonCol sizeLg="6" sizeMd="6" sizeSm="6" size="6">
                                                                                        <IonItem lines="none" class="ion-no-padding edit-body-item">
                                                                                            <IonLabel color="light" position="stacked" className="title-component">Medida do quadril</IonLabel>
                                                                                            <div className='position-relative w-100'>
                                                                                                <IonInput className="input-border-view" placeholder="72 cm" type="tel" name="hip" value={values.hip} onIonChange={handleChange} onIonBlur={handleBlur}> </IonInput>
                                                                                                <IonImg src="assets/images/profile-input-icon.svg" className="input-icon-body" />
                                                                                            </div>
                                                                                            <span className="custom-error-label">{errors.hip && touched.hip && errors.hip}</span>
                                                                                        </IonItem>
                                                                                    </IonCol>

                                                                                </IonRow>

                                                                            </div>
                                                                            <div className="col-lg-4 d-flex bottombuttondiv flex-column px-3 px-xl-0 pt-4 pt-xl-0">

                                                                                <IonButton expand="full" shape="round" size="large" className="salvar-btn" type="submit">Salvar alterações</IonButton>
                                                                                <IonButton expand="full" shape="round" size="large" className="cancelar-view-btn" type="reset" onClick={() => redirectToHome()}>Cancelar</IonButton>

                                                                            </div>


                                                                        </div>

                                                                    </IonGrid>

                                                                    {/* {platform.isAndroid || platform.isMobile ? (
                            <IonGrid className="edit-grid-view">
                                <IonRow className="footer-btn-view">
                                    <IonCol sizeLg="3" sizeMd="4" sizeSm="6" size="12">
                                        <IonButton expand="block" className="salvar-btn-mobile" type="submit">Salvar alterações</IonButton>
                                    </IonCol>
                                    <IonCol sizeLg="3" sizeMd="4" sizeSm="6" size="12">
                                        <IonButton expand="full" className="cancelar-view-btn" onClick={() => setIsEditProfle(false)}>Cancelar</IonButton>
                                    </IonCol>
                                </IonRow>
                            </IonGrid>
                        ) : ( */}
                                                                    {/* <IonGrid className="edit-grid-view">
                                        <IonRow className="footer-btn-view">
                                            <IonCol sizeLg="3" sizeMd="4" sizeSm="6" size="12">
                                                <IonButton expand="full" className="cancelar-view-btn" onClick={() => setIsEditProfle(false)}>Cancelar</IonButton>
                                            </IonCol>
                                            <IonCol sizeLg="3" sizeMd="4" sizeSm="6" size="12">
                                                <IonButton expand="block" className="salvar-btn" type="submit">Salvar alterações</IonButton>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid> */}
                                                                    {/* )} */}
                                                                </div>

                                                            </IonCol>

                                                        </IonRow>






                                                    </form>
                                                )}
                                            </Formik>
                                        </>
                                    ) : (
                                        <>
                                            <IonLabel className="topsubtitle-profile">Olá, {userData?.name}</IonLabel>
                                            <div className="header-content">
                                                <IonLabel className="header-title-txt">Meu perfil</IonLabel>
                                            </div>
                                            {isLoading &&
                                                (
                                                    <IonProgressBar type="indeterminate" className="ion-margin-bottom" />
                                                )}
                                            <div className="profile-container-main">
                                                <div className="personal-details">
                                                    <IonLabel className="header-sub-title-text">Mantenha seus dados cadastrais atualizados. Assim podemos agilizar seus atendimentos e prestar o melhor serviço para você e sua família.</IonLabel>

                                                    <div className="title-sub-header-txt">
                                                        <IonLabel className="title-sub-view">Meus dados pessoais</IonLabel>
                                                    </div>



                                                    {/* {platform.isAndroid || platform.isMobile ? (
                <IonGrid>
                    <IonRow>
                        <IonCol size='12'>
                            <IonLabel position="stacked" className="title-component">Nome completo</IonLabel>
                            <IonLabel className={profileData?.name ? "title-component-ans" : "title-component-not-ans"}>{profileData?.name}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='6'>
                            <IonLabel position="stacked" className="title-component">Data de nascimento</IonLabel>
                            <IonLabel className={profileData?.birthdate ? "title-component-ans" : "title-component-not-ans"}>{profileData?.birthdate}</IonLabel>
                        </IonCol>
                        <IonCol size='6'>
                            <IonLabel position="stacked" className="title-component">Telefone</IonLabel>
                            <IonLabel className={profileData?.phones[0]?.number ? "title-component-ans" : "title-component-not-ans"}>{profileData?.phones[0]?.number}</IonLabel>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol size='12'>
                            <IonLabel position="stacked" className="title-component">Email</IonLabel>
                            <IonLabel className={profileData?.email ? "title-component-ans" : "title-component-not-ans"}>{profileData?.email}</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            ) : ( */}
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size='6'>
                                                                <IonLabel position="stacked" className="title-component">Nome completo</IonLabel>
                                                                <IonLabel className={profileData?.name ? "title-component-ans" : "title-component-not-ans"}>{profileData?.name}</IonLabel>
                                                            </IonCol>
                                                            <IonCol size='6'>
                                                                <IonLabel position="stacked" className="title-component">Data de nascimento</IonLabel>
                                                                <IonLabel className={profileData?.birthdate ? "title-component-ans" : "title-component-not-ans"}>{profileData?.birthdate}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size='6'>
                                                                <IonLabel position="stacked" className="title-component">Email</IonLabel>
                                                                <IonLabel className={profileData?.email ? "title-component-ans" : "title-component-not-ans"}>{profileData?.email}</IonLabel>
                                                            </IonCol>
                                                            <IonCol size='6'>
                                                                <IonLabel position="stacked" className="title-component">Telefone</IonLabel>
                                                                <IonLabel className={profileData?.phones[0]?.number ? "title-component-ans" : "title-component-not-ans"}>{profileData?.phones[0]?.number}</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>

                                                    {/* )} */}
                                                </div>
                                                <div className="body-details">
                                                    <IonLabel className="header-sub-title-text-body">Mantenha seus dados cadastrais atualizados. Assim podemos agilizar seus atendimentos e prestar o melhor serviço para você e sua família.</IonLabel>
                                                    <div className="title-sub-header-txt">
                                                        <IonLabel className="title-sub-view">Minhas medidas</IonLabel>
                                                    </div>
                                                    <IonGrid>
                                                        <IonRow>
                                                            <IonCol size='4'>
                                                                <IonLabel position="stacked" className="title-component">Data</IonLabel>
                                                                <IonLabel className={profileData?.name ? "title-component-ans" : "title-component-not-ans"}>01/01/2021</IonLabel>
                                                            </IonCol>
                                                            <IonCol size='4'>
                                                                <IonLabel position="stacked" className="title-component">Peso</IonLabel>
                                                                <IonLabel className={profileData?.birthdate ? "title-component-ans" : "title-component-not-ans"}>69kg</IonLabel>
                                                            </IonCol>
                                                            <IonCol size='4'>
                                                                <IonLabel position="stacked" className="title-component">Altura</IonLabel>
                                                                <IonLabel className={profileData?.email ? "title-component-ans" : "title-component-not-ans"}>1,70m</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                        <IonRow>
                                                            <IonCol size='4'>
                                                                <IonLabel position="stacked" className="title-component">Medida abdominal</IonLabel>
                                                                <IonLabel className={profileData?.email ? "title-component-ans" : "title-component-not-ans"}>72cm</IonLabel>
                                                            </IonCol>
                                                            <IonCol size='4'>
                                                                <IonLabel position="stacked" className="title-component">Medida do quadril</IonLabel>
                                                                <IonLabel className={profileData?.email ? "title-component-ans" : "title-component-not-ans"}>72cm</IonLabel>
                                                            </IonCol>
                                                        </IonRow>
                                                    </IonGrid>
                                                </div>
                                            </div>


                                            <IonButton expand="full" shape="round" className="update-btn-vuew footer-btn-view" onClick={() => setIsEditProfle(true)}>Atualizar meu dados</IonButton>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <IonModal
                        isOpen={isLgoutConfirmation}
                        cssClass="logout-modal"
                        onDidDismiss={() => setIsLogoutConfirmation(false)}
                    >
                        <span className="logout-title">
                            Tem certeza de que deseja sair?
                        </span>
    
                        <div>
                            <IonButton
                                shape="round"
                                color="primary"
                                expand="block"
                                className="custom-logout-btn"
                                onClick={logout}
                            >
                                Sim
                            </IonButton>
                            <IonLabel
                                className="custom-logout-cancel-btn"
                                onClick={() => setIsLogoutConfirmation(false)}
                            >
                                Cancelar
                            </IonLabel>
                        </div>
                    </IonModal> */}

                <IonModal
                    isOpen={isShowModal}
                    cssClass="examfile-upload bottom-modal photomodal"
                    onDidDismiss={() => {
                        setIsShowModal(false);
                    }}
                >
                    <div
                        className="listdiv"
                        onClick={() => {
                            // setIsOpenCamera(true);
                            takePicture();
                            setIsShowModal(false);
                        }}
                    >
                        <img src="assets/images/camera.svg"></img>
                        <label> Câmera</label>
                    </div>
                    <div
                        className="listdiv"
                        onClick={() => {
                            handleProfileButton();
                            // takePicture()
                            setIsShowModal(false);
                        }}
                    >
                        <img src="assets/images/file-text.svg"></img>
                        <label>Fotos</label>
                    </div>
                    <div
                        className="lastlist"
                        onClick={() => {
                            setIsShowModal(false);

                        }}
                    >
                        Fechar
                    </div>
                </IonModal>

                {/* <IonModal
                        isOpen={isOpenCamera}
                        cssClass="policy-content-dialog bottom-modal photomodal camerashowmodal"
                        onDidDismiss={() => {
                            setIsOpenCamera(false)	
                        }}
                    >
                        <div className="closeiconmodal" 
                            onClick={()=>{
                                setIsOpenCamera(false)
                            }}>
                            <img src="/assets/images/cancel-icon.svg" alt="cancel" />
                        </div>
                        {isOpenCamera
                            ? handleCameraError
                            ?  <>
                            <div className="camera-error fixedheight-div">
                                <div className="container">
                                    <div className="row text-center justify-content-center">
                                        Câmera não foi detectada
                                    </div>
                                </div>
                            </div>
                        </>
                            : <Camera
                            onTakePhoto={(dataUri) => {
                                    handleTakePhoto(dataUri);
                                    UploadFile(dataUri)
                            }}
                            onCameraError={(err) => {
                                if (err) {
                                    setHandleCameraError(true);
                                } else {
                                    setHandleCameraError(false);
                                }
                            }}																
                        />
                        : null}
                        
                        {isLoading && <IonProgressBar type="indeterminate"  className="ion-margin-bottom"/>}
                    </IonModal> */}

                {getPlatforms().includes("desktop") ?
                    <AppFooter />
                    :
                    <MobileFooter props={props} />
                }
            </IonContent>
        </IonPage>
    )
}

export default UpdateProfile