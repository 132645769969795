import * as React from 'react'
import { useEffect } from 'react';
import ironFit from '../../clients/iron-fit-patient'
import api from './api';
import { Plugins } from '@capacitor/core';

import { getPlatforms, useIonToast } from '@ionic/react';
import "./styles.css";
declare const twiliovideo: any;
const { Camera } = Plugins;
const Chat = require('twilio-chat')

export const TwilioVideoCallScreen: React.FC<{ videoToken: any,roomName: any, supportId:any, patientId:any, pageRefreshRequestTick?: any, isAppointmentCall?: any  }> = ({ videoToken,roomName,supportId, patientId, pageRefreshRequestTick, isAppointmentCall=false }) => {
    const appointmentId = roomName
    const [privateChatMessaages, setPrivateChatMessages] = React.useState<any>([])
    let privateChatToken: any;
    const [activePrivateChatChannel, setActivePrivateChatChannel] = React.useState<any>(null)
    let activePrivateChatChannelValue = null
    let callType: any;
    let unreadCounts: any = 0;
    const [textMessage, setTextMessage] = React.useState<any>('')
    const chatTextMessage = React.useRef<HTMLInputElement>(null)
    const addfiles = React.useRef<HTMLInputElement>(null)
    const [attachments,setAttachments] = React.useState<any>(null)
    const [base64, setBase64] = React.useState<any>()
    const [present, dismiss] = useIonToast();
    useEffect(() => {
        getPrivateChatAccessToken();
        // if (activeTab == "Chat") {
        //     unreadCounts = 0;
        //     unreadCount(0)
        // }
    }, [])
    const getPrivateChatAccessToken = () => {
        setPrivateChatMessages([])
        api
          .post(`doctor/private_chat/get_access_token`, {
            identity: patientId
          })
            .then(response => {
                privateChatToken = response.data.data.token;
                intializePrivateChat(privateChatToken);
            })
            .catch(error => console.error(error));
    }
    const intializePrivateChat = (token: any) => {

      setActivePrivateChatChannel(null)
        setPrivateChatMessages([])
        Chat.Client.create(token).then((client: any) => {
            client.on("tokenAboutToExpire", () => {
                api
                    .post(`doctor/private_chat/get_access_token`, {
                        identity: patientId
                    })
                    .then(response => {
                        privateChatToken = response.data.data.token;
                        client.updateToken(privateChatToken);
                    });
            });
            client
                .getChannelByUniqueName(appointmentId)
                .then((channel: any) => {
                    if (channel.status != "joined") {
                        channel
                            .join()
                            .then((channel: any) => {
                                setActivePrivateChatChannel(channel);
                                activePrivateChatChannelValue = channel
                                // updateChannelSid(channel.sid);
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                    } else {
                        setActivePrivateChatChannel(channel);
                        activePrivateChatChannelValue = channel
                        // updateChannelSid(channel.sid);
                    }
                })
                .catch((err: any) => {
                    console.log("err", err.state);
                    createNewChannel(client);
                });
        });
    }

    const createNewChannel = (client: any) => {
      client
      .createChannel({
          uniqueName: appointmentId,
          friendlyName: appointmentId
      })
      .then((channel: any) => {
          channel.join();
          activePrivateChatChannelValue = channel;
          setActivePrivateChatChannel(channel);
          // updateChannelSid(channel.sid);
      });
    }

    useEffect(() => {
        if (activePrivateChatChannelValue != null) {
            let privateMessages: any = [];
            activePrivateChatChannelValue.getMessages().then(async (messages: any) => {
                if (messages.items.length > 0) {

                    for (var i = 0; i < messages.items.length; i++) {
                        privateMessages.push({
                            author: messages?.items[i]?.state?.author,
                            body:
                                messages?.items[i]?.state?.type == "text"
                                    ? messages?.items[i]?.state?.body
                                    : "",
                            date: messages?.items[i]?.state?.timestamp,
                            attachment:
                                messages?.items[i]?.state?.type != "text"
                                    ? messages?.items[i]?.media
                                    : "",
                            url:messages?.items[i]?.state?.type != "text"
                            ? await messages?.items[i]?.media.getContentTemporaryUrl()
                            : ""
                        });
                        setPrivateChatMessages(privateMessages)
                    }
                } else {
                    setPrivateChatMessages(messages.items)
                    // privateChatMessages = messages.items;
                }
            });
            // Listen for new messages sent to the channel
            activePrivateChatChannelValue.on("messageAdded", async (message: any) => {
                privateMessages.push({
                    author: message?.state?.author,
                    body: message?.state?.type == "text" ? message?.state?.body : "",
                    sid: message?.state?.sid,
                    date: message?.state?.timestamp,
                    attachment: message?.state?.type != "text" ? message?.media : "",
                    url:message?.state?.type != "text"
                            ? await message?.media.getContentTemporaryUrl()
                            : ""
                });
                setPrivateChatMessages(privateMessages)

                // if (message.state.author != staffId) {
                //     // if (activeTab != "Chat") {
                //         unreadCounts++;
                //         // unreadCount(unreadCounts)
                //     }
                // }
            });
        }
    }, [activePrivateChatChannelValue])

    const sentNewMessage = () => {      
        if (
            activePrivateChatChannelValue &&
            textMessage.trim() != "" &&
            chatTextMessage?.current?.value.trim()!= "" &&
            chatTextMessage?.current?.value&&
            attachments==null
        ) {
            const sentmsg = textMessage
                .replace(/<p[^>]*>/g, "")
                .replace(/<\/?p[^>]*>/g, "<br />");

                activePrivateChatChannelValue.sendMessage(sentmsg);
            chatTextMessage.current.value = ""
            setTextMessage("")
        }
        if(attachments!=null && chatTextMessage?.current?.value){
          activePrivateChatChannelValue.sendMessage(attachments);
          chatTextMessage.current.value = ""
          setAttachments(null)
        }
        setTextMessage("")
        setAttachments(null)
        AddFiles(base64)
    }

    const AddFiles = (base64: any) => {
      const formData = new FormData();   
      const myFile = blobToFile(converBase64toBlob(base64,'image/png'), "my-image.png");
      formData.append("file", myFile);
      setAttachments(formData)                 
      activePrivateChatChannelValue.sendMessage(formData);
    }
    const converBase64toBlob = (content, contentType) => {
      contentType = contentType || '';
      var sliceSize = 512;
      var byteCharacters = window.atob(content); //method which converts base64 to binary
      var byteArrays = [
      ];
      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);
        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      var blob = new Blob(byteArrays, {
        type: contentType
      }); 
      return blob;
    }
    const blobToFile = (theBlob: Blob, fileName:string): any => {
      var b: any = theBlob;
      b.lastModifiedDate = new Date();
      b.name = fileName;

      return theBlob;
    }

  // const takePicture = async () => {
  //   const image = await Camera.getPhoto({
  //     quality: 90,
  //     allowEditing: false,
  //     resultType: CameraResultType.Base64,
  //     // source: CameraSource.Camera
  //   });
  //   console.log(image)
  //   // UploadFile(image.base64String)
  // }

  useEffect(() => {
    if(!pageRefreshRequestTick) return
    const lastRefreshTick = window.localStorage.getItem('page_refresh_tick')
    const alreadyReloaded = !!lastRefreshTick && Number(lastRefreshTick) >= pageRefreshRequestTick
    window.localStorage.setItem('page_refresh_tick', String(pageRefreshRequestTick))
    
    const willReload = !alreadyReloaded
    finishRequest(patientId, willReload)
    async function finishRequest(id: string, withReload?: boolean) {
      const patientRef = ironFit.liveSupport().refPatient(id)
      const tickRef: any = patientRef.child('pageRefreshRequestTick')
      await tickRef.remove()
    }
    if (alreadyReloaded) return
    if((getPlatforms().includes('ios')|| getPlatforms().includes('android'))) {
      twiliovideo.closeRoom().then(()=>{
        twiliovideo.openRoom(videoToken, roomName + ":" + patientId, roomEventCallback)
      })
    }
  },[pageRefreshRequestTick])
 

  const roomEventCallback = async (event:any) => {
    if(event==="CLOSED"){
      const tempData= await window.localStorage.getItem('page_refresh_tick');
      
      if(tempData){
        await window.localStorage.setItem('page_refresh_tick', "")
        return
      }
      twiliovideo.closeRoom();
      // if(isAppointmentCall) {
      //   setIsRating(true)
      // }
      try {
        await ironFit.liveSupport().finishSupport(supportId)
      } catch(e){
        if (e.response && e.response.data && e.response.data.message) {
          present({message : e.response.data.message,duration:3000})
        } else {
          present({message :" Algo deu errado",duration:3000})
        }
        console.error("error when finish support", e);      
      }
    } else if(event && event.startsWith("ATTACHMENT--"))
    {
      const imageBase64 = event.replace("ATTACHMENT--", "");
      setBase64(imageBase64) 
      AddFiles(imageBase64)
    }
  }
  
  useEffect(() => {
 
    // Set the count variable value to Zero.
    console.log(roomName);
    console.log(videoToken);
    twiliovideo.openRoom(videoToken, roomName+":"+patientId, roomEventCallback)

 
  }, [] );
  
  
  return (
    <div className="container">
      <div className="row height_100vh d-flex align-items-center justify-content-center flex-column">
        <h5 className="connection_text mb-5 width_30">Por favor, aguarde. O profissional de saúde ingressará em alguns instantes.</h5>
      </div>
    </div>
  )
}
