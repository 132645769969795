import { getPlatforms } from "@ionic/react";
import _ from "lodash";

class Platform {
  private _mobilePlatform = ["mobile", "mobileweb", "android"];
  private _desktopPlatform = ["desktop"];
  private _androidPlatform = ["android"];
  private _mobileWebPlatform = ["mobileweb"];

  public get isMobile() {
    return _.intersection(this._mobilePlatform, this.platform).length > 0;
  }

  public get isDesktop() {
    return _.intersection(this._desktopPlatform, this.platform).length > 0;
  }

  public get isAndroid() {
    return _.intersection(this._androidPlatform, this.platform).length > 0;
  }
  public get isMobileweb() {
    return _.intersection(this._mobileWebPlatform, this.platform).length > 0;
  }
  public get platform() {
    return getPlatforms();
  }
}

export default new Platform();
